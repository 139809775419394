import React, {useContext,useState} from 'react';

import {Button,Heading,Input,Form,Link,Text,Stack} from '../../../designSystem';
import UserDataContext from '../../../admin-kit-local/tools/context';
import CardTemplate from '../card.js';


function ResetPasswordView(props) {
	const context = useContext(UserDataContext);
	const [done,setDone] = useState(false);
	const [errors,setErrors] = useState({});

	if (done) {
		return (
			<div>
				<Heading key='heading' variant='heading5'>Password Changed!</Heading>
				<Stack.Spacer height='xl' />
				<Text>Please <Link href="/account/login">login</Link> using your new password</Text>.
				<Stack.Spacer height='xl' />
				<Button variant='primary' href='/user/login'>Continue</Button>
			</div>
		);
	}

	return (
		<div>
			<Heading key='heading' variant='heading5'>Reset password</Heading>
			<Text>Please enter your new password.</Text>

			<Form style={{textAlign: 'left'}} onSubmit={onSubmit} key='form'>
				<Stack spacing='comfortable'>
					<Input label='Password' type='password' name='password1' onChange={onInputChange} />
					<Input label='Retype password' type='password' name='password2' onChange={onInputChange} 
						message={errors.dontMatch && "Passwords don't match"}
						error={errors.dontMatch}
					/>

					<div>
						<div style={{height: 12}} />
						<Button variant='primary' fullwidth loading={Form.loading}>Reset password</Button>
					</div>
				</Stack>
				<div style={{height: 32}} />
			</Form>
		</div>
	)

	/*------------------------------------------*/

	function onInputChange() {
		setErrors({});
	}

	function onSubmit(data) {
		let getQueryParameter = (name) => {
			var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
			return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
		}

		let password1 = data.password1;
		let password2 = data.password2;

		if (password1 !== password2){
			setErrors({dontMatch: true});
			return;
		}

		let user_id = getQueryParameter('uid');
		let token = getQueryParameter('token');
		let password = password1;
		
		return context.resetPassword(user_id,password,token).then(() => setDone(true));
	}
}


export default ResetPasswordView;
