
function InffuseQuotas(inffuse_instance, quotas)
{
	var quotas = quotas;
	var Inffuse = inffuse_instance;
	
	this.get = function(id)
	{
		if (typeof quotas[id] == 'undefined')
			return -1;

		return quotas[id];
	}

	this.consume = function(id,count)
	{
		params = {};
		if (typeof count == 'undefined')
			count = 1;
		
		params['count'] = count;

		var quota = quotas[id];
		if (typeof quota == 'undefined')
			return false;

		switch (quota.scope)
		{
			case 'user':
				params['user'] = Inffuse.user.id();
				break;

			case 'site':
				params['site'] = Inffuse.site.id();
				break;

			case 'project':
				params['project'] = Inffuse.project.id();
				break;
		}


		return Inffuse.requestAPI('quotas/'+id+'/consume',params,'POST')
			.done(function(data){
				quota.remaining = data.remaining;
			});
	}
};

export default InffuseQuotas;