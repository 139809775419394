export default function InffuseWeeblyClass(inffuse_instance)
{
	var Inffuse = inffuse_instance;
	var self = this;

	this.init = function()
	{
		// overrides
		Inffuse.broadcast = self.broadcast;
	}

	self.broadcast = function(event,data,includeSelf)
	{
		if (!window.parent)
			return;

		var msg = {
			app: 'inffuse',
			user: Inffuse.user ? Inffuse.user.key() : null,
			// site: Inffuse.site ? Inffuse.site.key() : null, // needed
			project: Inffuse.project ? Inffuse.project.key() : null,
			type: event,
			params: data
		}

		if (window.parent.postMessage)
			window.parent.postMessage(msg, "*");

		// http://stackoverflow.com/questions/13581493/communication-between-two-iframe-children-using-postmessage
		for (var i=0; i < window.parent.frames.length; i++) {
			var iframe = window.parent.frames[i];
			if (iframe == window)
				continue;
			
			iframe.postMessage(msg, "*");
		}

		if (includeSelf)
			Inffuse.trigger(event,data);
	}

	Inffuse.ui.openModal = function(params,callback)
	{
		Inffuse.broadcast('open-modal',params,true);
	}

	Inffuse.ui.closeModal = function(message)
	{
		Inffuse.broadcast('close-modal',undefined,true);
	}
}