
export default function InffuseSite(inffuse_instance,meta)
{
	var Inffuse = inffuse_instance;
	var self = this;
	var meta = meta;
	
	/*------------------------------------------*/

	/**
	 * Returns the current site ID
	 * @method Inffuse.site.id
	 * @returns {string}
	 */
	this.id = function()
	{
		return meta ? meta.id : null;
	}

	/**
	 * Returns the current site created
	 * @method Inffuse.site.created
	 * @returns {string}
	 */
	this.created = function()
	{
		return meta ? meta.created : null;
	}

	/**
	 * Returns the current site key name
	 * @method Inffuse.site.key
	 * @returns {String}
	 */
	this.key = function()
	{
		return meta ? meta.key_name : null;
	}
	
	/*------------------------------------------*/

	this.meta = function(callback) {
		Inffuse.error('Inffuse.site.meta() method is not implemented on '+Inffuse.platform+' platform');
		callback(null);
	}

	this.pages = function(callback) {
		Inffuse.error('Inffuse.site.pages() method is not implemented on '+Inffuse.platform+' platform');
	}

	this.currentPage = function(callback) {
		Inffuse.error('Inffuse.site.currentPage() method is not implemented on '+Inffuse.platform+' platform');
	}
	
	/*------------------------------------------*/
	
	this.listProjects = function()
	{
		var params = {
			user: Inffuse.user.id(),
			site: Inffuse.site.id(),
		};

		return Inffuse.requestAPI('projects',params,'GET');
	}

	this.createProject = function(name)
	{
		var params = {
			user: Inffuse.user.id(),
			site: Inffuse.site.id(),
			name: name
		};

		return Inffuse.requestAPI('projects',params,'POST');
	}

	/*------------------------------------------*/

	/**
	 * Helper function to display a direct link to the users account in Inffuse dashboard.
	 * The link will be printed using console.log().
	 * @method Inffuse.project.manage
	 */
	this.manage = function() {
		var host = Inffuse.server.indexOf("local") == -1 ? "dashboard.inffuse.com" : "dev.inffuse.local:28099";
		var url = [
			"http:/",
			host,
			"app:"+Inffuse.app.id(),
			"users",
			"site:"+self.id()
		].join('/');

		console.log('To manage the site go to: ',url);
	}

	/*------------------------------------------*/
};
