
function utils(inffuse_instance,contextObject)
{
	var Inffuse = inffuse_instance;
	
	this.init = function()
	{
	}
	
	this.openPopup = function(params)
	{
		function onClose() {
			Inffuse.off('popup-closed',onClose);
			if (params.onClose)
				params.onClose();
		}
		
		var msg_params = {};
		var keys = Object.keys(params);
		for (var i=0; i < keys.length; i++) {
			var key = keys[i];
			if (typeof params[key] == 'function')
				continue;

			msg_params[key] = params[key]
		}
		
		Inffuse.broadcast('open-popup',msg_params);
		Inffuse.on('popup-closed',onClose);
	}

	this.closePopup = function()
	{
		Inffuse.broadcast('close-popup');
	}

	this.buildQuery = function(params) {
		if (!params)
			return '';

		return Object.keys(params).map(function(k) {
			return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
		}).join('&');
	}

	this.http = async function(params,retry=0) {
		return fetch(params.url,{
			method: params.type,
			credentials: params.credentials,
			mode: params.mode,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Referer': 'https://mobile2.ollbeat.com',
				...params.header,
			},
			body: params.data ? new URLSearchParams({...params.data}).toString() : undefined,
		})
			.catch(error => {
				if (error.name === 'TypeError') {
					if (retry >= 3) {
						return Promise.reject(error);
					}
					
					contextObject.log(`[Inffuse.utils.http] TypeError (message = ${error.message}), retry ${retry+1}`);
					return this.http(params,retry+1);
				}
			})
			.then(async response => {
				const json = response.json();
				const responseJson = await json;

				if (response.status !== 200) {
					contextObject.log(`[Inffuse.http] Request failed with status code ${response.status}. ${responseJson.error}\nRequest - ${JSON.stringify(params)}`);
					params.error && params.error(response.status,responseJson.error,null);
					throw responseJson;
				}
				
				params.success && params.success(responseJson);
				return json;
			})
	}
};

export default utils;