/**
* Inffuse.UserClass
* @class 
*/

function InffuseUserClass(inffuse_instance, meta, data)
{
	var Inffuse = inffuse_instance
	var self = this;

	this.init = function()
	{
	}
	
	// internal
	this.meta = function(key) {
		return meta ? meta[key] : null;
	}
	
	/*------------------------------------------*/

	/**
	 * Create a new user
	 * @method Inffuse.user.create
	 * @param {string} name The name of the user
	 * @param {string} email The email of the user
	 * @param {string} password The password of the user
	 * @returns {object} jQuery jqXHR object
	 */
	this.create = function(name,email,password,captcha_response)
	{
		var params = {
			name: name,
			email: email,
			password: password,
			captcha: captcha_response /* optional */
		};

		return Inffuse.requestAPI('users',params,'POST',true)
			.then(function(data){
				meta = data.user;
			});
	}

	/**
	 * Autorize user
	 * @method Inffuse.user.login
	 * @param {string} email The email of the user
	 * @param {string} password The password of the user
	 * @returns {object} jQuery jqXHR object
	 */
	this.login = function(email,password)
	{
		var params = {
			email: email,
			password: password
		};

		return Inffuse.requestAPI('users/login',params,'POST',true)
			.then(function(data){
				meta = data.user;
			});
	}

	/**
	 * Log-out the current user
	 * @method Inffuse.user.logout
	 * @returns {object} jQuery jqXHR object
	 */
	this.logout = function()
	{
		return Inffuse.requestAPI('users/logout',{},'POST',true)
			.then(function(data){
				meta = {};
			});
	}

	/**
	 * Returns whether the current user is logged in
	 * @method Inffuse.user.loggedin
	 * @returns {boolean}
	 */
	this.loggedin = function()
	{
		return !!this.id();
	}

	/**
	 * Requests an email with password reset instructions
	 * @method Inffuse.user.resetPasswordRequest
	 * @param {string} email The email of the account for which the reset password is being requested
	 * @returns {object} jQuery jqXHR object
	 */	
	this.resetPasswordRequest = function(email)
	{
		return Inffuse.requestAPI('users/reset_password_request',{email:email},'POST');
	}

	/**
	 * Changes the password using a reset token issues previously via reset email
	 * @method Inffuse.user.resetPassword
	 * @param {string} user_id The user ID of the requester
	 * @param {string} password The new password
	 * @param {string} token The authorization token included in the reset request email 
	 * @returns {object} jQuery jqXHR object
	 */
	this.resetPassword = function(user_id,password,token)
	{
		return Inffuse.requestAPI('users/reset_password',{user:user_id,password:password,token:token},'POST');
	}

	/*------------------------------------------*/
	
	/**
	 * Returns the current user ID
	 * @method Inffuse.user.id
	 * @returns {string}
	 * @example
	 * 	var user_id = Inffuse.user.id()
	 * 	console.log("User ID = %s", user_id);
	 */
	this.id = function()
	{
		return meta.id;
	}

	/**
	 * Returns the current user created
	 * @method Inffuse.user.created
	 * @returns {string}
	 * @example
	 * 	var user_created = Inffuse.user.created()
	 * 	console.log("User created = %s", user_created);
	 */
	this.created = function()
	{
		return meta.created;
	}

	/**
	 * Returns the current user key name
	 * @method Inffuse.user.key
	 * @returns {string}
	 * @example
	 * 	var user_key = Inffuse.user.key()
	 * 	console.log("User Key = %s", user_key);
	 */
	this.key = function()
	{
		return meta.key_name;
	}

	/**

	 * Returns the current user plan
	 * @method Inffuse.user.plan
	 * @returns {string} User plan, or "free"
	 * @example
	 * 	var user_plan = Inffuse.user.plan()
	 * 	console.log("User plan = %s", user_plan);
	 */
	// TODO: return empty when free?
	this.plan = function()
	{
		return meta.plan;
	}
	
	/**
	 * Returns true when the user is first created
	 * @method Inffuse.user.isNew
	 * @returns {boolean}
	 * @example
	 * 	if (Inffuse.user.isNew())
	 * 		console.log("Hello new user...");
	 */
	this.isNew = function()
	{
		return meta['new'] == true;
	}

	/**
	 * Update the user object
	 * @method Inffuse.user.update
	 * @returns {object} jQuery jqXHR object
	 * @example
	 * 	Inffuse.user.update(user_object)
	 */
	this.update = function(user)
	{
		user['user'] = self.id();
		return Inffuse.requestAPI('users/'+self.id(),user,'POST')
			.then(function(data){
				for (var key in user) {
					if (key != 'password')
						meta[key] = user[key];
				}
			});
	}
		
	/**
	 * Editor mode only! (Set available only from settings window)
	 * Get or Set the user email
	 * Returns the current user email if no <email> parameter supplied (Get).
	 * @method Inffuse.user.email
	 * @returns {string} User email (Get)
	 * @returns {object} jQuery jqXHR object (Set).
	 * @example
	 * 	user_email = Inffuse.user.email()
	 * 	console.log("User email=%s", user_email);
	 * @example Wait for response
	 * 	Inffuse.user.email('newmail@domain.com')
	 * 		.done(function(){
	 * 			// Success!
	 *  		})
	 * 		.fail(function(){
	 * 			// Handle set email failure
	 * 		});
	 */
	// TODO: leave always available and secure via permissions?
	this.email = function(email)
	{
		if (typeof email == 'undefined')
			return meta.email;
		
		meta['email'] = email;

		var params = {
			user: self.id(),
			email: email
		};
			
		return Inffuse.requestAPI('users/update',params,'POST')
	}

	/**
	 * Editor mode only! (Set available only from settings window)
	 * Get or Set the user name
	 * Returns the current user name if no <name> parameter supplied (Get).
	 * @returns {object} jQuery jqXHR object if <name> parameter supplied (Set). Editor mode only option! (Available only from settings window)
	 * @method Inffuse.user.name
	 * @returns {string} User name (Get)
	 * @returns {object} jQuery jqXHR object (Set).
	 * @example
	 * 	user_name = Inffuse.user.name()
	 * 	console.log("User name=%s", user_name);
	 * @example Wait for response
	 * 	Inffuse.user.name('New User Name')
	 * 		.done(function(){
	 * 			// Success!
	 * 		})
	 * 		.fail(function(){
	 * 			// Handle set name failure
	 * 		});
	 */
	// TODO: consolidate with user.name()
	this.name = function(name)
	{
		if (typeof name == 'undefined')
			return meta.name;
		
		meta['name'] = name;
		
		var params = {
			user: self.id(),
			name: name
		};
			
		return Inffuse.requestAPI('users/update',params,'POST')
	}
	
	/**
	 * Open upgrade/billing page.
	 * @method Inffuse.user.upgrade
	 */
	// TODO: What is the return value of the overide function ???
	this.upgrade = function(plan)
	{
		var billing_service = '{{billing_service}}';
		switch (billing_service) {
			case 'stripe':
				Inffuse.services.stripe.checkout({
					type:'subscription',
					amount: 15,
					plan: plan,
					description: 'Subscribe to ' + plan + ' plan'
				});
				break;
		}
		
		Inffuse.ui.alert("Inffuse error :: upgrade() is not supported on " + Inffuse.platform + " platform");
	}
	
	/*------------------------------------------*/
	
	/**
	 * Get the current access_token to be used for authorizing API calls.
	 * @method Inffuse.user.accessToken
	 * @returns {string}
	 */
	this.accessToken = function()
	{
		return meta ? meta.access_token : null;
	}

	/*------------------------------------------*/

	/**
	 * Helper function to check the plan of the user
	 * @method Inffuse.user.is
	 * @param {string} plan Plan to test against.
	 * @returns {boolean} Returns true if the plan of the user equals the one passed.
	 * @example
	 * 	if (Inffuse.user.is('premium'))
	 * 		console.log("Premium user...");
	 */
	this.is = function(plan)
	{
		return (plan == this.plan());
	}

	/**
	 * Helper function to check if the user on the free plan.
	 * @method Inffuse.user.free
	 * @returns {boolean} Returns true if the user is on the free plan.
 	 * @example
 	 * 	if (Inffuse.user.free())
 	 * 		console.log("Free user...");
	 */
	this.free = function()
	{
		var plan = this.plan();
		return (!plan || plan == 'free');
	}

	/*------------------------------------------*/

	/**
	 * Get the dataStore for the default scope or the <scope_override> for the user
	 * @method Inffuse.project.getDataStore
	 * @param {string} scope_override if given, overrides the default scope. legal values are "private" or "public".
	 */
	this.getDataStore = function(scope_override)
	{
		if (!self.dataStore) {
			self.dataStore = new Inffuse.DataStore(Inffuse, 'users', self.id(), 'private');
			self.dataStore.populate(data);
		}
		
		return self.dataStore;
	}	
		
	/*------------------------------------------*/
	
	/**
	 * Helper function to display a direct link to the users account in Inffuse dashboard.
	 * The link will be printed using console.log().
	 * @method Inffuse.user.manage
	 */
	this.manage = function() {
		var host = Inffuse.server.indexOf("local") == -1 ? "dashboard.inffuse.com" : "dev.inffuse.local:28099";
		var url = [
			"http:/",
			host,
			"app:"+Inffuse.app.id(),
			"users",
			"user:"+self.id()
		].join('/');

		console.log('To manage the user go to: ',url);
	}

	/*------------------------------------------*/	
};

export default InffuseUserClass;