import React, {useContext,useEffect} from 'react';
import { Text, TextInput, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Button from '../../../utils/button';
import Form from '../../../utils/form';

import UserDataContext from '../../../admin-kit-local/tools/context';
import { GlobalColors, GlobalStyles } from '../../../styles';
import Image from '../../../utils/image';

function LoginPage(props) {
	const context = useContext(UserDataContext);
	const navigation = useNavigation();
	context.log("[LoginPage]");

	useEffect(() => {
		navigation.setOptions({ gestureEnabled: false, swipeEnabled: false });
	}, []);

	return (
		<View style={STYLE.container}>
			<Image src={require('../../../../img/logo.png')} style={STYLE.logo} />
			<Text style={{...GlobalStyles.heading, marginBottom: 64}}>Welcome to Ollbeat</Text>
			<Form style={STYLE.form} onSubmit={onLogin}>
				<Text style={STYLE.label}>Email</Text>
				<TextInput
					name='email'
					style={STYLE.input}
					// editable={!isSubmitting}
				/>

				<Text style={STYLE.label}>Password</Text>
				<TextInput
					// editable={!isSubmitting}
					name='password'
					secureTextEntry={true} 
					style={STYLE.input} 
				/>

				<Button submit variant='primary' style={STYLE.login.button}>Login</Button>
			</Form>

			<View style={STYLE.divider}>
				<Text style={STYLE.dividerLabel}>or</Text>
			</View>

			<Button variant='primary' style={STYLE.connect.button} onPress={onSpotifyConnect}>Connect with Spotify</Button>
		</View>
	)

	/*------------------------------------------*/

	function onLogin(values) {
		return context.login(values.email,values.password).then(() => {
			context.log("[ConnectedPage] navigating to Content");
				navigation.reset({
					index: 0,
					routes: [{ name: 'Content' }],
				});
		});
	}

	/*------------------------------------------*/

	function onSpotifyConnect() {
		context.spotifyConnect();
	}
}

/*---------------------------------------------*/

const STYLE = {
	container: {
		width: '100%',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: 120,
	},
	form: {
		width: '100%',
		marginBottom: 32,
	},
	label: {
		marginBottom: 6,
		fontFamily: GlobalStyles.fonts.primary,
	},
	login: {
		button: {
			marginTop: 16,
			paddingTop: 12,
			paddingBottom: 12,
			textAlign: 'center',
			borderRadius: 2,
			alignItems: 'center',
		}
	},
	input: {
		padding: 8,
		marginBottom: 16,
		backgroundColor: 'white',
		borderColor: GlobalColors['neutral-400'],
		borderWidth: 1,
		borderRadius: 2,
	},
	divider: {
		borderTopWidth: 1,
		borderTopColor: GlobalColors['neutral-400'],
		width: '100%',
		marginBottom: 32,
		alignItems: 'center',
	},
	dividerLabel: {
		backgroundColor: GlobalStyles.app.background,
		position: 'absolute',
		top: -10,
		paddingLeft: 12,
		paddingRight: 12,
		color: GlobalColors['neutral-700'],
		textAlign: 'center',
	},
	connect: {
		button: {
			backgroundColor: '#2dbc58', 
			borderRadius: 100,
			paddingTop: 12,
			paddingBottom: 12,
			paddingLeft: 32,
			paddingRight: 32,
			width: '100%',
			textAlign: 'center',
			alignItems: 'center',
		},
	},
	logo: {
		width: 48,
		height: 48,
		marginBottom: 32,
	}
}
/*---------------------------------------------*/

// export default withRouter(LoginPage);
export default LoginPage;
