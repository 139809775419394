import React, {useState,useRef} from 'react';
import { Text, TextInput, View } from 'react-native';
import { GlobalColors } from '../styles';

/*---------------------------------------------*/

export default function Form(props) {
	const [values,setValues] = useState(props.values || {});
	const [submitError,setSubmitError] = useState(null);
	const [loading,setLoading] = useState(false);
	const [valid,setValid] = useState(false);
	
	const submitPromise = useRef(null);

	const errors = props.errors || {};
	const error = props.error ?? submitError;

	const children = wrapWithForm(props.children);
	
	let errorElement = null;
	if (error && !props.silent) {
		errorElement = (
			<View style={STYLE.error.container}>
				<Text style={STYLE.error.label}>{error}</Text>
			</View>
		)
	}

	return (
		<View className={props.classes} style={props.style}>
			{errorElement}
			{children}
		</View>
	)

	/*------------------------------------------*/

	function get(obj,path,default_value) { 
		try {
			// https://stackoverflow.com/a/40270942/390035
			const value = path.split('.').reduce((res,key) => res[key], obj);
			if (typeof value !== 'undefined')
				return value;
	
		} catch(error) {
		}
	
		return default_value;
	}
	
	function set(obj,path,value) {
		const nodes = (typeof path === 'string') ? path.split('.') : path;
		const first = nodes.shift();
		if (typeof obj[first] === 'undefined')
			obj[first] = {};
		
		if (nodes.length)
			return set(obj[first],nodes,value);
		
		obj[first] = value;
	}
	
	/*------------------------------------------*/

	function onInputChange(value,name) {
		const newValues = {...values};
		set(newValues,name,value);

		props.onChange && props.onChange(newValues);
		setValues(newValues);

		setValid(validate(props.children));

		function validate(children) {
			for (let i=0; i < children.length; i++) {
				const component = children[i];
				if (component?.props?.required && !values[component.props.name]) {
					return false;
				}
				
				return component?.props?.children ? validate(component.props.children) : true;
			};
		}
	}

	/*------------------------------------------*/

	function onSubmit(e) {
		setSubmitError(null);

		submitPromise.current = props.onSubmit && props.onSubmit(values);
		if (submitPromise.current) {
			setLoading(true);
			
			submitPromise.current
				.catch(error => {
					setSubmitError(error || 'Error');
					setLoading(false);
				})
		}
	}

	/*------------------------------------------*/

	function wrapWithForm(children) {
		return React.Children.map(children,(component,index) => {
			if (!component || !component.props) {
				return component;
			}

			const props = {
				children: wrapWithForm(component.props.children)
			}

			if (component.props.name) {
				const name = component.props.name;

				props.value = get(values,name,component.props.value) || '';
				// props.error = !!errors[name] || component.props.error;
				// props.message = errors[name] || component.props.message;

				props.onChangeText = ((value) => {
					onInputChange(value,name); // should we use component.props.name instead?
					component.props.onChange && component.props.onChange(value,name);
				});

				props.onSubmitEditing = onSubmit;
			}

			if (component.props.loading === Form.loading) {
				props.loading = loading;
			}

			if (component.props.valid === Form.valid) {
				props.valid = valid;
			}

			if (component.props.submit) {
				props.onPress = onSubmit;
			}

			return React.cloneElement(component, props);
		});
	}
}

/*---------------------------------------------*/

Form.loading = 'FORM-LOADING-PLACEHOLDER';
Form.valid = 'FORM-VALID-PLACEHOLDER';

/*---------------------------------------------*/

const STYLE = {
	error: {
		container: {
			padding: 8, 
			marginBottom: 24, 
			borderRadius: 2,
			backgroundColor: GlobalColors['error-200'],
			alignItems: 'center',
		},
		label: {
			color: 'white',
			fontSize: 12,
		}
	}
}

/*---------------------------------------------*/
