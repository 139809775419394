
function InffuseApp(inffuse,meta, data)
{
	var self = this;
			
	this.init = function()
	{
	}
	
	/*------------------------------------------*/
	
	this.id = function()
	{
		return meta.id;
	}
	
	this.platform = function()
	{
		return meta.platform;
	}
	
	this.name = function()
	{
		return meta.name;
	}
	
	/*------------------------------------------*/
	
	this.set = function(key,value,save)
	{
		data[key] = value;

		if (typeof save == 'undefined' || save)
			this.save();
	}
	
	this.get = function(key,default_value)
	{
		if (typeof key == 'undefined') // TODO: should this be supported?
			return data;

		if (key in data == false)
			return default_value;
		
		return data[key];
	}
	
	this.remove = function(key,save)
	{
		delete data[key];

		if (typeof save == 'undefined' || save)
			this.save();
	}
	
	/*------------------------------------------*/
};

export default InffuseApp;