import { useContext, useState } from 'react';
import { ScrollView,Text,View } from 'react-native';

import UserDataContext from '../../admin-kit-local/tools/context';
import withLogin from '../../admin-kit-local/tools/withLogin';
import { generateId } from '../../admin-kit-local/tools/utils';
import { GlobalStyles } from '../../styles';
import Button from '../../utils/button';

/*---------------------------------------------*/

function BlockUserPage(props) {
	const context = useContext(UserDataContext);
	const userId = props.route.params.userId;
	const userName = props.route.params.userName;
	const [done, setDone] = useState(false);
	
	return (
		<ScrollView 
			keyboardShouldPersistTaps={'handled'} 
			contentContainerStyle={{width: '100%', minHeight: '100%', padding: 24,}}
		>
			<Text style={GlobalStyles.heading}>Block {userName}?</Text>
			<Text style={STYLE.text}>If you block {userName}, you will stop following them and they will no longer be able to follow you.</Text>
			<View style={{height: 24}} />
			<Button variant='primary' disabled={done} onPress={onBlock} style={{alignSelf: 'flex-start'}}>
				{done ? 'Blocked' : 'Block'}
			</Button>
		</ScrollView>
	)

	/*------------------------------------------*/

	function onBlock() {
		return context.inffuse.services.mysql.run('blockUser',{
			id: generateId('block'),
			blocked_user_id: userId,
		})
		.then((response) => {
			context.track('User Blocked',{blocked_user_id: userId});
			context.broadcast('FOLLOWERS_CHANGED');
			
			setDone(true);
			return Promise.resolve();
		})
		.catch(error => {
			context.track('Block User Failed', {blocked_user_id: userId, error: error.message});
		})
	}
	
	/*------------------------------------------*/
}

/*---------------------------------------------*/

const STYLE = {
	text: {
		lineHeight: 20,
		paddingRight: 16,
	}
}
/*---------------------------------------------*/

export default withLogin(BlockUserPage);
