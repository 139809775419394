import React from 'react';
import { useContext,useEffect,useMemo,useState } from "react";
import { Text } from 'react-native';

import UserDataContext from './context.js';

/*---------------------------------------------*/

export default function withLogin(Component) {
	return function LoginWall(props) {
		const context = useContext(UserDataContext);
		const [_update,forceUpdate] = useState(0);

		const onLoginChange = useMemo(() => {
			return (event,data) => {
				context.log("[withLogin.onLoginChange]");
				forceUpdate(u => u+1);

				if (!context.loggedIn()) {
					// props.navigation.navigate('Connect');
				}	
			}
		});
		
		// reason for useEffect here - https://stackoverflow.com/a/67030659
		useEffect(() => {
			context.observe(onLoginChange,['LOGIN_CHANGE']);

			if (!context.loggedIn()) {
				context.log("[withLogin] redirecting to /connect",Component);
				// props.navigation.navigate('Connect');
			}

			return () => context.unobserve(onLoginChange);
		}, []);
	
		context.log("[withLogin] logged in =",!!context.loggedIn());
		if (!context.loggedIn()) {
			return <Text>Please login</Text>;
		}

		return <Component {...props} />;
	}
}

/*---------------------------------------------*/