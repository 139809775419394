import { RangeSlider } from '../designSystem';

function Slider(props) {
	return (
		<RangeSlider 
			style={{...
				props.style,
				'--rangeslider-track-color': props.trackColor,
				'--rangeslider-fill-color': props.progressColor,
				'--rangeslider-handle-bg-color': props.handleColor,
				'--rangeslider-handle-border-color': props.handleColor,
				'--rangeslider-handle-hover-border-color': props.handleColor,
				'--rangeslider-handle-active-border-color': props.handleColor,
				'--rangeslider-handle-active-border-color': props.handleColor,
				'--rangeslider-handle-focus-shadow': props.handleColor,
			}}
			min={props.min} 
			max={props.max} 
			value={props.value} 
			onChange={props.onChange} 
			onSlideEnd={props.onSlideEnd} 
		/>		
	)
}

export default Slider;