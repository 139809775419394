import React, { useContext,useEffect,useState } from 'react';
import { GlobalStyles } from '../../styles';

import { Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Button from '../../utils/button';

import UserDataContext from '../../admin-kit-local/tools/context';
import withLogin from '../../admin-kit-local/tools/withLogin';
import UserCard from '../userCard';

/*---------------------------------------------*/

function AccountPage(props) {
	const context = useContext(UserDataContext);
	const [services,setServices] = useState(null);
	const [playlists,setPlaylists] = useState(null);

	useEffect(() => {
		refreshContent();
	},[]);

	const spotifyService = services?.find(service => (service.source === 'spotify'));
	const spotifyConfig = JSON.parse(spotifyService?.config || '{}');
	spotifyConfig.shareLikes = spotifyConfig.shareLikes ?? true;

	// const playlistsSection = playlists?.map((playlist,index) => (
	// 	<Checkbox label={playlist.name} message={`${playlist.tracks.total} songs`} name={playlist.id} key={index} />
	// ));

	return (
		<View>
			<View style={STYLE.container}>
				<Text style={GlobalStyles.heading}>Account</Text>
				<UserCard id={context.inffuse.user.id()} name={context.inffuse.user.name()} />
				<View style={{height: 8}} />
				<Button variant='primary' onPress={onLogout}>Log out</Button>
				
				<View style={{height: 24}} />
				<Text style={GlobalStyles.subHeading}>Spotify Account</Text>
				{ spotifyService
					? (
						<View>
							<Text>Connected</Text>

							{/* <Text variant='Text5'>Share liked songs</Text>
							<Toggle label="Automatically share all my likes" value={spotifyConfig.shareLikes} onChange={onLikesToggle} />
							<Stack.Spacer height='l' />

							<Text variant='Text5'>Playlists to share</Text>
							{ playlists ? null : 'Loading...'}
							<CheckboxGroup value={spotifyConfig?.playlists} onChange={onPlaylistsChange} >
								{playlistsSection || []}
							</CheckboxGroup> */}
						</View>
					)
					: <Button variant='primary' onPress={spotifyConnect}>Connect</Button>
				}
			</View>
		</View>
	)

	/*------------------------------------------*/
	
	function refreshContent() {
		context.inffuse.services.mysql.run('getUserServices',{})
			.then(response => {
				const services = response.result;
				setServices(services);
				
				const spotifyService = services?.find(service => (service.source === 'spotify'));
				
				if (spotifyService) {
					context.inffuse.services.spotify.playlists(spotifyService.external_id)
					.then(result => {
						const playlists = result?.playlists;
						setPlaylists(playlists);
					})
				}
			});
	}
	
	function spotifyConnect() {
		context.spotifyConnect().then(refreshContent);
	}

	/*------------------------------------------*/
	
	function updateSpotifyConfig(config) {
		spotifyService.config = JSON.stringify(config);
		context.inffuse.services.mysql.run('updateServiceConfig',{id: spotifyService.id, config: spotifyService.config});
		
		setServices([...services]);
	}
	
	function onLikesToggle(shareLikes) {
		spotifyConfig.shareLikes = shareLikes;
		updateSpotifyConfig(spotifyConfig);		
	}
	
	function onPlaylistsChange(playlists) {
		spotifyConfig.playlists = playlists;
		updateSpotifyConfig(spotifyConfig);
	}

	/*------------------------------------------*/

	function onLogout() {
		context.logout();
	}
}

/*---------------------------------------------*/

const STYLE = {
	container: {
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
}

/*---------------------------------------------*/

export default withLogin(AccountPage);
