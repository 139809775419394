import InffuseSDK_01 from './inffuse';

import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Mixpanel from './mixpanel';

export default class AppContextMgr {
	constructor(defaultSettings) {
		this.observers = [];

		this.currentlyPlayedPost = null;
		this.playerController = null;

		this.showPlayer = true;

		this.mixpanel = new Mixpanel("a8ae6c8a4b026a48654fdb1bff650e97");
		
		// this.demo = true;
		// this.emptyState = true;
	}

	/*----------------*/

	observe(handler,events) {
		if (this.observers.find(o => o.handler === handler)) {
			return;
		}

		this.observers.push({handler,events});
	}

	unobserve(handler) {
		const index = this.observers.findIndex(o => o.handler === handler)
		this.observers.splice(index,1);
	}

	broadcast(event,data) {
		this.observers
			.filter(observer => observer.events.includes(event))
			.forEach((observer) => observer.handler(event,data));
	}

	/*----------------*/

	ready() {
		return !!this.inffuse;
	}

	/*----------------*/

	init(app_id,onReady) {
		this.initInffuse(app_id,null,() => { 
			onReady();
		});
	}
	
	initInffuse(app_id,params,callback) {
		this.log("[ContextMgr.initInffuse]",params);
		const onInffuseLoaded = (Inffuse) => {
			this.log("[ContextMgr.initInffuse] onInffuseLoaded");
			this.inffuse = Inffuse;
			window.inffuse = Inffuse;
			
			this.log("[ContextMgr.initInffuse] onInffuseLoaded",{loggedIn: this.loggedIn()});
			if (this.loggedIn()) {
				this.onLogin();
				// mixpanel.identify(this.inffuse.user.id());
			}
			
			callback && callback();
		}

		const server = false
		// const server = true
			? 'http://192.168.1.11:10099'
			: 'https://ollbeat-dot-inffuse-platform.appspot.com';
		
		const inffuseSDK = new InffuseSDK_01(app_id,this);
		inffuseSDK.server = server;
		inffuseSDK.context_params = {
			platform: 'web',
			// user_id: 'user_6Ig3THSX16YvvJiQ019rc',
			// access_token: '2bce8b18051b9e2be4b70acd0db26a1c',
			// user: encodeURIComponent('sashagim@gmail.com'),
			// user: encodeURIComponent('sashagim+ollbeat@gmail.com'),
			// user: 'sashagim+ollbeat@gmail.com',
			// user_id: "user_kjtBGLznSlXwRljtPGMW0", // admin@ollbeat.com
			// secret_key: 'a08c4b74986a9e093a8457bfe52fad10',
			// // user: encodeURIComponent('user+pitchfork@ollbeat.com'),
			...params,
		};

		this.log("[ContextMgr.initInffuse] inffuseSDK.init");
		inffuseSDK.init(onInffuseLoaded);
	}

	registerForPushNotificationsAsync = async () => {
		if (Platform.OS === 'web' || !Device.isDevice) {
			return;
		}

		if (Platform.OS === 'android') {
			await Notifications.setNotificationChannelAsync('default', {
				name: 'default',
				importance: Notifications.AndroidImportance.MAX,
				vibrationPattern: [0, 250, 250, 250],
				lightColor: '#3A00C0',
			});
		}

		this.log("[ContextMgr.registerForPushNotificationsAsync]");

		const { status: existingStatus } = await Notifications.getPermissionsAsync();
		let finalStatus = existingStatus;
		
		if (existingStatus !== 'granted') {
			const { status } = await Notifications.requestPermissionsAsync();
			finalStatus = status;
		}
		
		if (finalStatus !== 'granted') {
			alert(`Failed to get push token for push notification! ${finalStatus}`);
			return;
		}

		try {
			const token = (await Notifications.getExpoPushTokenAsync({development: true})).data;
			this.log("[ContextMgr.registerForPushNotificationsAsync]",{token});

			this.inffuse.services.mysql.run('updateUserAccount',{
				push_token: token,
			});

			this.inffuse.services.mysql.run('updatePushToken',{
				token: token,
				// token: `$."${token}"`,
				token_data: JSON.stringify({
					timestamp: 1 * new Date(),
					os: Platform.OS,
				})
			});

		} catch (e) {
			alert(e);
		}
	};
	
	async sendInffuseRequest(path='', data={}, method='POST', secretKey) {
		const app_id = 'ollbeat';
		const queryParams = {
			app: app_id,
			platform: 'dashboard',
			secret_key: secretKey,
			access_token: this.inffuse.user.accessToken(),
		};

		const request = {
			method: method,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
		}

		if (data) {
			if (method === 'POST') {
				request.body = new URLSearchParams(data).toString();

			} else {
				queryParams = {
					...queryParams,
					...data,
				};
			}
		}
	
		const url = `${this.inffuse.server}/api/v0.1${path}?${(new URLSearchParams(queryParams).toString())}`;
		
		const response = await fetch(url, request);
		return response.json().then(responseJson => {
			if (response.status !== 200) {
				// alert("Request failed with status code "+response.status+".\n\n[Error]\n"+responseJson.error);
				throw new Error(responseJson.error);
			} else {
				return responseJson;
			}
		})
	}

	async sendSpotifyRequest(action,data,method) {
		this.sendInffuseRequest(`/users/${this.inffuse.user.id()}`,null,'GET')
		.then(async result => {
			const spotifyAccessToken = result.user.tokens.spotify.access_token;
			const spotifyAccessTokenExpiry = result.user.tokens.spotify.access_token_expiry;

			if (1000*spotifyAccessTokenExpiry < (new Date())) {
				alert("Access token expired. Expiry - " + (new Date(1000*spotifyAccessTokenExpiry).toLocaleString()));
				return;
			}

			const request = {
				method: method || 'GET',
				headers: {
					Authorization: `Bearer ${spotifyAccessToken}`,
					// 'Content-Type': 'application/x-www-form-urlencoded',
				},
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
			}
	
			const response = await fetch(`https://api.spotify.com/v1/${action}`, request);
			return response.json().then(responseJson => {
				if (response.status !== 200) {
					alert("Request failed with status code "+response.status+".\n\n[Error]\n"+responseJson.error);
					throw new Error(responseJson.error);
				} else {
					debugger;
					return responseJson;
				}
			})
		});
	}

	/*----------------*/

	loggedIn() {
		return this.inffuse && this.inffuse.user.id();
	}

	async onLogin() {
		this.track('Logged In');
		// await AsyncStorage.clear();

		this.registerForPushNotificationsAsync();
		
		const installedDate = await AsyncStorage.getItem('installed');
		if (installedDate === null) { // first install 
			const response = await fetch(`https://www.ollbeat.com/api/invites/identify?device=${Platform.OS}`);
			if (response.status === 200) {
				response.json().then(invite => {
					this.inffuse.services.mysql.run('acceptInvite',{id: invite.id});
					this.followUser(invite.invited_by,'NOTIF_INVITE_ACCEPTED');
					this.track('Invitation Accepted',{invited_by: invite.invited_by});
				});
			}

			await AsyncStorage.setItem('installed', "" + (1*new Date()));

			this.track('App Installed');
			this.inffuse.services.email.send('welcome',{
				user_name: this.inffuse.user.name(),
				user_email: this.inffuse.user.email(),
			});
		}

		this.broadcast('LOGIN_CHANGE');
		this.mixpanel.identify(this.inffuse.user.id());
		this.mixpanel.people_set({ 
			id: this.inffuse.user.id(),
			name: this.inffuse.user.name(),
		});

		return this.inffuse.services.mysql.run('createAccount',{
			id: this.generateId("account"),
			user_id: this.inffuse.user.id(),
		})
		.catch(() => { 
			this.log("[ContextMgr.onLogin] Account already exists");
		});
	}

	login(email,password) {
		return new Promise((resolve, reject) => {
			this.inffuse.user.login(email,password)
				.then((_result) => {
					this.track('Logged In',{email});
					this.onLogin();
					resolve();
				})
				.catch((responseJSON) => {
					reject(responseJSON?.error);
				})
		});
	}

	generateId(prefix) {
		const letters = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		
		const generatedId = new Array(21).fill(null).map(() => letters[Math.floor(Math.random()*letters.length)]).join('')
		if (prefix) {
			return `${prefix}_${generatedId}`;
		}

		return generatedId;
	}

	onSpotifyConnected(result) {
		this.track('Spotify Connected');

		const createService = () => {
			this.log("[onSpotifyConnected.createService]");
			if (result.details.images.length) {
				let imageUrl = result.details.images[0].url;
				if (Platform.OS !== 'web') {
					imageUrl = encodeURIComponent(imageUrl);
				}

				this.inffuse.services.aws.s3.copy(imageUrl,null,"img.ollbeat.com","profile.jpg")
					.catch(error => console.error("Failed to copy the profile image",{url: result.details.images[0].url, error}));
			}

			this.log("[onSpotifyConnected.createService] adding service");
			return this.inffuse.services.mysql.run('addService',{
				id: this.generateId("service"),
				external_id: result.details.id,
				source: "spotify",
				// last_sync: 1*(new Date())/1000,
			})
			.catch(() => { 
				this.log("Service already exists");
			})
			.then(_ => {
				this.sendToServer(`admin/sync/${this.inffuse.user.id()}`,null,'GET');
			});
		}

		this.log("[ContextMgr] onSpotifyConnected, result = ",{result, user: this.inffuse?.user?.id()});
		return new Promise((resolve, reject) => {
			if (!this.inffuse.user.id()) {
				this.initInffuse('ollbeat',{
					user_id: result.user.id,
					access_token: result.user.access_token,
				},() => {
					createService().catch((e) => {
					}).finally(() => {
						resolve();
					});
				});
			} else {
				createService().catch(() => {

				}).finally(() => {
					resolve();
				});
			}
		});
	}

	spotifyConnect() {
		const nextDeepLink = Linking.createURL('/user/connected');
		this.inffuse.navigator = this.navigator;

		return new Promise((resolve, reject) => {
			this.inffuse.services.spotify.connect(
				nextDeepLink, (_result) => {
					this.log(_result);
					this.onSpotifyConnected(_result).then(resolve);
				},
				(result) => {
					reject(result.responseJSON?.error);
				}
			);
		});
	}

	signup(name,email,password) {
		return new Promise((resolve, reject) => {
			this.inffuse.user.create(name,email,password)
				.then((_result) => {
					this.onLogin();
					this.track('Signed Up',{name,email});
					resolve();
				})
				.catch((result) => {
					console.error("Exception",result);
					reject(result.responseJSON?.error);
				})
		});
	}

	logout() {
		return new Promise((resolve, reject) => {
			this.inffuse.user.logout()
				.then((_result) => {
					this.broadcast('LOGIN_CHANGE');
					this.track('Logged Out');
					this.mixpanel.reset();

					// document.location.reload();
					resolve();
				})
				.catch((result) => {
					console.error("Exception",result);
					reject(result.responseJSON?.error);
				})
		});
	}

	sendPasswordReset(email) {
		this.track('Reset Password Requested',{email});

		return new Promise((resolve, reject) => {
			this.inffuse.user.resetPasswordRequest(email)
				.then(function(_response){
					resolve();
				})
				.catch(function(result){
					console.error("Exception",result);
					reject(result.responseJSON?.error);
				});
		});
	}

	resetPassword(user_id,password,token) {
		this.track('Reset Password Completed',{user_id});

		return new Promise((resolve, reject) => {
			this.inffuse.user.resetPassword(user_id,password,token)
				.then(function(_response){
					resolve();
				})
				.catch(function(result){
					console.error("Exception",result);
					reject(result.responseJSON?.error);
				});
		});
	}

	/*----------------*/

	user() {
		return this.inffuse.user;
	}

	plan() {
		return this.inffuse.user.plan();
	}
	
	isFreeTier() {
		return (
			this.inffuse.user.plan === 'free' ||
			!this.inffuse.user.plan ||
			!this.inffuse.user.plan_override
		);
	}

	/*----------------*/
	
	isDirty(flag) {
		if (flag !== undefined) {
			this.dirtyFlag = flag;
			
		} else {
			return this.dirtyFlag;
		}
	}

	/*----------------*/
	
	track(eventType,eventParams) {
		this.mixpanel.track(eventType,eventParams);
	}

	/*----------------*/

	notify(userId,type,data) {
		const dataEncoded = data;
		// if (Platform.OS !== 'web') {			
		// 	for (let key in data) {
		// 		dataEncoded[key] = encodeURIComponent(data[key]);
		// 	}
		// }

		const params = {
			id: this.generateId('notif'),
			user_id: userId, 
			type: type,
			data: JSON.stringify(dataEncoded),
		}

		return this.inffuse.services.mysql.run('addNotification',params)
			.catch(err => {
				console.error('Failed to create notification',err,params);
			});
	}

	/*----------------*/

	redirect(history,path) {
		history.push(path);
	}

	/*----------------*/

	onLocationChanged(location) {
	}

	/*----------------*/
	
	onHeaderContentChange(callback) {
		this.headerContentChangeCallback = callback;
	}
	
	setCustomHeaderContent(content) {
		this.headerContentChangeCallback(content);
	}

	/*----------------*/

	setPlayerController(playerController) {
		this.playerController = playerController;
	}

	play(post) {
		if (this.currentlyPlayedPost === post) {
			this.playerController && this.playerController.resume();
		} else {
			this.currentlyPlayedPost = post;
			this.playerController && this.playerController.play(post);
		}

		this.broadcast('PLAYER_STATE_CHANGE');
	}
	
	pause() {
		this.playerController && this.playerController.pause();

		this.broadcast('PLAYER_STATE_CHANGE');
	}

	isPlaying() {
		return this.playerController && this.playerController.isPlaying();
	}

	/*---------------------------------------------*/	

	async sendToServer(path='', data={}, method='POST') {
		const server = false
			? 'http://localhost:3003'
			: 'https://ollbeat-server.appspot.com';
	
		const url = `${server}/${path}`;
		const request = {
			method: method,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				Referer: 'https://www.ollbeat.com/',
				redirect: 'follow',
				referrerPolicy: 'no-referrer',
			},
		}
		
		if (data) {
			if (method === 'POST') {
				request.body = new URLSearchParams(data).toString();
				
			} else {
				url = url + '&' + (new URLSearchParams(data).toString());
			}
		}
		
		return fetch(url, request)
			.then(response => {
				if (response.status !== 200) {
					response.json().then(result => {
						console.error(`Request failed with status code ${response.status} (${result.error})\n`);
					});
					throw new Error(response.error);
				} 
	
				return response.json()
			})
	}
	
	/*---------------------------------------------*/	

	navigate(view,params) {
		this.navigator?.navigate(view,params);
	}

	/*---------------------------------------------*/
	
	log() {
		const [message,...args] = arguments;
		console.log(`[${new Date().toLocaleTimeString()}] ${message}`,...args);
		// args['timestamp'] = 1 * new Date();
		// this.mixpanel.track("[LOG9] "+message,args);
	}

	/*---------------------------------------------*/	

	followUser(user_id,notificationType='NOTIF_FOLLOWER') {
		const context = this;
		context.track("User Followed",{followed_id: user_id});

		return context.inffuse.services.mysql.run('follow',{
			"id": context.generateId("follow"),
			"user_id": context.inffuse.user.id(),
			"followed_user_id": user_id,
		})
			.then((response) => {
				context.broadcast('FOLLOWERS_CHANGED');
				context.notify(user_id,notificationType,{
					userId: context.inffuse.user.id(),
					userName: context.inffuse.user.name(),
				});
			});
	}

	unfollowUser(user_id) {
		const context = this;
		context.track("User Unfollowed",{followed_id: user_id});

		return context.inffuse.services.mysql.run('unfollow',{
			"user_id": context.inffuse.user.id(),
			"followed_user_id": user_id,
		})
			.then((response) => {
				context.broadcast('FOLLOWERS_CHANGED');
			});
	}
	
	/*---------------------------------------------*/	
}

