export default function InffuseWixClass(inffuse_instance)
{
	var Inffuse = inffuse_instance;
	var self = this;
	
	self.siteInfo = null;
	
	this.init = function()
	{
		if (typeof Wix == 'undefined')
			return Inffuse.ui.alert("Inffuse error :: Wix SDK not loaded.");

		// overrides
		Inffuse.user.upgrade = self.upgrade;
		Inffuse.broadcast = self.broadcast;
		Inffuse.site.meta = self.site_meta;
		Inffuse.site.pages = self.site_pages;
		Inffuse.site.currentPage = self.site_currentPage;
		Inffuse.utils.openPopup = self.openPopup;
		Inffuse.utils.closePopup = self.closePopup;

		(Wix.getSiteInfo || Wix.Worker.getSiteInfo)( function(info) {
			this.siteInfo = info;
		});

		var addEventListener = (Wix.addEventListener || Wix.Worker.addEventListener);
		addEventListener(Wix.Events.SITE_PUBLISHED, function() {
			Inffuse.project.publish();
		});
		
		addEventListener(Wix.Events.COMPONENT_DELETED, function() {
			// sent when closing a popup as well (?!)
			if (Inffuse.viewMode() != 'editor')
				return;

			Inffuse.project.remove();
		});

		addEventListener(Wix.Events.STYLE_PARAMS_CHANGE, function(params, typeOfCall) {
			Inffuse.trigger("style-changed");
		});

		addEventListener(Wix.Events.EDIT_MODE_CHANGE, function(params, typeOfCall) {
			Inffuse._viewMode = params.editMode;
			Inffuse.trigger("view-mode-changed",Inffuse._viewMode);
		});

		addEventListener(Wix.Events.SETTINGS_UPDATED, function(message) {
			Inffuse.receiveMessage(message);
		});

		addEventListener(Wix.Events.PAGE_NAVIGATION, function(data) {
			Inffuse.trigger("route-changed",{page: data.toPage});
		});

		// bug work-around - wix passes viewMode=preview even though we're in editor
		if (Wix.Utils && Wix.Utils.getViewMode)
			Inffuse._viewMode = Wix.Utils.getViewMode();
		
		/*
		function onSizeChanged()
		{
			Wix.setHeight($('body').outerHeight());
		}

		$(window).resize(onSizeChanged);
		$(window).bind("load",onSizeChanged);
		*/
	}

	this.demoMode = function() {
		return false;
	}


	/*----------------------------------------------*/
	/* Overrides												*/
	/*----------------------------------------------*/

	this.upgrade = function()
	{
		Wix.Settings.openBillingPage();
	}

	this.broadcast = function(type,params,includeSelf)
	{
		var compId = Inffuse.project ? Inffuse.project.key() : undefined;
		Wix.Settings.triggerSettingsUpdatedEvent({type: type, params: params}, compId);

		if (includeSelf)
			Inffuse.trigger(type,params);
	}

	this.site_meta = function(callback)
	{
		(Wix.getSiteInfo || Wix.Worker.getSiteInfo)(function(siteInfo) {
			callback({
				title: siteInfo.siteTitle,
				pageTitle: siteInfo.pageTitle,
				description: siteInfo.siteDescription,
				keywords: siteInfo.siteKeywords,
				referrer: siteInfo.referrer,
				url: siteInfo.url,
				host: siteInfo.baseUrl
			});
		});
	}

	this.site_pages = function(callback)
	{
		(Wix.getSitePages || Wix.Worker.getSitePages)(function(sitePages) {
			callback(sitePages);
		});
	}

	this.site_currentPage = function(callback) 
	{
		Wix.getCurrentPageId(function(page_id){
			callback(page_id);
		});
	}
	
	this.openPopup = function(params)
	{
		Wix.openModal(
			params.url,
			params.width,
			params.height,
			{origin:Wix.WindowOrigin.FIXED},
			params.onClose,
			Wix.Theme.BARE
		);
	}

	this.closePopup = function()
	{
		Wix.closeWindow();
	}

	this.openModal = function(params)
	{
	}

	Inffuse.ui.openModal = function(params,callback)
	{
		Wix.Settings.openModal(params.src, params.width, params.height, params.title, callback);
	}

	Inffuse.ui.closeModal = function(message)
	{
		Wix.Settings.closeWindow(message);
	}
}