import { Image as NativeImage } from 'react-native';

/*---------------------------------------------*/

export default function Image(props) {
	const nativeProps = {
		...props,
		src: undefined,
		source: (props.src?.startsWith && props.src?.startsWith('http')) ? {uri: props.src} : props.src,
	}

	return <NativeImage {...nativeProps} />
}

/*---------------------------------------------*/
