/**
* @namespace
* @memberof Inffuse
*/

export default function InffuseServices(inffuse_instance,services)
{
	var Inffuse = inffuse_instance;
	var self = this;

	function addServiceMethod(obj,service_name,method_name,func) {
		var path = [service_name,method_name].join('.');
		var parts = path.split('.');

		for (var i=0; i < parts.length-1; i++)
		{
			var part = parts[i];

			if (typeof obj[part] == 'undefined')
				obj[part] = {};

			obj = obj[part];
		}

		var last = parts.pop();
		obj[last] = func;
	}

	/* creating all supported services */
	for (var iService = 0; iService < services.length; iService++)
	{
		var service = services[iService];

		for (var iMethod = 0; iMethod < service.methods.length; iMethod++)
		{
			var method = service.methods[iMethod];

			(function(service,method) {
				var method_func = function(){
				
					var args = {};
					for (var i = 0; i < method.args.length; i++) {
						var name = method.args[i];
						var value = arguments[i];

						args[name] = value;
					}

					var _service = service.name.replace(/\./g,'/');
					var _method = method.name.replace(/\./g,'/');
					
					var _params = {
						method: [_service,method.name].join('.'), // for possible error message
						path: ['services',_service,_method].join('/'),
						type: method.type // default:"GET"
					}

					_params['args'] = {};
					for (var name in args) {
						var value = args[name];
						if (typeof value == 'function')
							continue;

						if (typeof value == 'object')
							value = JSON.stringify(value)

						_params['args'][name] = value;
					}

					_params.args['platform'] = Inffuse.platform;
					_params.args['app'] = Inffuse.app.id();

					if (Inffuse.user) {
						_params.args['user'] = Inffuse.user.id();
						_params.args['site'] = Inffuse.site ? Inffuse.site.id() : '';
						_params.args['project'] = Inffuse.project ? Inffuse.project.id() : '';
					}
					
					if (method.args.indexOf("callback") != -1)
						_params['callback'] = args.callback;
					
					return Inffuse.services[method.action || 'fetch'](_params);
				};

				addServiceMethod(self, service.name, method.name, method_func);

			})(service,method);
		}
	}

	this.fetch = function(params)
	{
		return Inffuse.requestAPI(params.path,params.args,params.type,true)
			.catch(function(error){
				if (error.responseJSON && data.responseJSON.error) {
					Inffuse.error(['Request to',params.method,'failed','('+data.responseJSON.error+')'].join(' '));
				}

				throw error;
			});
	}

	this.openWindow = function(params)
	{
		var closeInterval;
		var ieInterval;
		
		function complete(response) {
			closeInterval && clearInterval(closeInterval);
			closeInterval = null;

			ieInterval && clearInterval(ieInterval);
			ieInterval = null;

			window.removeEventListener("message",onMessage);

			params.callback(response);
		}

		function onMessage(event)
		{
			var data = event.data;
			if (data.app != 'inffuse')
				return;

			complete(data.type == 'connected' ? data.params : false);
		}

		// session id will be used for polling key on IE
		var session_id = Math.floor(Math.random()*100000000);
		params.args['session'] = session_id;

		var url = [Inffuse.server,'api',Inffuse.apiVersion,params.path].join('/');
		url += '?'+Inffuse.utils.buildQuery(params.args);

		Inffuse.openWebpage(url,onMessage);
	}
};
