// import '/web/index.scss';

import React from 'react';
import { RefreshControl, ScrollView, Share, Text, TextInput, View } from 'react-native';
import { GlobalColors, GlobalStyles } from '../../styles';
import Button from '../../utils/button';
import UserDataContext from '../../admin-kit-local/tools/context';
import withLogin from '../../admin-kit-local/tools/withLogin';

import { useContext, useEffect, useRef, useState } from 'react';
import UserCard from '../userCard';

/*---------------------------------------------*/

function FriendsPage(props) {
	const context = useContext(UserDataContext);
	const [refreshing, setRefreshing] = useState(false);	
	const [followers,setFollowers] = useState(null);
	const [suggestions,setSuggestions] = useState(null);
	const [searchQuery,setSearchQuery] = useState(null);
	const [searchResults,setSearchResults] = useState(null);
	
	useEffect(() => {
		context.inffuse.ready(() => {
			setFollowers(followers);
		});

		refresh();

		context.observe(refresh,['FOLLOWERS_CHANGED']);
		return () => context.unobserve(refresh);
			
	},[context.inffuse.user.id()]);

	const searchResultsSection = (() => {
		if (!searchResults) {
			return null;
		} 
		
		const results = searchResults.filter(user => !user.following).map(user => (
			<UserCard {...user} followButton={!user.following} unfollowButton={user.following} key={`result-${user.id}`} />
		));

		return (
			<View style={{marginBottom: 32}}>
				<Text style={GlobalStyles.subHeading} key='results-title'>Search results</Text>
				{ results.length ? results : <Text>We could't find anyone!</Text> }
			</View>
		)
	})();
	
	const followersCards = followers && followers.map(user => (
		<UserCard {...user} unfollowButton={true} key={`followed-${user.id}`} />
	));

	const suggestionsCards = suggestions && suggestions.map(user => (
		<UserCard {...user} followButton={true} key={`suggestion-${user.id}`} />
	));

	return (
		<ScrollView 
			keyboardShouldPersistTaps={'handled'} 
			contentContainerStyle={{width: '100%', minHeight: '100%', padding: 16}}
			refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => refresh(true)} />}
		>
			<Text style={GlobalStyles.heading} key='main-title'>People you follow</Text>
			<Text style={{...GlobalStyles.subHeading, marginTop: 8}}>Invite friends</Text>
			<View style={STYLE.share.container}>
				<Button variant='primary' onPress={onInvite}>Share Invitation</Button>
			</View>

			<View style={STYLE.form.container}>
				<Text style={{...GlobalStyles.subHeading, marginTop: 8}}>Find friends</Text>
				<TextInput
					style={STYLE.form.input}
					onChangeText={setSearchQuery}
					onSubmitEditing={onSearchUsers}
					placeholder='Enter part of name or email'
					placeholderTextColor='#aaa'
				/>
				
				<Button 
					variant='primary' 
					disabled={!(searchQuery?.length >= 3)} 
					onPress={onSearchUsers}>Search</Button>
			</View>
			
			{ searchResultsSection }

			{/* <Text style={{...GlobalStyles.subHeading, marginTop: 8}}>People to follow</Text>
			{suggestionsCards ?? new Array(3).fill(null).map((_x,index) => <UserCard index={index} key={index} />)}
			{suggestionsCards?.length === 0 ? <Text>We don't have any suggestions for you at the moment :(</Text> : null} */}

			<Text style={{...GlobalStyles.subHeading}}>People you're following</Text>
			{followersCards ?? new Array(3).fill(null).map((_x,index) => <UserCard index={index} key={index} />)}
			{followersCards?.length === 0 ? <Text>You don't follow anyone yet!</Text> : null}
		</ScrollView>
	)

	/*------------------------------------------*/
	
	function refresh() {
		const params = {
			user_id: context.inffuse.user.id()
		}

		context.inffuse.services.mysql.run('followers',params)
			.then((response) => {
				setFollowers(response.result);
			});

		context.inffuse.services.mysql.run('followSuggestions',params)
			.then((response) => {
				setSuggestions(response.result);
			});

		onSearchUsers(); // refresh search results
	}

	function onSearchUsers() {
		if (!(searchQuery?.length >= 3)) {
			return;
		}

		const params = {
			"user": context.inffuse.user.id(),
			"term": `%${searchQuery.toLowerCase()}%`
		};

		context.inffuse.services.mysql.run('userSearch',params)
			.then((response) => {
				setSearchResults(response.result);
			});
	}

	/*------------------------------------------*/

	async function onInvite() {
		try {
			const result = await Share.share({
				message: `Join me on Ollbeat!\n\nhttps://www.ollbeat.com/invited?id=${context.inffuse.user.id()}`
			});

			if (result.action === Share.sharedAction) {
				if (result.activityType) {
					// shared with activity type of result.activityType
				} else {
					// shared
				}
			} else if (result.action === Share.dismissedAction) {
				// dismissed
			}
		} catch (error) {
			alert(error.message);
		}
	}
}

/*---------------------------------------------*/

const STYLE = {
	share: {
		container: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			marginBottom: 32,
		},
	},
	form: {
		container: {
			flexDirection: 'column',
			alignItems: 'flex-start',
			marginBottom: 32,
		},
		input: {
			width: '100%',
			borderWidth: 1,
			borderColor: GlobalColors['neutral-400'],
			background: 'white',
			padding: 8,
			marginBottom: 8,
		},
		button: { 
		}
	}
}

/*---------------------------------------------*/

export default withLogin(FriendsPage);
