import React from 'react';
import { RefreshControl,ScrollView,View,Text,TouchableOpacity,TouchableWithoutFeedback } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { GlobalStyles,GlobalColors } from '../../styles';

import UserDataContext from '../../admin-kit-local/tools/context';
import withLogin from '../../admin-kit-local/tools/withLogin';
import Avatar from '../../admin-kit-local/components/avatar';
import TimeAgo from '../../utils/timeAgo';
import {NotificationsContent,handleNotification} from '../../notificationsContent';

import { useContext, useEffect, useState } from 'react';

/*---------------------------------------------*/

function NotificationsView(props) {
	const context = useContext(UserDataContext);
	const [refreshing, setRefreshing] = useState(false);	
	const [notifications,setNotifications] = useState(null);
	
	useEffect(() => refresh(), []);

	useEffect(() => {
		context.observe(refresh,'NOTIFICATION_RECEIVED');
		return () => context.unobserve(refresh);
	},[])

	useEffect(() => {
		const unsubscribe = props.navigation.addListener('focus', () => {
			const currentTime = new Date().toISOString().slice(0,19).replace('T',' ');
			context.inffuse.services.mysql.run('updateUserAccount',{last_ntfs_read: currentTime});

			setNotifications(notifications => {
				context.broadcast('NOTIFICATIONS_CHANGE',{notifications});
				return notifications;
			})
		});

		return unsubscribe;
	}, [props.navigation]);

	const content = (() => {
		if (!notifications) {
			return <View style={STYLE.empty}><Text>Loading...</Text></View>;
		}

		if (notifications.length) {
			return notifications.map(notification => <Notification notification={notification} onContentChanged={refresh} key={notification.id} />);
		}

		return <View style={STYLE.empty}><Text>No notifications yet</Text></View>;
	})();

	return (
		<ScrollView 
			keyboardShouldPersistTaps={'handled'} 
			contentContainerStyle={{width: '100%', minHeight: '100%', paddingTop: 16, paddingBottom: 0}}
			refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => refresh(true)} />}
		>
			<Text style={{...GlobalStyles.heading, paddingLeft: 16}}>Notifications</Text>
			{content}
		</ScrollView>
	)

	/*------------------------------------------*/

	function refresh(refreshIndication) {
		context.log("[NotificationsView.refresh]");
		
		refreshIndication && setRefreshing(true);

		context.inffuse.services.mysql.run('getUserNotifications',{})
			.then((response) => {
				let notifications = response.result;
				
				// filter out obsolete notification types
				notifications = notifications.filter(notification => !!NotificationsContent[notification.type]);
				notifications.forEach(notification => {
					if (notification.data) {
						notification['data'] = JSON.parse(`${notification.data}`);
					}
				});
					
				setNotifications(notifications);
				context.broadcast('NOTIFICATIONS_CHANGE',{notifications});

				refreshIndication && setRefreshing(false);
			});
	}
}

/*---------------------------------------------*/

function Notification(props) {
	const {notification} = props;
	const navigation = useNavigation();
	const context = useContext(UserDataContext);
	const meta = NotificationsContent[notification.type];

	if (!notification.data) {
		return null;
	}

	return (
		<TouchableWithoutFeedback onPress={onPress}>
			<View style={{...STYLE.container, backgroundColor: notification.read ? 'white' : GlobalColors['primary-100']}}>
				<View style={STYLE.image}>
					<Avatar id={notification.data.userId} name={notification.data.userName}  style={{width:48, height: 48}} />
				</View>
				<View style={STYLE.details}>
					<Text style={STYLE.detailsTitle}>{enrich(meta.body || meta.title,notification.data)}</Text>
					<Text style={STYLE.detailsTime}><TimeAgo time={notification.created} /></Text>
				</View>
			</View>
		</TouchableWithoutFeedback>
	)

	/*------------------------------------------*/

	function onPress() {
		context.track("Notification clicked",{notification});
		handleNotification(context,navigation,notification)
			.then(() => props.onContentChanged && props.onContentChanged());
	}

	/*------------------------------------------*/

	function renderJsx(templateFunc,formatters) {
		const vars = Object.keys(formatters);
		
		// 1. create a dummy data to replace ${x} to {x}
		const dataWithPlaceholders = {};
		vars.forEach(varName => dataWithPlaceholders[varName] = `{${varName}}`);

		// 2. render a string with placeholders - "{userName} liked {songName}"
		const str = templateFunc(dataWithPlaceholders);
		
		// 3. create a regex to wrap placeholders with delimiters
		const pattern = vars.map(varName => `\\{(${varName})\\}`).join('|');
		const re = new RegExp(pattern,'g');
		
		// 4. split the string to a list of parts - ["userName"," liked ","{songName}"]
		const delimiter = '%|%';
		const strDelimited = str.replace(re,(...args) => {
			const matches = args.slice(1,-2);
			for (let i in matches) {
				if (matches[i]) {
					return `${delimiter}${matches[i]}${delimiter}`
				}
			}
		});
		
		// 5. format() all variables, and wrap with strings in <Text> tags
		const parts = strDelimited.split(delimiter).filter(part => !!part);
		const jsx = parts.map((part,index) => {
			if (vars.includes(part)) {
				const varName = part;
				return formatters[varName](index);
			}

			return <Text key={index}>{part}</Text>;
		});

		return jsx;
	}

	function onUserClick(userId) {
		navigation.navigate('User',{userId: userId});
	}

	function enrich(templateFunc,data) {
		const formatters = {
			userName: (index) => (
				<Text style={{fontWeight: 'bold'}} onPress={() => onUserClick(data.userId)} key={index}>
					{data.userName}
				</Text>
			),
			songName: (index) => <Text style={{fontWeight: 'bold'}} key={index}>{data.songName}</Text>,
		};

		Object.keys(data).forEach(key => {
			if (!formatters[key]) {
				formatters[key] = (index) => <Text key={index}>{data[key]}</Text>;
			}
		});

		return renderJsx(templateFunc,formatters);
	}
}

/*---------------------------------------------*/

const STYLE = {
	empty: {
		padding: 16, 
		height: '100%', 
		backgroundColor: 'white'
	},
	container: {
		padding: 16,
		paddingBottom: 14,
		flexDirection: 'row',
		backgroundColor: 'white',
		borderTopWidth: 1,
		borderTopColor: 'rgba(0,0,0,0.05)',
	},
	image: {
		paddingTop: 2,
		paddingRight: 8,
	},
	details: {
		flex: 1,
		flexGrow: 1,
	},
	detailsTitle: {
		lineHeight: 20,
	},
	detailsTime: {
		fontSize: 12,
		opacity: 0.6,
		marginTop: 3,
	}
}

/*---------------------------------------------*/

export default withLogin(NotificationsView);
