// import '/web/index.scss';

import React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { FlatList, RefreshControl, ScrollView, Text, View } from 'react-native';

import UserDataContext from '../../admin-kit-local/tools/context';
import withLogin from '../../admin-kit-local/tools/withLogin';

import { Post, PostSkeleton } from '../post';
import UserCard from '../userCard';
import { GlobalColors } from '../../styles';

/*---------------------------------------------*/

function UserPage(props) {
	const context = useContext(UserDataContext);
	const [refreshing, setRefreshing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [posts,setPosts] = useState(null);
	const [user,setUser] = useState(null);
	const endOfContent = useRef(false);
	const userId = props.route.params.userId;
	
	useEffect(() => {
		refresh();
	},[userId]);

	const data = [{type: 'user'}];
	if (posts?.length) {
		data.push(...posts.map(post => ({type: 'post', post})));
	} 
	
	if (loading) {
		if (posts?.length) {
			data.push({type: 'footer', content: 'Loading more...'});
	
		} else {
			const skeletons = new Array(3).fill(null).map((_x,index) => ({type: 'skeleton', index}));
			data.push(...skeletons);
		}
	}

	if (posts?.length === 0) {
		data.push({type: 'empty'})
	}

	return (
		<FlatList 
			data={data}
			renderItem={renderItem}
			keyExtractor={(item,index) => index}
			keyboardShouldPersistTaps={'handled'} 
			onEndReached={onEndReached}
			contentContainerStyle={{width: '100%', minHeight: '100%', paddingTop: 8}}
			refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => refresh(true)} />}
		/>
	)

	/*------------------------------------------*/

	function renderItem({index,item}) { 
		switch (item.type) {
			case 'post':
				return <Post onContentChanged={refresh} post={item.post} key={index} />;
			
			case 'skeleton':
				return <PostSkeleton index={item.index} key={index} />;

			case 'user': 
				return (
					<View style={STYLE.profile.container}>
						<UserCard id={user?.id} name={user?.name} followButton={!user?.following} unfollowButton={user?.following} />
					</View>
				)
			
			case 'empty': 
				return (
					<Text style={{textAlign: 'center', paddingTop: 16, color: GlobalColors['neutral-600'], lineHeight: 22}}>
						{user.name} hasn't shared anything yet.
					</Text>
				)

			case 'footer':
				return <Text style={STYLE.footer}>{item.content}</Text>;
		}
	
		alert("Can't render item of type " + item.type);
	}

	/*------------------------------------------*/

	function onEndReached() {
		context.log("[UserPage.onEndReached]");

		if (endOfContent.current || !posts) {
			return;
		}

		loadPosts(posts.length)
			.then(loadedPosts => {
				setPosts([...posts,...loadedPosts]);
			});
	}

	/*------------------------------------------*/

	function loadPosts(offset,count) {
		setLoading(true);
		return context.inffuse.services.mysql.run('getUserPosts',{user: userId, offset, count})
			.then((response) => {
				const loadedPosts = response.result;
				loadedPosts.forEach(post => {
					if (post.likes) {
						post['likes'] = JSON.parse(`[${post.likes}]`);
					}
				});

				setLoading(false);
				if (loadedPosts.length === 0) {
					endOfContent.current = true;
				}

				return loadedPosts;
			});
	}

	/*------------------------------------------*/

	function refresh(refreshIndication) {
		if (!userId) {
			return;
		}

		refreshIndication && setRefreshing(true);
		endOfContent.current = false;

		loadPosts()
			.then(loadedPosts => {
				setPosts(loadedPosts);
				refreshIndication && setRefreshing(false);
			});

		context.inffuse.services.mysql.run('getUserProfile',{user: userId})
			.then((response) => {
				setUser(response.result[0])
			});
	}
}

/*---------------------------------------------*/

const STYLE = {
	profile: {
		container: {
			padding: 16,
		}
	},
	footer: { 
		textAlign: 'center',
		color: GlobalColors['neutral-600'],
		paddingBottom: 16,
	}
}

/*---------------------------------------------*/

export default withLogin(UserPage);
