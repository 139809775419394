export default function InffuseShopifyClass(inffuse_instance)
{
	var Inffuse = inffuse_instance;
	var self = this;
	var metadata;
	
	self.init = function(_metadata)
	{
		metadata = _metadata;
		self.preview_url = metadata.widget_url;

		if (Inffuse.editing)
		{
			Inffuse.loadScript('https://cdn.shopify.com/s/assets/external/app.js',function(){
				ShopifyApp.init({
					apiKey: metadata.api_key,
					shopOrigin: 'https://'+ metadata.shop,
					forceRedirect: metadata.force_redirect
				});

				var logo_url = metadata.logo_url;
				if (!logo_url)
					logo_url = [document.location.protocol,'//',document.location.host,'/img/logo_86.png'].join('');

				var buttons = {};
				buttons['primary'] = {
					label: 'Publish', 
					message: 'publish', 
					callback: function(){
						setTimeout(ShopifyApp.Bar.loadingOff,200);

						Inffuse.project.publish();
						ShopifyApp.flashNotice("Your changes were published.")
					}
				};

				buttons['secondary'] = [];
				
				if (metadata.widget_url) {
					buttons['secondary'].push({
						label: 'Preview', 
						message: 'preview', 
						callback: Inffuse.shopify.preview
					});
				}
				
				var has_premium = metadata.has_premium;
				if (has_premium && Inffuse.user.free()) {
					buttons['secondary'].push({
						label: 'Upgrade', 
						message: 'upgrade',
						callback: function(){
							setTimeout(ShopifyApp.Bar.loadingOff,200);

							Inffuse.trigger('upgrade');
							//doSomeCustomAction();
						}
					});
				}
						
				// ShopifyApp.ready is not called if the shopify script is loaded asynchroniously
				// (the "ready" message is sent before the message handled is set up by the script,
				// and is therefore missed)
				// Since inffuse is loaded asynchroniously itself, this shouldn't be a problem - 
				// Shopfiy API should be loaded by now.
				ShopifyApp.Bar.loadingOff();
				ShopifyApp.Bar.initialize({
					icon: metadata.logo_url,
					title: 'Settings',
					buttons: buttons
				});
			});
		}
	}

	self.setPreviewUrl = function(url)
	{
		self.preview_url = url;
	}

	self.preview = function()
	{
		setTimeout(ShopifyApp.Bar.loadingOff,200);

		if (!self.preview_url)
			return Inffuse.ui.alert("Inffuse error :: preview URL is not set");

		Inffuse.ui.openModal({
			src: self.preview_url,
			title: 'Preview',
			width: 'small',
			height: 350,
			buttons: {
				primary: { 
					label: "Close",
					callback: Inffuse.ui.closeModal
				}
			}
		});
	}

	/*----------------------------------------------*/
	/* Overrides												*/
	/*----------------------------------------------*/

	Inffuse.ui.alert = function(str)
	{
		ShopifyApp.flashNotice(str);
	}

	Inffuse.ui.openModal = function(params,callback)
	{
		ShopifyApp.Modal.open(params, callback);
	}

	Inffuse.ui.closeModal = function()
	{
		ShopifyApp.Modal.close();
	}

	Inffuse.user.upgrade = function(plan,amount,test)
	{
		Inffuse.services.shopify.createCharge(metadata.shop,plan,amount,test)
			.then(function(response) {
				window.top.location.href = response.confirmation_url;
			});
	}
}