import React, {useContext,useEffect} from 'react';
import { Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';

import Button from '../../../utils/button';

import UserDataContext from '../../../admin-kit-local/tools/context';
import { GlobalStyles } from '../../../styles';
import Image from '../../../utils/image';

function ConnectPage(props) {
	const context = useContext(UserDataContext);
	const navigation = useNavigation();
	context.log("[ConnectPage]");

	useEffect(() => {
		navigation.setOptions({ gestureEnabled: false, swipeEnabled: false });
	}, []);

	return (
		<View style={STYLE.container}>
			<Image src={require('../../../../img/logo.png')} style={STYLE.logo} />
			<Text style={GlobalStyles.heading}>Welcome to Ollbeat</Text>
			<Text style={STYLE.connect.label}>Connect your account</Text>
			<Button variant='primary' style={STYLE.connect.button} type="button" onPress={onSpotifyConnect}>Connect to Spotify</Button>
		</View>
	)

	/*------------------------------------------*/

	function onSpotifyConnect() {
		context.spotifyConnect();
	}
}

/*---------------------------------------------*/

const STYLE = {
	container: {
		width: '100%',
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: 100,
	},
	connect: {
		label: {
			marginTop: 32,
			marginBottom: 12,
		},
		button: {
			backgroundColor: '#2dbc58', 
			borderRadius: 100,
			paddingLeft: 32,
			paddingRight: 32,
		},
	},
	logo: {
		width: 48,
		height: 48,
		marginBottom: 32,
	}
}
/*---------------------------------------------*/

// export default withRouter(ConnectPage);
export default ConnectPage;
