import React from 'react';
import { Icon as WebIcon } from '../designSystem';
import Icons from './icons';

// https://www.carbondesignsystem.com/guidelines/icons/library/

/*---------------------------------------------*/

export default function Icon(props) {
	const icon = Icons[props.type];
	return (
		<WebIcon {...props} icon={icon.web} />
	)
}

/*---------------------------------------------*/
