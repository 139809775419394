import React, {useState,useContext} from 'react';

import {Button,Icon,Heading,Divider,Form,Input,Link,Stack,Text} from '../../../designSystem';
import UserDataContext from '../../../admin-kit-local/tools/context';


function ForgotPasswordView(props) {
	const context = useContext(UserDataContext);
	const [done,setDone] = useState(false);
	const [email,setEmail] = useState(null);

	if (done) {
		return (
			<div>
				<Heading key='heading' variant='heading5'>Reset password</Heading>
				<Stack.Spacer height='xl' />
				<Icon icon='CheckmarkOutline' size={56} color='var(--color-primary-80)' />
				<Stack.Spacer height='xl' />
				<Text>
					We've sent you an email to <b>{email || 'email@company.com'}</b>
				</Text>
				<Stack.Spacer height='l' />
				<Text color='--neutral-70'>
					Check your email for instructions to reset your password.
				</Text>
			</div> 
		)
	}

	return (
		<div>
			<Heading key='heading' variant='heading5'>Reset password</Heading>
			<Text color='--neutral-70'>Enter your email address and we'll send you a link to reset your password.</Text>
			<Form onSubmit={onSubmit} key='form' style={{textAlign: 'left'}}>
				<Input label='Email' type='text' name='email' />
				<Button variant="primary" fullwidth loading={Form.loading}>Continue</Button>
			</Form>
		</div>
	)

	/*------------------------------------------*/
	
	function onSubmit(data) {
		setEmail(data.email);
		return context.sendPasswordReset(data.email).then(() => setDone(true));
	}
}

export default ForgotPasswordView;
