import React from 'react';
import { RefreshControl,ScrollView,Text } from 'react-native';

import UserDataContext from '../../admin-kit-local/tools/context';
import withLogin from '../../admin-kit-local/tools/withLogin';
import UserCard from '../userCard';

import { useContext, useEffect, useState } from 'react';

/*---------------------------------------------*/

function LikesView(props) {
	const context = useContext(UserDataContext);
	const entityId = props.route.params.entityId;
	const [refreshing, setRefreshing] = useState(false);
	
	const [likes,setLikes] = useState(null);
	
	useEffect(() => {
		refresh();
	},[entityId]);

	const content = likes
		? likes.map(like => <UserCard id={like.user_id} name={like.user_name} key={like.id} />)
		: <Text>Loading...</Text>;

	return (
		<ScrollView 
			keyboardShouldPersistTaps={'handled'} 
			contentContainerStyle={{width: '100%', minHeight: '100%', padding: 16}}
			refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => refresh(true)} />}
		>
			{content}
		</ScrollView>
	)

	/*------------------------------------------*/

	function refresh(refreshIndication) {
		refreshIndication && setRefreshing(true);

		context.inffuse.services.mysql.run('getLikes',{entity_id: entityId})
			.then((response) => {
				if (response.result.length) {
					setLikes(response.result);
					refreshIndication && setRefreshing(false);
				}
			});
	}
}

/*---------------------------------------------*/

export default withLogin(LikesView);
