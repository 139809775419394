import React from 'react';
import { WebView } from 'react-native-webview';
import * as Linking from 'expo-linking';

/*---------------------------------------------*/

const EXTERNAL_BROWSER_URLS = [
	'https://accounts.spotify.com/en/login/facebook',
	'https://accounts.spotify.com/en/login/google',
]

/*---------------------------------------------*/

function WebviewPage(props) {
	const uri = props.route.params.uri;

	return (
		<WebView 
			source={{ uri: uri }} 
			// incognito={true}
			onShouldStartLoadWithRequest={onShouldStartLoadWithRequest} 
		/>
	)

	/*------------------------------------------*/

	function onShouldStartLoadWithRequest(event) {
		console.log("[WebviewPage.onShouldStartLoadWithRequest]",{url: event.url});

		const matches = EXTERNAL_BROWSER_URLS.filter(prefix => event.url.startsWith(prefix));
		if (matches.length) {
			Linking.openURL(event.url);
			return false;
		}

		return true;
	}
}

/*---------------------------------------------*/

export default WebviewPage;
