
// https://www.randomlists.com/random-names
// https://www.uifaces.co/browse-avatars/?provider%5B%5D=9&provider%5B%5D=7
const users = [
	{
		user_name: "Selena Golden",
		user_image: "https://images.pexels.com/photos/1845534/pexels-photo-1845534.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
	},
	{
		user_name: "Joyce Douglas",
		user_image: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
	},
	{
		user_name: "Delilah Braun",
		user_image: "https://images.pexels.com/photos/1587009/pexels-photo-1587009.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
	},
	{
		user_name: "Nathanael Barnett",
		user_image: "https://images.pexels.com/photos/247885/pexels-photo-247885.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
	},
	{
		user_name: "Britney Brennan",
		user_image: "https://images.pexels.com/photos/1130626/pexels-photo-1130626.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
	},
	{
		user_name: "Tripp Patel",
		user_image: "https://images.pexels.com/photos/1222271/pexels-photo-1222271.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
	},
	{
		user_name: "Tabitha Spears",
		user_image: "https://images.pexels.com/photos/1832959/pexels-photo-1832959.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
	},
	{
		user_name: "Valentino Schaefer",
		user_image: "https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
	},
	{
		user_name: "Damian Taylor",
		user_image: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
	},
	{
		user_name: "Ezekiel Brady",
		user_image: "https://images.pexels.com/photos/736716/pexels-photo-736716.jpeg?auto=compress&cs=tinysrgb&crop=faces&fit=crop&h=200&w=200",
	},
	{
		user_name: "Savanah Snow",
		user_image: "https://images.pexels.com/photos/594421/pexels-photo-594421.jpeg?crop=faces&fit=crop&h=200&w=200&auto=compress&cs=tinysrgb",
	},
];

/*---------------------------------------------*/

// https://www.billboard.com/charts/hot-100/
const posts = [
	{
		song_artist: "Harry Styles",
		song_name: "As It Was",
		song_thumb: "https://charts-static.billboard.com/img/2022/04/harry-styles-bma-as-it-was-po3-180x180.jpg",
	},
	{
		song_artist: "Bad Habit",
		song_name: "Steve Lacy",
		song_thumb: "https://charts-static.billboard.com/img/2022/07/steve-lacy-tvr-bad-habit-l6b-344x344.jpg",
	},
	{
		song_artist: "Running Up That Hill (A Deal With God)",
		song_name: "Kate Bush",
		song_thumb: "https://charts-static.billboard.com/img/1985/08/kate-bush-qv2-running-up-that-hill-1cx-344x344.jpg",
	},
	{
		song_artist: "",
		song_name: "Hold Me Closer",
		song_thumb: "https://charts-static.billboard.com/img/2022/09/elton-john-m6x-holdmecloser-952-344x344.jpg",
	},
];

/*---------------------------------------------*/

const comments = [
	"This song is so beautiful and emotional. The lyrics are so powerful and the music is so moving. It makes me feel so connected to the artist and the song.",
	"I love this song! I can't get it out of my head. It's so catchy.",
	"I absolutely love this song! There's just something about the nostalgic feel of it that makes me happy.",
	"Best song ever. I love this album it's so amazing.",
	"I don't usually comment on songs, but this one is worth it. I love the synthesizer part in particular.",
	"This song is one of the best I've ever heard. The lyrics are Parish and the vocal range is amazing. The instrumental is also very good.",
	"The song is very touching and tells a story that many people can relate to. It is a beautifully written song and the singer has an amazing voice.",
]

export default {users,posts,comments};