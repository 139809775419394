const GlobalColors = {
	'primary-50':  '#F5F9FF',
	'primary-100': '#EBF3FF',
	'primary-200': '#DBE9FF',
	'primary-300': '#C2DAFF',
	'primary-400': '#8FBBFF',
	'primary-500': '#5C9CFF',
	'primary-600': '#287DFF',
	'primary-700': '#1B69E0',
	'primary-800': '#0C53C2',
	'primary-900': '#0040A3',

	// neutral
	'neutral-50': '#fcfcfc',
	'neutral-100': '#f0f4fa',
	'neutral-200': '#e6eaf0',
	'neutral-300': '#dde2eb',
	'neutral-400': '#cfd6e0',
	'neutral-500': '#a2abbd',
	'neutral-600': '#6b768a',
	'neutral-700': '#4f586b',
	'neutral-800': '#343b4d',
	'neutral-900': '#202433',

	// yellow
	'yellow-50': '#fffcf5',
	'yellow-100': '#fff6e3',
	'yellow-200': '#ffeecc',
	'yellow-300': '#ffe2a8',
	'yellow-400': '#ffd175',
	'yellow-500': '#ffc042',
	'yellow-600': '#f5ab18',
	'yellow-700': '#db9200',
	'yellow-800': '#b87a00',
	'yellow-900': '#946300',

	// cyan
	'cyan-50': '#f5fdff',
	'cyan-100': '#d9f6fc',
	'cyan-200': '#bef0fa',
	'cyan-300': '#99e8f7',
	'cyan-400': '#66dcf4',
	'cyan-500': '#30d0f0',
	'cyan-600': '#0dbde0',
	'cyan-700': '#0ca3c2',
	'cyan-800': '#08748a',
	'cyan-900': '#004c5c',

	// STATE

	// success
	'success-50': '#fafffc',
	'success-100': '#7acca1',
	'success-200': '#03803d',
	'success-300': '#025c2c',
	'success-400': '#00421e',

	// error
	'error-50': '#fff5f5',
	'error-100': '#f09090',
	'error-200': '#d20000',
	'error-300': '#a80000',
	'error-400': '#800000',

	// warning
	'warning-50': '#fffbf5',
	'warning-100': '#facf96',
	'warning-200': '#fa9819',
	'warning-300': '#e58200',
	'warning-400': '#cc7400',

	// info
	'info-50': '#f0f3fa',
	'info-100': '#b5c8f5',
	'info-200': '#0e4de5',
	'info-300': '#0739b3',
	'info-400': '#032b8a',
	'info-visited': '#5f22e0',
}

const Fonts = {
	primary: 'Lora_500Medium',
	primaryBold: 'Lora_700Bold',
	body: 'Roboto_400Regular',
}

const GlobalStyles = {
	fonts: {
		primary: Fonts.primary,
		primaryBold: Fonts.primaryBold,
		body: Fonts.body,
	},
	app: {
		background: '#faf7f3',
	},
	heading: {
		fontSize: 20,
		marginBottom: 12,
		fontFamily: Fonts.primary,
	},
	subHeading: {
		fontSize: 16,
		fontFamily: Fonts.primaryBold,
		marginBottom: 14,
	},
	badge: {
		borderRadius: 8,
		overflow: 'hidden',
		backgroundColor: GlobalColors['error-200'],
		color: 'white',
		width: 16,
		height: 16,
		paddingTop: 1.5,
		fontSize: 9,
		fontWeight: 'bold',
		textAlign: 'center',
	},
	player: {
		height: 232,
		crop: 50,
	},
	hr: {
		borderColor: GlobalColors['neutral-100'],
	}
}

export {GlobalColors,GlobalStyles};