import React,{useState,useRef} from 'react';
import { Modal, TouchableOpacity, Text, View, ScrollView } from 'react-native';
import Icon from '../../utils/icon';
import Button from '../../utils/button';

/*---------------------------------------------*/

export default function Confirm(props) {
	const [isVisible,setVisible] = useState(false);
	const action = useRef(null);
	const actionArgs = useRef(null);

	// pass <Confirm />'s props to the child, but witohut "children" prop, not to duplicate
	const parentProps = {...props};
	delete parentProps.children;

	const children = React.Children.map(props.children,(child,_index) => {
		const childProps = {
			...parentProps,
			onClick: () => {
				setVisible(true);

				action.current = child.props.onPress || child.props.onClick;
				actionArgs.current = arguments;
			}
		}

		return React.cloneElement(child, childProps);
	});

	return (
		<>
			{children}
			<Modal visible={isVisible} transparent={true}>
				<View style={STYLES.overlay}>	
					<View style={STYLES.content}>			
						<TouchableOpacity style={STYLES.close} onPressIn={onClose}>
							<Icon type='close' size={16} color='gray' />
						</TouchableOpacity>

						<Text style={{fontWeight: 'bold'}}>{props.title ?? 'Are you sure?'}</Text>
						<Text>Please confirm you want to {props.action}.</Text>
						<View style={STYLES.footer}>
							<Button variant='danger' style={STYLES.cta} onPress={onConfirm}>Yes, I'm sure!</Button>
							<Button variant='common' onPress={onClose}>Cancel</Button>
						</View>
					</View>
				</View>
			</Modal>
		</>
	)

	/*------------------------------------------*/

	function onConfirm() {
		const result = action.current && action.current(actionArgs.current);
		if (result && result.then) {
			return result.then(onClose);
		}
	} 

	function onClose() {
		setVisible(false);
	}
}

/*---------------------------------------------*/

const STYLES = {
	container: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
	},
	
	overlay: {
		flex: 1,
		backgroundColor: 'rgba(0,0,0,0.4)',
		justifyContent: "center",
		alignItems: "center",
		// opacity: 0.6
	},

	content: {
		backgroundColor: 'white',
		padding: 24,
		paddingRight: 48,
		width: 300,
		// left: '50%',
		// top: '50%',
		borderRadius: 2,
		// border: '1px solid var(--color-error-200)',
		// transform: 'translateX(-50%) translateY(-50%)',
		textAlign: 'left',
		boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.14), 0px 0px 22px rgba(0, 0, 0, 0.1)',
		// flex: 1, 
	},

	cta: {
		marginRight: 8,
	},
	
	footer: {
		flexDirection: 'row',
		marginTop: 16
	},
	
	close: {
		position: 'absolute',
		top: 16,
		right: 16,
		color: 'gray',
	},
}

/*---------------------------------------------*/

