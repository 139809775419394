const Icons = {
	pause: {
		web: 'pauseOutlineFilled',
		native: 'rounded-pause-button'
	},
	play: {
		web: 'playFilled',
		native: 'play-rounded-button'
	},
	like: {
		web: 'thumbsUpFilled',
		native: 'thumb-up-button',
	},
	comments: {
		web: 'chat',
		native: 'chat-bubble',
	},
	home: {
		web: 'home',
		native: 'home-button',
	},
	friends: {
		web: 'friendship',
		native: 'users-social-symbol',
	},
	account: {
		web: 'userAvatar',
		native: 'round-account-button-with-user-inside',
	},
	notifications: {
		web: 'notification',
		native: 'notifications-button',
	},
	send: {
		web: 'send',
		native: 'send-button',
	},
	close: {
		web: 'close',
		native: 'close-button',
	},
	chevronDown: {
		web: 'chevronDown',
		native: 'expand-button',
	},
	chevronUp: {
		web: 'chevronUp',
		native: 'expand-arrow',
	},
	more: {
		web: 'overflowMenuHorizontal',
	},
	link: {
		web: 'link',
	},
	report: {
		web: 'warning',
	},
	reportContent: {
		web: 'warningOther',
	},
	block: {
		web: 'error',
	},
}

export default Icons;