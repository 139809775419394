export default function InffuseFastspringClass(inffuse_instance) 
{
	var Inffuse = inffuse_instance;
	var self = this;
	
	this.renderUrl = function(url,params){
		params = params || {};
		params.platform = Inffuse.platform;
		params.user = Inffuse.user.id();

		if (Inffuse.site)
			params.site = Inffuse.site.id();

		var info = encodeURI(JSON.stringify(params));
		return url+"?referrer="+info;
	}

	this.open = function(product_url) {
		window.open(self.renderUrl(product_url));
	}
};