import React,{ useContext,useCallback,useEffect,useRef,useState } from 'react';
import UserDataContext from './admin-kit-local/tools/context';

import { Animated, Easing, Text, View } from 'react-native';

/*---------------------------------------------*/

export default function UnreadNotificationsCount(props) {
	const context = useContext(UserDataContext);
	const [count,setCount] = useState(0);
	const [nextCount,setNextCount] = useState(0);
	const animationStep = useRef(new Animated.Value(0));

	const handler = useCallback((event,data) => {
		const notifications = data.notifications;

		context.inffuse.services.mysql.run('getAccount',{})
			.then((response) => {
				const account = response.result[0];
				const unread = notifications.filter(notification => !notification.read && (notification.created > (account.last_ntfs_read || 0)));
				const newCount = unread.length;
		
				setNextCount(newCount);
			});

	},[]);

	useEffect(() => {
		// setInterval(() => {
		// 	setCount(count => {
		// 		if (count > 3) {
		// 			setNextCount(0);
		// 		} else {
		// 			setNextCount(count+1);
		// 		}

		// 		return count;
		// 	});
		// },2000);

		context.observe(handler,'NOTIFICATIONS_CHANGE');
		return () => context.unobserve(handler);
	},[]);

	useEffect(updateCount,[nextCount]);

	const maxScale = 1.3;
	const fontTransform = {
		transform: [
			{
				scale: animationStep.current
					.interpolate({ 
						inputRange: [0, 100],
						outputRange: [1, maxScale],
					}),
			},
		]
	}

	if (count === 0) {
		return null;
	}
	
	return (
		<Animated.View style={fontTransform}>
			<Text style={{...props.style}}>{count === 0 ? '' : count}</Text>
		</Animated.View>
	)

	/*------------------------------------------*/

	function updateCount() {
		// https://stackoverflow.com/a/59921727

		if (count === nextCount) {
			return;
		}

		const growDuration = 100;

		const pulseGrowAnimation = {
			useNativeDriver: true,
			toValue: 100,
			duration: growDuration,
			easing: Easing.out(Easing.ease)
		};

		const pulseShrinkAnimation = {
			useNativeDriver: true,
			toValue: 0,
			duration: 400,
			easing: Easing.out(Easing.ease)
		}

		Animated.timing(animationStep.current,pulseGrowAnimation).start();
		setTimeout(() => setCount(nextCount), 0);
		setTimeout(() => {
			Animated.timing(animationStep.current,pulseShrinkAnimation).start();
		},growDuration);
	}
}
