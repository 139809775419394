import { useContext, useState } from 'react';
import { ScrollView,Text,TextInput,View } from 'react-native';

import UserDataContext from '../admin-kit-local/tools/context';
import withLogin from '../admin-kit-local/tools/withLogin';
import { generateId } from '../admin-kit-local/tools/utils';
import { GlobalStyles } from '../styles';
import Button from '../utils/button';

/*---------------------------------------------*/

function ReportPage(props) {
	const context = useContext(UserDataContext);
	const entityId = props.route.params.entityId;
	const entityType = props.route.params.entityType;
	const entityName = props.route.params.entityName;
	const [message,setMessage] = useState(null);
	const [done, setDone] = useState(false);
	
	return (
		<ScrollView 
			keyboardShouldPersistTaps={'handled'} 
			contentContainerStyle={{width: '100%', minHeight: '100%', padding: 24,}}
		>
			<Text style={GlobalStyles.heading}>Report {entityType}</Text>
			<Text style={STYLE.text}>
				Did you find this {entityType} offensive, or otherwise inappropriate? 
			</Text>
			<View style={{height: 16}} />
			<Text style={STYLE.text}>
				Let us know! We'll review it and take measures in case it does not meet our community guidelines.
			</Text>
			<View style={{height: 24}} />
			<Text>Tell us more:</Text>
			<TextInput
				style={STYLE.textInput}
				multiline
				editable={!done}
				onChangeText={setMessage}
				onSubmitEditing={onReport}
				placeholder='Write a comment...' />
	
			<View style={{height: 24}} />
			<Button variant='primary' disabled={done} onPress={onReport} style={{alignSelf: 'flex-start'}}>
				{done ? 'Reported' : 'Report'}
			</Button>
		</ScrollView>
	)

	/*------------------------------------------*/

	function onReport() {
		context.inffuse.services.email.send('report',{
			userId: context.inffuse.user.id(), 
			userName: context.inffuse.user.name(),
			userEmail: context.inffuse.user.email(),
			message,
			entityId, 
			entityType
		});
		setDone(true);
	}
	
	/*------------------------------------------*/
}

/*---------------------------------------------*/

const STYLE = {
	text: {
		lineHeight: 20,
		paddingRight: 16,
	},
	textInput: {
		height: 150,
		marginTop: 4,
		padding: 8,
		backgroundColor: 'white',
		borderWidth: 1,
		borderColor: 'rgba(0,0,0,0.1)',
		textAlignVertical: 'top',
	}
}
/*---------------------------------------------*/

export default withLogin(ReportPage);
