import { forwardRef,useContext,useImperativeHandle,useEffect,useMemo,useRef } from 'react';
import { Audio } from 'expo-av';

import UserDataContext from '../admin-kit-local/tools/context';
import Image from '../utils/image';

Audio.setAudioModeAsync({
	playsInSilentModeIOS: true,
});

function AudioPlayer(props,ref) {
	const context = useContext(UserDataContext);
	const audioController = useMemo(() => new Audio.Sound(),[]);
	const post = props.post;
	const prevStatus = useRef({});
	const imageUrl = props.post?.song_thumb;

	useImperativeHandle(ref, () => ({
		play: async () => {
			audioController?.playAsync();
		},
		pause: async () => {
			await audioController?.pauseAsync();
		},
		seekTo: (position) => {
			audioController?.setPositionAsync(position*1000);
		},
		getCurrentTime: () => {
			return new Promise((resolve,reject) => {
				audioController?.getStatusAsync().then(status => resolve((status.positionMillis || 0) / 1000));
			});
		},
		getDuration: () => {
			return new Promise((resolve,reject) => {
				audioController?.getStatusAsync().then(status => resolve((status.durationMillis || 0) / 1000));
			})
		},
	}));

	useEffect(() => {
		audioController?.setOnPlaybackStatusUpdate(async playbackStatus => {
			const changes = ['isLoaded','isPlaying','didJustFinish'].filter(key => playbackStatus[key] !== prevStatus.current[key]);
			if (!changes.length) {
				return;
			}

			if (changes.includes('isLoaded') && playbackStatus.isLoaded) {
				props.onStateChange('video cued');
			}
			
			if (changes.includes('isPlaying') && playbackStatus.isPlaying) {
				props.onStateChange('playing');
			}

			if (changes.includes('isPlaying') && !playbackStatus.isPlaying) {
				props.onStateChange('paused');
			}
			
			if (changes.includes('didJustFinish') && playbackStatus.didJustFinish) {
				props.onStateChange('ended');
			}

			prevStatus.current = {...playbackStatus};
		});
	},[]);

	useEffect(() => {
		if (!post?.key_name) {
			return;
		}

		const trackPromise = context.demo 
			? Promise.resolve({track: {preview_url: "https://p.scdn.co/mp3-preview/79ae1833a4ced018c00435674564d60c5b96afb8"}})
			: context.inffuse.services.spotify.track(post.key_name);
			
		trackPromise.then(async result => {
			// https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement
			await audioController.loadAsync({ uri: result.track.preview_url });
			await audioController?.playAsync();
		});

		return () => {
			audioController?.unloadAsync();
		}
	},[post?.key_name]);

	return null;
}

export default forwardRef(AudioPlayer);
