export default function InffuseStripeClass(inffuse_instance) 
{
	var Inffuse = inffuse_instance;
	
	this.checkout = function(params) {
		
		var publishable_key = params.publishable_key;

		function checkout() {
			var handler = StripeCheckout.configure({
				key: publishable_key,
				image: params.image,
				token: function(token) {
					// console.log(token);
					switch (params.type) {
						case 'charge':
							Inffuse.services.stripe.charge(token.id,params.amount,params.test);
							break;

						case 'subscription':
							Inffuse.services.stripe.subscribe(token.id,params.plan,params.test);
							break;
					}
				}
			});

			var period = params.period || 'month';
			handler.open({
				name: params.name,
				description: params.description,
				amount: params.amount*100,
				panelLabel: 'Subscribe ({{amount}}/'+period+')',
				allowRememberMe: false
			});
		}


		Inffuse.loadScript("https://checkout.stripe.com/checkout.js",checkout);
	}
};