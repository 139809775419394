import React from 'react';
import { Image as NativeImage, Text, View } from 'react-native';

/*---------------------------------------------*/

function Image(props) {
	const nativeProps = {
		...props,
		src: undefined,
		source: {uri: props.src},
	}

	return <NativeImage {...nativeProps} />
}

/*---------------------------------------------*/

const Avatar = (props) => {
	const getInitials = (name) => {
		if (!name) {
			return <Text></Text>;
		}

		const parts = name.split(' ');
		const initials = parts.map(part => part[0].toUpperCase()).join('');

		return [
			<Text style={STYLES.textShadow} key='textShadow'>{initials}</Text>,
			<Text style={STYLES.text} key='text'>{initials}</Text>,
		]
	}

	const imageUri = props.image || (props.id && `https://s3.amazonaws.com/img.ollbeat.com/${props.id.replace("user_","")}/profile.jpg`);

	const colorId = props.id?.slice(-1).charCodeAt();
	const bgColor = COLORS[colorId % COLORS.length];
	const style = {
		...props.style, 
		...STYLES.container,
		backgroundColor: bgColor,
	};

	return (
		<View style={style} className={`${props.className} avatar`} onClick={props.onClick}>
			<View>{getInitials(props.name)}</View>
			<Image src={imageUri} style={STYLES.image} />
		</View>
	)
}

/*---------------------------------------------*/

// https://colorbrewer2.org/?type=qualitative&scheme=Paired&n=12
const COLORS = ['#a6cee3','#1f78b4','#b2df8a','#33a02c','#fb9a99','#e31a1c','#fdbf6f','#ff7f00','#cab2d6','#6a3d9a','#ffff99','#b15928'];

/*---------------------------------------------*/

const STYLES = {
	container: {
		borderRadius: 100,
		overflow: 'hidden',
		width: 32, 
		height: 32,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: '#ddd',
		borderColor: 'rgba(0,0,0,0.2)',
		borderWidth: 1,
	},
	image: {
		position: 'absolute',
		left: 0,
		top: 0,
		right: 0,
		bottom: 0,
		zIndex: 2,
	},
	text: {
		color: 'white',
		zIndex: 1,
	},
	textShadow: {
		position: 'absolute',
		left: 0.5,
		top: 0.5,
		color: 'black',
	}
}

export default Avatar;