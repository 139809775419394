function InffuseProjectClass(inffuse_instance,meta,data,flags)
{
	var Inffuse = inffuse_instance;
	var self = this;
	var scope = Inffuse.user.accessToken() ? 'private' : 'public';

	var dataStores = {};
	var defaultDataStore;


	// Undocumented
	function init()
	{
		dataStores["private"] = new Inffuse.DataStore(Inffuse, 'projects', self.id(), 'private');
		dataStores["public"] = new Inffuse.DataStore(Inffuse, 'projects', self.id(),'public');
		defaultDataStore = dataStores[scope];

		defaultDataStore.populate(data);

		if (typeof document != 'undefined') { // browser
			document.addEventListener('keyup', function(e) {
				if (e.ctrlKey && e.altKey){
					// Ctrl-Alt-D-A to open the dashboard
					if (e.keyCode == 65 && self._last_key == 68)
						window.open(self.manage());

					self._last_key = e.keyCode;
				}
			}, false);
		}
	}
	
	/**
	 * Get the dataStore for the default scope or the <scope_override> for the project
	 * @method Inffuse.project.getDataStore
	 * @param {string} scope_override if given, overrides the default scope. legal values are "private" or "public".
	 */
	this.getDataStore = function(scope_override)
	{
		if (scope_override)
			return dataStores[scope_override];
		
		return defaultDataStore;
	}
	
	// internal
	this.meta = function(key) {
		return meta ? meta[key] : null;
	}

	/**
	 * Returns the current project ID
	 * @method Inffuse.project.id
	 * @returns {String}
	 * @example
	 * 	var project_id = Inffuse.project.id()
	 * 	console.log("Project ID = %s", project_id);
	 */
	this.id = function()
	{
		return self.meta('id');
	}

	/**
	 * Returns the current project created
	 * @method Inffuse.project.created
	 * @returns {String}
	 * @example
	 * 	var project_created = Inffuse.project.created()
	 * 	console.log("Project created = %s", project_created);
	 */
	this.created = function()
	{
		return self.meta('created');
	}

	/**
	 * Returns the values of all custom flags
	 * @method Inffuse.project.flags
	 * @returns {Object}
	 * @example
	 * 	var project_flags = Inffuse.project.flags()
	 * 	console.log("Project Disabled = %s", project_flags.disabled);
	 */
	this.flags = function()
	{
		return flags;
	}

	/**
	 * Returns the value of a custom flag
	 * @method Inffuse.project.flag
	 * @returns {String}
	 * @example
	 * 	var disabled_flag = Inffuse.project.flag('disabled')
	 * 	console.log("Project Disabled = %s", disabled_flag);
	 */
	this.flag = function(key)
	{
		return flags && flags[key];
	}

	/**
	 * Returns the current project key name
	 * @method Inffuse.project.key
	 * @returns {String}
	 * @example
	 * 	var project_key = Inffuse.project.key()
	 * 	console.log("Project Key = %s", project_key);
	 */
	this.key = function()
	{
		return self.meta('key_name');
	}

	/**
	 * Returns the current project name
	 * @method Inffuse.project.name
	 * @returns {string}
	 * @example
	 * 	var project_name = Inffuse.project.name()
	 * 	console.log("Project Name = %s", project_name);
	 */
	this.name = function()
	{
		return self.meta('name');
	}

	/**
	 * Returns true when the project is first created
	 * @method Inffuse.project.isNew
	 * @returns {boolean}
	 * @example
	 * 	var is_new = Inffuse.project.isNew()
	 * 	console.log("Project Is %s", is_new ? "new":"not new");
	 */
	this.isNew = function()
	{
		return meta['new'] == true;
	}
	
	/**
	 * Returns the ID of the site which owns the project
	 * @method Inffuse.project.siteID
	 * @returns {string}
	 * @example
	 * 	var site_id = Inffuse.project.siteID()
	 * 	console.log("The site ID of the project %s", site_id);
	 */
	this.siteID = function()
	{
		return self.meta('site_id');
	}
	
	/**
	 * Set the <value> under <key> entry in the project data.
	 * @method Inffuse.project.set
	 * @param {string} key Key to the data item.
	 * @param {mixed} value The value to be set.
	 * @param {boolean} in_batch=false Choose whether this is part of a batch operation. If set to true, the data will not be saved in the cloud, and Inffuse.project.save() will need to be called later.
	 * @param {string} scope_overide if given, overrides the default scope. legal values are "private" or "public".
	 * @returns {object} jQuery jqXHR object
	 * @example Simple set
	 * 	Inffuse.project.set('name', 'John');	 
	 * 	Inffuse.project.set('age', 32);	 
	 * @example Wait for response
	 * 	Inffuse.project.set('params', {
	 * 		weight: 78,
	 * 		height: 180, 
	 * 		alias: "Johnny"
	 * 	})
	 * 	.done(function(){
	 * 		// Success!
	 *  	})
	 * 	.fail(function(){
	 * 		// Handle set failure
	 * 	});
	 */
	this.set = function(key,value,in_batch,scope_overide)
	{
		if (!this.id())
			return Inffuse.error("Project does not exist");

		return this.getDataStore(scope_overide).set(key,value,in_batch);
	}

	/**
	 * Get the value stored under the <key> in the project data.
	 * @method Inffuse.project.get
	 * @param {string} key Key to the data item.
	 * @param {string} default_value The default value to return, if <key> is not found.
	 * @returns {mixed} Value for <key>, if <key> not found returns the default_value. if no default_value supplied returns 'undefined'.
	 * @example
	 * 	var name = Inffuse.project.get('name', null);	 
	 * 	if (name)
	 * 	{
	 * 		//do something...
	 * 	}
	 * 	else
	 * 	{
	 * 		//key=name does not exsist...
	 * 	}
	 */
	this.get = function(key,default_value)
	{
		if (!this.id())
			return Inffuse.error("Project does not exist");
		
		return defaultDataStore.get(key,default_value);
	}


	/**
	 * Get the value stored under the key on the server.
	 * @method Inffuse.project.loadData
	 * @param {string} key Key to the data item.
	 * @param {string} scope_overide Defines if the value will be taken from "public" or "private" areas in the DB.
	 * @param {string} default_value The default value to return, if <key> is not found.
	 * @returns the XHR requestAPI call. 
	 */
	this.loadData = function(key, scope_overide, default_value)
	{
		if (!this.id())
			return Inffuse.error("Project does not exist");
		
		return this.getDataStore(scope_overide).loadData(key,default_value);
	}


	/**
	 * Append the <value> to an array stored under <key>. If <key> is not set yet a new array will be created.
	 * @method Inffuse.project.append
	 * @param {string} key Key to the array.
	 * @param {mixed} value The value to be appended.
	 * @returns {object} jQuery jqXHR object
	 * @example
	 * 	for(var i=0; i<10; i++)
	 * 		Inffuse.project.append("my_array", "item"+i)
	 */
	this.append = function(key,value)
	{
		if (!this.id())
			return Inffuse.error("Project does not exist");
		
		return defaultDataStore.append(key,value);
	}

	/**
	 * Removes the key entry from project data.
	 * @method Inffuse.project.del
	 * @param {string} key Key to the data item.
	 * @param {boolean} in_batch=false Choose whether this is part of a batch operation. If set to true, the data will not be deleted in the cloud, and Inffuse.project.save() will need to be called later.
	 * @example
	 * 	Inffuse.project.del("my_key")
	 * 	console.log("my_key is deleted!");
	 */
	this.del = function(key,save)
	{
		return defaultDataStore.del(key,save);
	}

	// Undocumented
	this.setData = function(project_data)
	{
		return defaultDataStore.setData(project_data);
	}


	/**
	 * Set the all the values in <obj> under the matching keys in <obj> in the project data.
	 * @method Inffuse.project.setMulti
	 * @param {object} obj an object containing a list of key:value entries.
	 * @param {boolean} in_batch=false Choose whether this is part of a batch operation. If set to true, the data will not be saved in the cloud, and Inffuse.project.save() will need to be called later.
	 * @returns {object} jQuery jqXHR object
	 * @example Simple setMulti
	 * 	Inffuse.project.setMulti({
	 * 		name: 'My Name', 
	 * 		age: 29, 
	 * 		my_list: ['item1', 'item2', item3],
	 * 		my_obj: {first: 1, second: 2, third: 999}
	 * 	});
	 * @example Wait for response
	 * 	Inffuse.project.setMulti({
	 * 		name: 'My Name', 
	 * 		my_list: ['item1', 'item2', item3]
	 * 	})
	 * 	.done(function(){
	 * 		// Success!
	 *  	})
	 * 	.fail(function(){
	 * 		// Handle setMulti failure
	 * 	});
	 */
	this.setMulti = function(obj,in_batch)
	{
		if (!this.id())
			Inffuse.error("Project does not exist");

		return defaultDataStore.setMulti(obj,in_batch);
	}

	
	// Undocumented
	this.setToken = function(service_name,token_key,token_value)
	{
		var params = {
			project: self.id(),
			service: service_name,
			key: token_key,
			value: token_value
		};
			
		var url = ['projects',self.id(),'settoken'].join('/');
		return Inffuse.requestAPI(url,params,'POST');
	}
	

	/**
	 * Save all changes previusely made with in_batch=true to the cloud.
	 * @method Inffuse.project.save
	 * @returns {object} jQuery jqXHR object
	 * @example
	 * 	Inffuse.project.set('name', 'John', true);	 
	 * 	Inffuse.project.set('age', 32, true);	 
	 * 	Inffuse.project.set('params', {
	 * 		weight: 78, 
	 * 		height: 180,
	 * 		alias: "Johnny"
	 * 	}, true);
	 *     	
	 * 	Inffuse.project.save()
	 * 		.done(function(){
	 * 			// Success
	 * 	 	})
	 * 		.fail(function(){
	 * 			// Handle batch save failure
	 * 		});
	 */
	this.save = function()
	{
		if (!this.id())
			Inffuse.error("Project does not exist");

		return defaultDataStore.save()
	}

	
	/**
	 * Publish the current project data.
	 * @method Inffuse.project.publish
	 * @returns {object} jQuery jqXHR object
	 * @example
	 * 	Inffuse.project.publish()
	 * 		.done(function(){
	 * 			console.log("Project Data Published OK");
	 * 		})
	 * 		.fail(function(){
	 * 			console.error("Error publishing Project Data!");		
	 * 		});
	 */
	this.publish = function()
	{
		Inffuse.broadcast('project-published',undefined,true);

		var url = ['projects',self.id(),'data','publish'].join('/');
		var params = {
			user: Inffuse.user.id(),
			project: self.id()
		};
		
		return Inffuse.requestAPI(url,params,'POST');
	}

		
	/**
	 * Delete the project from the system
	 * @method Inffuse.project.remove
	 * @returns {object} jQuery jqXHR object
	 * @example
	 * 	Inffuse.project.remove()
	 * 		.done(function(){
	 * 			console.log("Project was deleted");
	 * 		})
	 * 		.fail(function(){
	 * 			console.error("Error deleting this project");		
	 * 		});
	 */
	this.remove = function()
	{
		Inffuse.broadcast('project-deleted',undefined,true);

		var url = ['projects',self.id()].join('/');
		var params = {
			user: Inffuse.user.id()
		}

		// sync is needed to prevent the widget to be deleted
		// and the request be cancelled
		return Inffuse.requestAPI(url,params,'DELETE',false,true);
	}
	

	/**
	 * Update the project
	 * @method Inffuse.project.update
	 * @returns {object} jQuery jqXHR object
	 * @example
	 * 	Inffuse.project.remove()
	 * 		.done(function(){
	 * 			console.log("Project was deleted");
	 * 		})
	 * 		.fail(function(){
	 * 			console.error("Error deleting this project");		
	 * 		});
	 */
	this.update = function(params)
	{
		params['user'] = Inffuse.user.id();

		var url = ['projects',self.id()].join('/');
		return Inffuse.requestAPI(url,params,'POST');
	}


	// Undocumented
	this.refreshWidget = function(params)
	{
		Inffuse.broadcast('refresh-widget',params);
	}

	
	/**
	 * Request the widget height to be updated
	 * @method Inffuse.project.updateHeight
	 * @param {integer} height The new height. If nothing is passed - the widget height will be updated to the body height.
	 * @example
	 * 	var height = $('#content') + 20;
	 * 	Inffuse.project.updateHeight(height)
	 */
	this.updateHeight = function(height)
	{
		if (typeof height == 'undefined')
		{
			var container = document.getElementsByClassName('inffuse-container');
			if (container.length)
				container = container[0];
			else
				container = document.body;

			height = container.offsetHeight;
		}

		self.resize({height:height});
	}


	// Undocumented
	this.resize = function(params)
	{
		if (params.height && Inffuse.platform == 'wix' && typeof Wix != 'undefined')
			Wix.setHeight(params.height);
		
		Inffuse.broadcast('resize',params);
	}

	// Undocumented
	this.preview = function(params)
	{
		self.refreshWidget(params);
	}

	/*------------------------------------------*/

	/**
	 * Helper function to display a direct link to the users account in Inffuse dashboard.
	 * The link will be printed using console.log().
	 * @method Inffuse.project.manage
	 */
	this.manage = function() {
		var host = Inffuse.server.indexOf("local") == -1 ? "dashboard.inffuse.com" : "dev.inffuse.local:28099";
		var url = [
			"http:/",
			host,
			"app:"+Inffuse.app.id(),
			"users",
			"project:"+self.id()
		].join('/');

		console.log('To manage the project go to: ',url);
		return url;
	}

	/*------------------------------------------*/

	init();
};

export default InffuseProjectClass;