// import '/web/index.scss';

import React from 'react';
import * as Linking from 'expo-linking';
import { TouchableOpacity, Text, TextInput, View } from 'react-native';
import { useContext, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { GlobalColors, GlobalStyles } from '../styles';

import UserDataContext from '../admin-kit-local/tools/context';
import { generateId } from '../admin-kit-local/tools/utils.js';
import Avatar from '../admin-kit-local/components/avatar';
import Confirm from '../admin-kit-local/patterns/confirm';

import TimeAgo from '../utils/timeAgo';
import Icon from '../utils/icon';
import SkeletonBar from './skeletonBar';

/*---------------------------------------------*/

function Link(props) {
	return (
		<TouchableOpacity className='action' size='xsmall' onPress={props.onClick} style={props.style}>
			{props.children}
		</TouchableOpacity>
	)
}

/*---------------------------------------------*/

function CommentsSection(props) {
	const context = useContext(UserDataContext);
	// const anchoredId = document.location.hash?.replace("#","");
	const anchoredId = props.highlightCommentId;
	const post = props.post;
	const [comments,setComments] = useState(post.comments ?? null);

	useEffect(() => {
		if (props.open && !post.comments) {
			refresh();
		}
	}, [post.id,props.open,post._data_timestamp]);

	useEffect(() => {
		context.observe(onPostChangedEvent,['POST_CHANGED']);

		return () => context.unobserve(onPostChangedEvent);

	},[]);

	if (!props.open) {
		return null;
	}

	return (
		<View style={props.style}>
			{getComments()}
			<CommentsForm post={post} onSubmitted={onCommentSubmitted} key={comments?.length} />
		</View>
	);

	/*---------------------------------------------*/

	function onPostChangedEvent(data) {
		// context.log("[CommentSection.onPostChangedEvent]",{data});
		if (data.post_id !== post.id) {
			return;
		}

		refresh();
	}

	/*---------------------------------------------*/

	function refresh() {
		context.inffuse.services.mysql.run('getComments', { post_id: post.id })
			.then((response) => {
				const newComments = response.result;
				newComments.forEach(comment => {
					if (comment.likes) {
						comment['likes'] = JSON.parse(`[${comment.likes}]`);
					}
				});

				setComments(newComments);
				props.onChange(newComments);
			});
	}

	/*------------------------------------------*/

	function onCommentSubmitted(comment) {
		const promises = [];

		const notifData = {
			userId: context.inffuse.user.id(),
			userName: context.inffuse.user.name(),
			postId: post.id,
			songName: post.song_name,
			songArtist: post.song_artist,
			commentId: comment.id,
			commentText: comment.text,
		};

		if (post.user_id !== context.inffuse.user.id()) {
			promises.push(context.notify(post.user_id,'NOTIF_COMMENT',notifData));
		}

		const threadCommenters = getUniqueValues(comments.map(comment => comment.user_id))
			.filter((userId,index,self) => (userId !== post.user_id) && (userId !== context.inffuse.user.id()));

		const likeUsers = post.likes && post.likes
			.filter((like,index,self) => (like.user_id !== post.user_id) && (like.user_id !== context.inffuse.user.id()))
			.filter((like,index,self) => threadCommenters.indexOf(like.user_id) === -1); // don't send 2 notifications

		threadCommenters.forEach(commenterId => promises.push(context.notify(commenterId,'NOTIF_COMMENT_THREAD',notifData)));
		likeUsers?.forEach(like => promises.push(context.notify(like.user_id,'NOTIF_LIKED_POST_COMMENT',notifData)));
	
		refresh();

		return Promise.all(promises);
	}

	/*------------------------------------------*/

	function getComments() {
		if (comments && !comments.length) { // comments are loaded and empty
			return null;
		}

		if (!comments && !post.comments_count) { // comments not loaded but seem empty
			return null;
		}

		const content = (comments === null) // loading
			? (new Array(Math.min(2, post.comments_count)).fill(null).map((_x, index) => <CommentSkeleton index={index} key={index} />))
			: [
				// ...(new Array(Math.min(2, post.comments_count)).fill(null).map((_x, index) => <CommentSkeleton index={index} key={index} />)),
				...comments?.map((comment, index) => <Comment  onChange={refresh} post={post} comment={comment} highlight={comment.id===anchoredId} key={comment.id} />)
			];

		return (
			<View style={STYLE.comments}>
				{content}
			</View>
		)
	}
}

/*---------------------------------------------*/

function Comment(props) {
	const navigation = useNavigation();
	const context = useContext(UserDataContext);
	const post = props.post;
	const [commentLocal,setCommentLocal] = useState(null);
	const comment = (commentLocal?._ts || 0) > (props.comment?._ts || 0) ? commentLocal : props.comment;
	
	const isOwner = comment.user_id === context.inffuse.user.id();
	const isLiked = !!comment.likes?.find(like => like.user_id === context.inffuse.user.id());
	const highlightStyle = props.highlight ? {backgroundColor: GlobalColors['yellow-300']} : null;

	return (
		<View style={{...STYLE.comment.container, ...highlightStyle}}>
			<TouchableOpacity onPress={() => navigation.navigate('User',{userId: comment.user_id})}>
				<Avatar style={STYLE.comment.avatar} id={comment.user_id} name={comment.user_name} image={comment.user_image} />
			</TouchableOpacity>
			<View style={STYLE.comment.details}>
				<View style={STYLE.comment.content}>
					<TouchableOpacity onPress={() => navigation.navigate('User',{userId: comment.user_id})}>
						<Text style={STYLE.comment.username}>{comment.user_name}</Text>
					</TouchableOpacity>
					<Text style={STYLE.comment.text}>{activate(comment.text)}</Text>
				</View>
				<View style={STYLE.comment.actionBar}>
					<Text style={STYLE.comment.date}><TimeAgo time={comment.created} /></Text>
					<Text style={STYLE.comment.actions.separator}>·</Text>
					<Link onClick={isLiked ? onUnlike : onLike}>
						<Text style={{...STYLE.comment.action, ...(isLiked ? STYLE.comment.actionLiked : {})}}>Like</Text>
					</Link>
					{
						isOwner 
						? (
							<>
								<Text style={STYLE.comment.actions.separator}>·</Text>
								<Confirm action={`delete this comment`}>
									<Link onClick={onDelete}><Text style={STYLE.comment.delete}>Delete</Text></Link>
								</Confirm>
							</>
						)
						: null
					}
					<Text style={STYLE.comment.actions.separator}>·</Text>
					<Text style={STYLE.comment.report} onPress={() => navigation.navigate('Report',{entityId: comment.id, entityType: 'comment'})}>Report</Text>
					{
						comment.likes?.length
						? (
							<TouchableOpacity 
								style={{flexGrow: 1, flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}
								onPress={() => navigation.navigate('Likes',{entityId: comment.id})}
							>
								<Icon type='like' size={10} color='purple' style={{marginRight: 4}} />
								<Text style={STYLE.comment.likes}>{comment.likes.length}</Text>
							</TouchableOpacity>
						)
						: null
					}
				</View>
			</View>
		</View>
	)

	/*------------------------------------------*/

	function activate(text) {
		const urls = text.match(/(http\S*)/g);
		const parts = [];
		let remainder = text;

		if (!urls) {
			return text;
		}

		urls.forEach((url,index) => {
			const [prefix,...suffix] = remainder.split(url);
			remainder = suffix.join(url);

			parts.push(<Text key={`prefix-${index}`}>{prefix}</Text>);
			parts.push(
				<Text style={{color: GlobalColors['primary-600']}} onPress={async () => await Linking.openURL(url)} key={`link-${index}`}>
					{url}
				</Text>
			);
		});

		return parts;
	}

	/*------------------------------------------*/

	function onLike() {
		setCommentLocal({
			...comment,
			_ts: Math.floor(1 * new Date() / 1000),
			likes: [
				...(comment?.likes || []),
				{
					user_id: context.inffuse.user.id(),
					user_name: context.inffuse.user.name(),
				}
			]
		});

		return context.inffuse.services.mysql.run('like',{
			id: generateId("like"),
			user_id: context.inffuse.user.id(),
			entity_id: comment.id,
			type: "like",
		})
		.then((response) => {
			if (comment.user_id !== context.inffuse.user.id()) {
				context.notify(comment.user_id,'NOTIF_COMMENT_LIKE',{
					userId: context.inffuse.user.id(),
					postId: post.id,
					commentId: comment.id,
					commentText: comment.text,
					userName: context.inffuse.user.name(),
					songName: post.song_name,
					songArtist: post.song_artist,
				});
			}

			context.track("Comment Liked",{comment_id: comment.id, author_id: comment.user_id});
			context.broadcast('POST_CHANGED',{post_id: post.id});
		})
		.catch(error => {
			context.track("Like Failed",{comment_id: comment.id, author_id: post.user_id, error: error.message});
		})
		.finally(() => props.onChange());
	}

	function onUnlike() {
		setCommentLocal({
			...comment,
			_ts: Math.floor(1 * new Date() / 1000),
			likes: comment.likes.filter(user => user.user_id !== context.inffuse.user.id())
		});

		return context.inffuse.services.mysql.run('unlike',{
			entity_id: comment.id,
		})
		.then((response) => {
			context.track("Comment Unliked",{comment_id: comment.id, author_id: comment.user_id});
			context.broadcast('POST_CHANGED',{post_id: post.id});
		})
		.catch(error => {
			context.track("Unlike Failed",{comment_id: comment.id, author_id: post.user_id, error: error.message});
		})
		.finally(() => props.onChange());
	}

	/*------------------------------------------*/

	function onDelete() {
		const result = context.inffuse.services.mysql.run('deleteComment',{
			id: comment.id
		})
		.then((response) => {
			props.onChange();

			context.track("Comment Deleted",{post_id: post.id, author_id: post.user_id, comment_id: comment.id});
			context.broadcast('POST_CHANGED',{post_id: post.id});
		});

		return result;
	}
}

/*---------------------------------------------*/

function CommentsForm(props) {
	const context = useContext(UserDataContext);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [draftText,setDraftText] = useState(null);
	const [contentHeight, setContentHeight] = useState(0);
	const post = props.post;

	return (
		<View style={{...props.style, ...STYLE.form.container, minHeight: contentHeight}}>
			<Avatar style={STYLE.comment.avatar} id={context.inffuse.user.id()} name={context.inffuse.user.name()} />
			<TextInput
				style={STYLE.form.textInput}
				multiline
				editable={!isSubmitting}
				onChangeText={setDraftText}
				onContentSizeChange={({ nativeEvent: { contentSize: { width, height } } })  => setContentHeight(height)}
				onSubmitEditing={() => onCommentSubmitted()}
				placeholder='Write a comment...' />
			
			<TouchableOpacity onPress={() => onCommentSubmitted()} style={STYLE.form.button}>
				<Icon type='send' size={16} color={GlobalColors['neutral-600']} style={STYLE.form.buttonIcon} />
			</TouchableOpacity>
		</View>
	)

	/*------------------------------------------*/

	function onCommentSubmitted(data) {
		setIsSubmitting(true);

		const comment = {
			id: generateId("comment"),
			user_id: context.inffuse.user.id(),
			post_id: post.id,
			text: draftText,
		};

		context.inffuse.services.mysql.run('addComment', comment)
			.then((response) => {
				props.onSubmitted(comment);

				context.track("Comment Added",{post_id: post.id, author_id: post.user_id, comment_id: comment.id});
				context.broadcast('POST_CHANGED',{post_id: post.id});
			});
	}
}

/*---------------------------------------------*/

function CommentSkeleton(props) {
	return (
		<View className='comment' style={{...STYLE.comment.container, opacity: 1 - props.index * 0.3 }}>
			<SkeletonBar style={{...STYLE.comment.avatar, width: 32, height: 32, borderRadius: 100}} />
			<View style={STYLE.comment.details} >
				<View className='content' style={STYLE.comment.content} >
					<SkeletonBar style={{width: '40%', marginTop: 4}} />
					<SkeletonBar style={{width: '70%', opacity: 0.6}} />
				</View>
				<View style={STYLE.comment.actionBar}>
					<SkeletonBar style={{width: '35%', minHeight: 10, opacity: 0.3, marginTop: 2}}/>
				</View>
			</View>
		</View>
	);
}

/*---------------------------------------------*/

function getUniqueValues(array) {
	return array.filter((value,index,self) => self.indexOf(value) === index);
}

/*---------------------------------------------*/

const backgroundColor = GlobalColors['neutral-100'];

const STYLE = {
	comments: {
		paddingTop: 8,
		borderTopWidth: 1, 
		borderColor: GlobalStyles.hr.borderColor,
	},
	comment: {
		container: {
			flexDirection: 'row', 
			paddingTop: 8,
			paddingBottom: 8,
			paddingLeft: 16,
			paddingRight: 16,
			marginLeft: -16,
			marginRight: -16,
		},
		avatar: {
			marginRight: 8,
			marginTop: 2,
		},
		details: {
			flex: 1,
			flexGrow: 1,
		},
		content: {
			backgroundColor: backgroundColor, 
			borderRadius: 4, 
			padding: 8, 
			paddingTop: 4, 
			paddingBottom: 4,
		},
		username: {
			fontFamily: GlobalStyles.fonts.primaryBold,
		},
		text: {
			fontFamily: GlobalStyles.fonts.body,
		},
		actionBar: {
			paddingLeft: 8,
			marginTop: 4,
			flexDirection: 'row',
		},
		date: {
			fontSize: 12,
			color: 'gray',
		},
		delete: {
			fontSize: 12,
		},
		report: {
			fontSize: 12,
		},
		actions: {
			separator: {
				marginLeft: 4,
				marginRight: 4,
				marginTop: -1,
				fontSize: 14,
			},
		},
		action: {
			color: GlobalColors['neutral-800'],
			fontSize: 12,
			fontWeight: 'normal',
		},
		actionLiked: {
			color: 'purple',
			fontWeight: 'bold',
		},
		likes: {
			fontSize: 12,
		}
	},
	form: {
		container: {
			flexDirection: 'row',
			alignItems: 'flex-start',
			marginBottom: 8,
			paddingTop: 12,
			boxSizing: 'content-box',
			borderTopWidth: 1, 
			borderColor: GlobalStyles.hr.borderColor,
		},
		textInput: {
			backgroundColor: backgroundColor,
			padding: 4,
			paddingLeft: 8,
			minHeight: 36,
			borderRadius: 4,
			flexGrow: 1,
			alignSelf: 'stretch',
			flex: 1,
		},
		button: {
			backgroundColor: backgroundColor, 
			paddingLeft: 8,
			paddingRight: 8,
			marginLeft: 4,
			borderRadius: 4,
			minHeight: 36,
			flexDirection: 'column',
			justifyContent: 'center',
		},
		buttonIcon: {
			opacity: 1,
		}
	}
}

/*---------------------------------------------*/

export default CommentsSection;
