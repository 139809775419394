
/*---------------------------------------------*/

function generateId(prefix = "", length = 21) {
	const chars = "abcdefghijklmnopqrstuvwxyz0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	
	let id = new Array(length).fill(null).map(_ => chars[Math.floor(Math.random() * chars.length)]).join('');
	if (prefix) {
		id = `${prefix}_${id}`;
	}

	return id;
}

/*---------------------------------------------*/

function formatDate(date) {
	return new Date(date).toLocaleDateString();
}

/*---------------------------------------------*/

export {
	generateId,
	formatDate
};