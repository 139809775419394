import InffuseUtils from './utils.js';
import InffuseUI from './ui.js';
import InffuseApp from './app.js';
import InffuseUserClass from './user.js';
import InffuseSite from './site.js';
import InffuseProjectClass from './project.js';
import InffuseProjectsClass from './projects.js';
import InffuseQuotas from './quotas.js';

import InffuseServices from './services.js';
import InffuseStripeClass from './services/stripe.js';
import InffuseFastspringClass from './services/fastspring.js';

import InffuseWixClass from './platforms/wix.js';
import InffuseShopifyClass from './platforms/shopify.js';
import InffuseWeeblyClass from './platforms/weebly.js';

import { Platform } from 'react-native';
import * as Linking from 'expo-linking';

function InffuseSDK_01(app_id,contextObject)
{
	var instance = this;
	var self = this;

	this.app_id = app_id;
	this.loaderVersion = "0.1";
	this.server = "https://inffuse-platform.appspot.com";
	this.context_params = null;
	
	var EVENT_HANDLERS = {};
	
	/*------------------------------------------*/
	/*------------------------------------------*/

	self.ui = new InffuseUI(self);
	self.utils = new InffuseUtils(self,contextObject);

	/*------------------------------------------*/
	
	this.getContext = function(callback,callback_error) {
		contextObject.log("[Inffuse.getContext]");
		let query_params = self.utils.buildQuery(self.context_params);
		const data = self.context_params || {};

		if (typeof document != 'undefined') { // browser 
			query_params = query_params || document.location.search.slice(1);
			data["_referrer"] = document.referrer;
			data["_origin"] = document.location.href; // referer and origin are sometimes blocked, for instance in FF private session
		}
		
		const url = self.server+"/js/v"+self.loaderVersion+"/"+app_id+"/data?"+(query_params||'');
		// console.log("[Inffuse.getContext]",{url});
		self.requestAPI(url,data,'POST',true)
			.then(context => {
				contextObject.log("[Inffuse.getContext] requestAPI.then")
				// console.log("[Inffuse.getContext] Got response,",{context: JSON.stringify(context),callback});
				callback(context);
			})
			.catch(error => {
				contextObject.log("[Inffuse.getContext] requestAPI.error",{error})
				if (error.status == 303) {
					document.location.href = response.redirect_url;
					return;
				}

				if (callback_error)
					callback_error(error.status,response);
			})
	}

	this.initWithContext = function(context,callback) {
		self.app = new InffuseApp(instance, context.app.meta, context.app.data);
		self.platform = context.platform;
		self.server	= context.server;
		self.apiVersion = context.api_version;
		self.editing = context.editing;
		self.external_script = context.external_script;
		self._viewMode = context.view_mode;

		if (context.user)
			self.user = new InffuseUserClass(self, context.user.meta, context.user.data);
		else
			self.user = new InffuseUserClass(self,{},{});

		
		if (context.user) {
			if (context.site)
				self.site = new InffuseSite(self, context.site.meta);

			if (context.project)
				self.project = new InffuseProjectClass(self, context.project.meta, context.project.data, context.project.flags);
		}
		
		self.projects = new InffuseProjectsClass(self);
		self.quotas = new InffuseQuotas(self, context.quotas);
		
		self.services = new InffuseServices(self, context.services);
		self.stripe = new InffuseStripeClass(self);
		self.fastspring = new InffuseFastspringClass(self);
		
		switch (self.platform) {
			case 'wix':
				self.wix = new InffuseWixClass(self);
				self.wix.init();
				break;

			case 'weebly':
				self.weebly = new InffuseWeeblyClass(self);
				self.weebly.init();
				break;

			case 'shopify':
				self.shopify = new InffuseShopifyClass(self);
				self.shopify.init(context.shopify);
				break;
		}


		if (typeof callback != "undefined")
			callback(self,context);

		self.ready();
	}

	/*------------------------------------------*/

	this.init = function(callback,callback_error)
	{
		// console.log("[Inffuse.init]");
		self.getContext(function(context) {
			// console.log("[Inffuse.init] getContext callback");
			self.initWithContext(context,callback);
			// console.log("[Inffuse.init]", {ready: self.ready(), user: context.user, userId: self.user?.id()});
		},callback_error);
	}

	/*------------------------------------------*/

	this.message = function(str)
	{
		console.log(str);
	}

	this.logData = function()
	{
		console.log("version: "+ this.loaderVersion + ", appID: "+ this.app_id);
	}

	// this.getContextData = function()
	// {
	// 	return self.contextData;
	// }
	
	/*------------------------------------------*/

	/**
	 * Request a callback when Inffuse is fully loaded and ready for work
	 * @method ready
	 * @param {function} onready Called when Inffuse is ready
	 */
	this.ready = function(onready)
	{
		if (typeof onready != 'undefined')
		{
			if (self.isready)
				onready(self);
			else {
				if (typeof self.readyCallbacks == 'undefined')
					self.readyCallbacks = [];
				
				self.readyCallbacks.push(onready);
			}

			return;
		}
		
		if (self.readyCallbacks)
		{
			for (var i in self.readyCallbacks)
				self.readyCallbacks[i](self);
		}
		
		self.broadcast("ready");
		self.isready = true;
	}

	/**
	 * @method viewMode
	 * @returns {string}	Returns the current viewing mode. Valid values - [editor/preview/site]
	 */
	this.viewMode = function() {
		return this._viewMode;
	}

	/** 
	 * @private
	 */
	this.error = function(msg)
	{
		var full_msg = "[Inffuse error] " + msg + '.';
		if (window.console)
			console.error(full_msg);
	}

	/*------------------------------------------*/

	/**
	 * Subscribe to event from Inffuse or to a custom event.
	 * @method on
	 * @param {string} event Event name
	 * @param {function} handler Called when the event is triggered
	 * @param {object} data Event data
	 * @example Inffuse event
	 * 	Inffuse.on("data-changed",function(new_data) 
	 * 	{
	 * 		if (new_data.hasOwnProperty("my_key"))
	 * 		{
	 * 			// Do something
	 * 			return;
	 * 		}
	 * 	});
	 * @example Custom event
	 * 	Inffuse.broadcast("my_custom_event",{weight: 78; height: 180; alias: "Johnny"});
	 *
	 * 	Inffuse.on("my_custom_event",function(user) {
	 * 		console.log("User Alias=%s, User Height=%d", user.alias, user.height)
	 * 	});
	 */
	this.on = function(event,handler)
	{
		if (!handler || typeof handler != "function") {
			throw "[Inffuse] Invalid handler passed (function is required)";
			return;
		}

		if (typeof EVENT_HANDLERS[event] == 'undefined')
			EVENT_HANDLERS[event] = [];

		EVENT_HANDLERS[event].push(handler);
	}

	/**
	 * Unsubscribe from event
	 * @method off
	 * @param {string} event Event name
	 * @param {function} handler Handler passed on Inffuse.on call
	 * @example 
	 * 	Inffuse.on("data-changed",handle_data_change); 
	 * 	function handle_data_change(new_data)
	 * 	{
	 * 		if (new_data.hasOwnProperty("my_key"))
	 * 		{
	 * 			// Do something once
	 *
	 * 			Inffuse.off("data-changed",handle_data_change);
	 * 			return;
	 * 		}
	 * 	}); 
	 */
	this.off = function(event,handler)
	{
		if (typeof EVENT_HANDLERS[event] == 'undefined')
			return;

		EVENT_HANDLERS[event] = EVENT_HANDLERS[event].filter(function(h){
			return h != handler;
		});
	}

	/**
	 * @private
	 */
	this.trigger = function(event,data)
	{
		var handlers = EVENT_HANDLERS[event];
		if (typeof handlers == 'undefined' || handlers.length == 0)
			return false;

		EVENT_HANDLERS[event].map(function(handler){
			handler(data);
		});

		return true;
	}

	/*------------------------------------------*/

	/**
	 * Broadcast an event
	 * @method broadcast
	 * @param {string} event Event type
	 * @param {object} data Event data
	 * @param {object} includeSelf=false Should the event be sent to the current window.
	 * @example  
	 * 	Inffuse.broadcast("my_custom_event",{weight: 78; height: 180; alias: "Johnny"});
	 *
	 * 	Inffuse.on("my_custom_event",function(user){
	 * 		console.log("User Alias=%s, User Height=%d", user.alias, user.height)
	 * 	});
	 */
	this.broadcast = function(event,data,includeSelf)
	{
		if (typeof window == 'undefined' || !window.parent || !window.parent.postMessage)
			return;

		var msg = {
				app: 'inffuse',
				user: self.user ? self.user.key() : null,
				//site: Inffuse.site ? Inffuse.site.key() : null,
				project: self.project ? self.project.key() : null,
				type: event,
				params: data
			}

		window.parent.postMessage(msg, "*");

		if (includeSelf)
			self.trigger(event,data);
	}

	/*------------------------------------------*/
	// undocumented
	this.loadScript = function(script_src,callback)
	{
		if (script_src.indexOf('//') == -1) {
			var base_url = self.server;
			script_src = base_url + script_src;
		}

		var head = document.getElementsByTagName('head')[0];
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = script_src;
		head.appendChild(script);

		script.onload = function(){
			callback();
		};
	}

	/*------------------------------------------*/

	this.loadSite = function(site_id)
	{
		var params = {
			user: self.user.id()
		};

		var deferred = new jQuery.Deferred();
		self.requestAPI('sites/'+site_id,params,'GET')
			.then(
				function(response){
					var site = new self.SiteClass(self,response.site);
					deferred.resolve(site);
				},
				function(err){
					deferred.reject(err);
				});

		return deferred.promise();
	}

	/*------------------------------------------*/

	this.requestAPI = function(action,params,method,withCredentials,sync)
	{
		contextObject.log("[Inffuse.requestAPI]",{params: {action,params,method,withCredentials,sync}});
		// console.log("[Inffuse.requestAPI]",{action,params,method,withCredentials,sync});
		if (typeof method == 'undefined')
			method = 'GET';

		params = params || {};
		params['app'] = params['app'] || this.app_id;
		// params['platform'] = self.platform;

		if (this.demo_mode)
		// return empty resolved deferred object
			return new function(){ this.success = function(callback){ callback(); }}
		
		var access_token = self.user?.accessToken()
		if (access_token) {
			params['access_token'] = access_token;
		}

		var url = action.startsWith("http") ? action : [self.server,'api',self.apiVersion,action].join('/');
		if (method == 'GET' || method == 'DELETE') {
			url += '?' + self.utils.buildQuery(params);
			params = undefined;
		}

		var ajax_params = {
			url: url,
			type: method,
			data: params,
			async: !sync
		};

		if (withCredentials) {
			ajax_params['credentials'] = 'include';
			ajax_params['mode'] = 'cors';
		}

		return self.utils.http(ajax_params);
	}

	/*------------------------------------------*/
	
	this.openWebpage = async function(uri) {
		if (Platform.OS === 'web') {
			await Linking.openURL(uri);

		} else {
			this.navigator.navigate('WebView',{uri: uri});
		}
	}

	/*------------------------------------------*/
	
	this.receiveMessage = function(message) 
	{
		switch (message.type)
		{
			case 'data-changed':
				if (self.project) {
					var project_data = message.params;
					for (key in project_data)
						self.project.set(key,project_data[key],true);
				}
				
				break;
		}

		self.trigger(message.type,message.params);
	}

	if (typeof window != 'undefined') // we're in browser 
	{
		// window.addEventListener("message", function(event){
		// 	var message = event.data;
		// 	if (message.app != 'inffuse')
		// 		return;
			
		// 	self.receiveMessage(message);
	
		// }, false);
	}
}




//	InffuseSDK.prototype.Services = InffuseServices;
if (typeof Inffuse != 'undefined' && Inffuse.registerVersion)
	Inffuse.registerVersion("0.1",InffuseSDK_01);

export default InffuseSDK_01;