// import '/web/index.scss';

import React, { useContext } from 'react';
import { Text, View, TouchableOpacity } from "react-native";
import { useNavigation } from '@react-navigation/native';
import Button from '../utils/button';

import UserDataContext from '../admin-kit-local/tools/context';
import Avatar from '../admin-kit-local/components/avatar';

/*---------------------------------------------*/

const RouterLink = Text;

/*---------------------------------------------*/

function UserCard(props) {
	const context = useContext(UserDataContext);
	const navigation = useNavigation();
	const user = (({id,email,name}) => ({id,email,name}))(props);

	const followButton = props.followButton && (user.id !== context.inffuse.user.id());
	const unfollowButton = props.unfollowButton && (user.id !== context.inffuse.user.id());

	if (!user.name) {
		return <UserCardSkeleton index={props.index} />;
	}

	return (
		<View className="user card" style={{...props.style, flexDirection: 'row', alignItems: 'center', marginBottom: 8}} key={user.id}>
			<TouchableOpacity onPress={onOpenUser}>
				<Avatar style={{marginRight: 8}} id={user.id} name={user.name} />
			</TouchableOpacity>
			<TouchableOpacity onPress={onOpenUser} style={{flexGrow: 1}}>
				<Text variant='Text5'><RouterLink to={`/users/${user.id}`}>{user.name}</RouterLink></Text>
			</TouchableOpacity>
			{followButton ? <Button variant='outline' onPress={onFollow}>Follow</Button> : null}
			{unfollowButton ? <Button variant='outline' onPress={onUnfollow}>Unfollow</Button> : null}
		</View>
	)

	/*------------------------------------------*/

	function onOpenUser() {
		navigation.navigate('User',{userId: user.id})
	}

	/*------------------------------------------*/

	function onFollow() {
		return context.followUser(user.id).then(() => {props.onChange && props.onChange()});
	}

	/*------------------------------------------*/

	function onUnfollow() {
		return context.unfollowUser(user.id).then(() => {props.onChange && props.onChange()});
	}
}

/*---------------------------------------------*/

function UserCardSkeleton(props) {
	const index = props.index || 0;
	return (
		<View className="user card" key={props.id} style={{opacity: 1-index*0.3}}>
			<Avatar className="skeleton" />
			<View className="skeleton" style={{width: 100, height: 24, backgroundColor: 'red !important'}}></View>
		</View>
	);
}

/*---------------------------------------------*/

export default UserCard;
