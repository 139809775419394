import React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { FlatList,RefreshControl,Text,View } from 'react-native';
import { useNavigation, useScrollToTop } from '@react-navigation/native';

import UserDataContext from '../admin-kit-local/tools/context';
import withLogin from '../admin-kit-local/tools/withLogin';
import { Post, PostSkeleton } from './post';
import { GlobalStyles, GlobalColors } from '../styles';
import Image from '../utils/image';
import DemoContent from './demoContent';

function FeedView(props) {
	const context = useContext(UserDataContext);
	const scrollViewRef = useRef(null);
	const [refreshing, setRefreshing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [posts,setPosts] = useState(null);
	const endOfContent = useRef(false);
	const navigation = useNavigation();

	useScrollToTop(scrollViewRef);

	useEffect(() => {
		context.inffuse.ready(() => {
			setPosts(posts);
		});

		refresh();

		context.observe(refresh,['FOLLOWERS_CHANGED','POST_CHANGED']);
		return () => context.unobserve(refresh);
		
	},[context.inffuse.user.id()]);

	useEffect(() => {
		const unsubscribe = navigation.addListener('focus', () => {
			const currentTime = new Date().toISOString().slice(0,19).replace('T',' ');
			context.inffuse.services.mysql.run('updateUserAccount',{last_read: currentTime});
		});
  
		return unsubscribe;
	}, [navigation]);


	const data = [];
	if (posts?.length) {
		// const skeletons = new Array(3).fill(null).map((_x,index) => ({type: 'skeleton', index}));
		// data.push(...skeletons);
		
		data.push(...(posts?.map(post => ({type: 'post', post})) || []));
	} 
	
	if (loading && !posts) {
		const skeletons = new Array(3).fill(null).map((_x,index) => ({type: 'skeleton', index}));
		data.push(...skeletons);
		
	} else if (!endOfContent.current) {
		data.push({type: 'footer', content: 'Loading more...'});
	}

	if (posts?.length === 0) {
		return (
			<View style={{flex: 1, alignItems: 'center', padding: 16,}}>
				<Image src={require('../../img/welcome.png')} style={STYLE.welcome.image} resizeMode={'contain'} />
				<Text style={{...GlobalStyles.heading, fontSize: 24, marginBottom: 16}}>Welcome to Ollbeat!</Text>
				<Text style={{textAlign: 'center', maxWidth: '80%', fontSize: 16, lineHeight: 22}}>
					Start <Text style={{color: 'blue'}} onPress={() => navigation.navigate('Friends')}>following some people</Text> to
					see, listen, 
				</Text>
				<Text style={{textAlign: 'center', maxWidth: '80%', fontSize: 16, lineHeight: 22}}>
					like and comment on songs they like.
				</Text>
			</View>
		)
	}

	return (
		<FlatList 
			data={data}
			renderItem={renderItem}
			keyExtractor={(item,index) => index}
			keyboardShouldPersistTaps={'handled'} 
			onEndReached={onEndReached}
			contentContainerStyle={{width: '100%', minHeight: '100%', paddingTop: 8}}
			refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => refresh(true)} />}
			ref={scrollViewRef}
		/>
	)

	/*------------------------------------------*/

	function renderItem({index,item}) { 
		switch (item.type) {
			case 'post':
				return <Post onContentChanged={refresh} post={item.post} key={index} />;
			
			case 'skeleton':
				return <PostSkeleton index={item.index} key={index} />;
			
			case 'footer':
				return <Text style={STYLE.footer}>{item.content}</Text>;
		}
	
		alert("Can't render item of type " + item.type);
	}

	/*------------------------------------------*/

	function onEndReached() {
		context.log("[FeedView.onEndReached]");

		if (endOfContent.current || !posts) {
			return;
		}

		loadPosts(posts.length)
			.then(loadedPosts => {
				setPosts([...posts,...loadedPosts]);
			});
	}

	/*------------------------------------------*/

	function loadPosts(offset,count) {
		if (context.DemoContent) {
			return Promise.resolve(DemoContent.posts.map((post,iPost) => {
				const postCreated = 1*new Date() - 0.6*60*60*(iPost+1)*1000;
				const comments = DemoContent.comments.slice(DemoContent.comments.length*Math.random()).map((text,iComment) =>  ({
					id: context.generateId("comment"),
					created: postCreated - 0.6*60*60*iComment*1000,
					text: text,
					...DemoContent.users[DemoContent.users.length-1-iComment],
				}));

				return {
					...post,
					...DemoContent.users[iPost],
					id: context.generateId("post"),
					key_name: context.generateId("postkey"),
					created: postCreated,
					likes: DemoContent.users.sort(() => 0.5-Math.random()).slice(10*Math.random()),
					comments: comments,
					comments_count: comments.length,
				}
			}));
		}

		setLoading(true);
		return context.inffuse.services.mysql.run('getFeed',{user: context.inffuse.user.id(), offset, count})
			.then((response) => {
				const loadedPosts = response.result;
				loadedPosts.forEach(post => {
					if (post.likes) {
						post['likes'] = JSON.parse(`[${post.likes}]`);
					}
				});

				setLoading(false);
				if (loadedPosts.length === 0) {
					endOfContent.current = true;
				}

				return loadedPosts;
			});
	}

	/*------------------------------------------*/
	
	function refresh(refreshIndication) {
		refreshIndication && setRefreshing(true);
		endOfContent.current = false;

		return loadPosts()
			.then(loadedPosts => {
				setPosts(context.emptyState ? [] : loadedPosts);
				refreshIndication && setRefreshing(false);
			});
	}
}

/*---------------------------------------------*/

const STYLE = {
	welcome: {
		image: {
			width: '100%', 
			maxHeight: '25%', 
			marginTop: '15%', 
			marginBottom: '10%',
		},
	},
	footer: { 
		textAlign: 'center',
		color: GlobalColors['neutral-600'],
		paddingBottom: 32,
		paddingTop: 16,
	}
}

/*---------------------------------------------*/

export default withLogin(FeedView);
