// import '/web/index.scss';

import React from 'react';
import { useContext, useEffect, useMemo, useState, memo } from 'react';
import { Alert, Linking, Modal, Pressable, Share, TouchableOpacity, Text, View } from 'react-native';
import Icon from '../utils/icon';
import { useNavigation } from '@react-navigation/native';
import { GlobalColors, GlobalStyles } from '../styles';
import Image from '../utils/image';

import UserDataContext from '../admin-kit-local/tools/context';
import Avatar from '../admin-kit-local/components/avatar';
import { generateId } from '../admin-kit-local/tools/utils.js';

import TimeAgo from '../utils/timeAgo';
import SkeletonBar from './skeletonBar';

import CommentsSection from './commentsSection';
import ContextMenu from './contextMenu';

/*---------------------------------------------*/

function Link(props) {
	return (
		<TouchableOpacity size='xsmall' onPress={props.onPress} style={props.style}>
			{props.children}
		</TouchableOpacity>
	)
}

/*---------------------------------------------*/

function PostWithPlayingState(props) {
	const navigation = useNavigation();
	const [commentsVisible,setCommentsVisible] = useState(false);
	const [commentsCount,setCommentsCount] = useState(null);
	const [postLocal,setPostLocal] = useState(null);
	const post = (postLocal?._data_timestamp || 0) > (props.post?._data_timestamp || 0) ? postLocal : props.post;

	const context = useContext(UserDataContext);
	const isLiked = !!post.likes?.find(like => like.user_id === context.inffuse.user.id());
	const permalink = `https://web.ollbeat.com/posts/${post.id}`;
	// const permalink = ExpoLinking.createURL(`/posts/${post.id}`);

	const likeStyle = isLiked ? STYLE.actionLiked : STYLE.action;

	return (
		<View key={post.id} style={STYLE.container}>
			<View style={STYLE.header.container}>
				<TouchableOpacity onPress={() => navigation.navigate('User',{userId: post.user_id})}>
					<Avatar id={post.user_id} name={post.user_name} image={post.user_image} style={STYLE.avatar} />
				</TouchableOpacity>
				<View style={STYLE.header.details}>
					<Link onPress={() => navigation.navigate('User',{userId: post.user_id})} href={`/users/${post.user_id}`}>
						<Text style={STYLE.username}>{post.user_name}</Text>
					</Link>
					<Link onPress={() => navigation.navigate('Post',{postId: post.id})}>
						<Text style={STYLE.created} size='xsmall'>
							<TimeAgo time={post.created} />
						</Text>
					</Link>
				</View>
				<ContextMenu target={<Icon type='more' size={24} />} style={STYLE.header.icon}>
					{/* <ContextMenu.Item icon='link' label='Copy link' /> */}
					<ContextMenu.Item icon='report' label='Report user' onPress={onReportUser} />
					<ContextMenu.Item icon='reportContent' label='Report post' onPress={onReportPost} />
					<ContextMenu.Item icon='block' label='Block user' onPress={onBlockUser} />
				</ContextMenu>
			</View>
			{/* <Text selectable={true} onPress={() => openLink(permalink)}>Permalink ({permalink})</Text> */}
			<View style={STYLE.song.container} >
				<View style={STYLE.song.thumb}>
					<Image src={post.song_thumb} style={{width: '100%', height: '100%'}} alt='song thumbnail' />
				</View>
				<View style={STYLE.song.detailsPane}>
					<View style={STYLE.song.details} >
						<View style={STYLE.song.name}>
							<Text style={STYLE.song.nameText}>{post.song_name}</Text>
						</View>
						<Text style={STYLE.song.artist}>{post.song_artist}</Text>					
					</View>
					<View style={STYLE.song.actions}>
						<TouchableOpacity onPress={props.isPlaying ? onPause : onPlay} style={STYLE.song.action}>
							{/* <Image className='play' src={require('../../img/play--filled.png')} color='#000' style={{width: 16, height: 16}} />&nbsp; */}
							<Icon type={props.isPlaying ? 'pause' : 'play'} size={18} style={STYLE.song.icon} />
							<Text style={STYLE.song.action}>{props.isPlaying ? 'Pause' : 'Play'}</Text>
						</TouchableOpacity>
						<Text>&nbsp;&nbsp;</Text>
						<TouchableOpacity onPress={() => openLink(`spotify:track:${post.key_name}`)} style={STYLE.song.action}>
							<Image src={require('../../img/spotify16.png')} style={{...STYLE.song.icon, width: 16, height: 16}} />
							<Text style={STYLE.song.action}>Open in Spotify</Text>
						</TouchableOpacity>
					</View>
				</View>
			</View>

			<StatusLine style={STYLE.statusLine} post={post} commentsCount={commentsCount ?? post.comments_count} onCommentsClicked={() => setCommentsVisible(!commentsVisible)} />

			<View style={STYLE.actions}>
				<Link onPress={isLiked ? onUnlike : onLike}>
					<Text style={likeStyle}>Like</Text>
				</Link>
				<Text style={STYLE.action}> ·&nbsp;</Text>
				<Link onPress={() => setCommentsVisible(!commentsVisible)}><Text style={STYLE.action}>Comment</Text></Link>
				<Text style={STYLE.action}> ·&nbsp;</Text>
				<Link onPress={onShare}><Text style={STYLE.action}>Share</Text></Link>
			</View>
			<CommentsSection 
				style={STYLE.commentsSection} 
				post={post} 
				highlightCommentId={props.highlightCommentId} 
				open={props.openComments || commentsVisible} 
				onChange={comments => setCommentsCount(comments.length)} 
			/>
		</View>
	)

	/*------------------------------------------*/

	function onLike() {
		setPostLocal({
			...post,
			_data_timestamp: Math.floor(1 * new Date() / 1000),
			likes: [
				...(post?.likes || []),
				{
					user_id: context.inffuse.user.id(),
					user_name: context.inffuse.user.name(),
				}
			]
		});

		return context.inffuse.services.mysql.run('like',{
			id: generateId("like"),
			user_id: context.inffuse.user.id(),
			entity_id: post.id,
			type: "like",
		})
		.then((response) => {
			context.notify(post.user_id,'NOTIF_POST_LIKE',{
				postId: post.id,
				userId: context.inffuse.user.id(),
				userName: context.inffuse.user.name(),
				songName: post.song_name,
				songArtist: post.song_artist,
			});

			context.track("Post Liked",{post_id: post.id, author_id: post.user_id});
			context.broadcast('POST_CHANGED',{post_id: post.id});
			return Promise.resolve();
		})
		.catch(error => {
			context.track("Like Failed",{post_id: post.id, author_id: post.user_id, error: error.message});
		})
		.finally(() => props.onContentChanged());
	}

	function onUnlike() {
		setPostLocal({
			...post,
			_data_timestamp: Math.floor(1 * new Date() / 1000),
			likes: post.likes.filter(user => user.user_id !== context.inffuse.user.id())
		});

		return context.inffuse.services.mysql.run('unlike',{
			entity_id: post.id,
		})
		.then((response) => {
			props.onContentChanged();
			context.track("Post Unliked",{post_id: post.id, author_id: post.user_id});
			context.broadcast('POST_CHANGED',{post_id: post.id});
		});
	}

	function onPlay() {
		context.play(post);
		context.track("Post Played",{post_id: post.id, author_id: post.user_id});
	}

	function onPause() {
		context.pause();
		context.track("Post Paused",{post_id: post.id, author_id: post.user_id});
	}
	
	async function onShare() {
		context.log("[Post.onShare]");
		try {
			const result = await Share.share({
				message: permalink,
			});

			if (result.action === Share.sharedAction) {
				if (result.activityType) {
				// shared with activity type of result.activityType
				} else {
				// shared
				}
			} else if (result.action === Share.dismissedAction) {
				// dismissed
			}
		} catch (error) {
			alert(error.message);
		}
		
		context.track("Post Share",{post_id: post.id, author_id: post.user_id});
	}
	
	/*---------------------------------------------*/

	async function openLink(url) {
		context.log("[Post.openLink]",url);
		// fails for some reason
		// const supported = await Linking.canOpenURL(url);
		const supported = true;

		if (supported) {
			// Opening the link with some app, if the URL scheme is "http" the web link should be opened
			// by some browser in the mobile
			await Linking.openURL(url);
		} else {
			Alert.alert(`Don't know how to open this URL: ${url}`);
		}
	};

	/*---------------------------------------------*/

	function onBlockUser() {
		navigation.navigate('BlockUser',{
			userId: post.user_id, 
			userName: post.user_name,
		})
	}

	function onReportUser() {
		navigation.navigate('Report',{
			entityId: post.user_id,
			entityType: 'user', 
			entityName: post.user_name,
		})
	}

	function onReportPost() {
		navigation.navigate('Report',{
			entityId: post.id,
			entityType: 'post', 
			entityName: `${post.song_name} by ${post.song_artist}`,
		})
	}
}

/*---------------------------------------------*/

function StatusLine(props) {
	const post = props.post;
	const navigation = useNavigation();

	let likesInfo = null;
	if (post?.likes?.length) {
		if (post?.likes?.length === 1) {
			likesInfo = `${post.likes[0].user_name} likes this`;
		// } else if (post.likes?.length === 2) {
		// 	likesInfo = `${post.likes[0].user_name} and ${post.likes[1].user_name} like this`;
		} else {
			likesInfo = `${post.likes[0].user_name} and ${post.likes.length - 1} more like this`;
		}
	}
	
	const likesStatus = (
		<TouchableOpacity style={{flexDirection: 'row', alignItems: 'center', flexGrow: 1}} onPress={() => navigation.navigate('Likes',{entityId: post.id})}>
			{ likesInfo 
				? (
					<>
						<Icon type='like' size={13} color='purple' style={{marginRight: 6, marginTop: 0}} />
						<Text style={STYLE.statusText}>{likesInfo}</Text>
					</>
				)
				: null
			}
		</TouchableOpacity>
	);
	
	const commentsStatus = props.commentsCount
		? (
			<View style={{flexDirection: 'row', alignItems: 'center'}}>
				<Icon type='comments' size={13} style={{marginRight: 6, marginTop: 2}} color={GlobalColors['neutral-800']} />
				<Link onPress={props.onCommentsClicked}>
					<Text style={STYLE.statusText}>{props.commentsCount} comment(s)</Text>
				</Link>
			</View>
		)
		: null;
	
	if (!likesStatus && !commentsStatus) {
		return null;
	}

	return [
		<View style={{...props.style, flexDirection: 'row', paddingBottom: 8, borderBottomWidth: 1, borderColor: GlobalStyles.hr.borderColor}} key='status'>
			{likesStatus || <View />}
			{commentsStatus}
		</View>
	]
}

/*---------------------------------------------*/

function PostSkeleton(props) {
	return (
		<View style={{...STYLE.container, opacity: 1-props.index*0.3}}>
			<View style={STYLE.header.container} >
				<SkeletonBar style={{...STYLE.avatar, width: 32, height: 32, borderRadius: 100}} />
				<View>
					<SkeletonBar style={{width: 150, height: 14, marginBottom: 6,}} />
					<SkeletonBar style={{...STYLE.created, width: 70}} />
				</View>
			</View>
			<View style={STYLE.song.container}>
				<View>
					<SkeletonBar style={{...STYLE.song.thumb, marginBottom: 0,}} />
				</View>
				<View>
					<View style={STYLE.song.details}>
						<SkeletonBar style={{width: 150}} />
						<SkeletonBar style={{...STYLE.song.artist, width: 120}} />					
					</View>
					<View>
						<SkeletonBar style={{marginBottom: 0,}} />
					</View>
				</View>
			</View>
		</View>
	);
}

/*---------------------------------------------*/

const MemoizedPost = memo(PostWithPlayingState);

function Post(props) {
	const [_update,forceUpdate] = useState(0);
	const post = props.post;
	const context = useContext(UserDataContext);
	const isSelectedInPlayer = context.currentlyPlayedPost?.id === post.id;
	const isPlaying = context.isPlaying();

	const eventHandler = useMemo(() => {
		return (event,data) => {
			forceUpdate(u => u+1);
		}
	});

	useEffect(() => {
		context.observe(eventHandler,['PLAYER_STATE_CHANGE']);

		return () => context.unobserve(eventHandler);
	},[]);

	const newProps = {
		...props,
		isSelectedInPlayer: isSelectedInPlayer,
		isPlaying: isSelectedInPlayer ? isPlaying : undefined,
	}

	return (
		<MemoizedPost 
			{...newProps}
		/>
	)
}

/*---------------------------------------------*/

const STYLE = {
	container: {
		padding: 16,
		marginBottom: 16,
		backgroundColor: 'white',
		zIndex: -1,
	},
	avatar: {
		marginRight: 8,
	},
	header: {
		container: {
			flexDirection: "row",
			marginBottom: 8,
			alignItems: 'flex-start',
		},
		details: {
			flexGrow: 1,
		},
		icon: {
			marginRight: -4,
			marginTop: -6,
			padding: 4,
		}
	},
	username: {
		fontFamily: GlobalStyles.fonts.primary,
		fontSize: 15,
	},
	created: {
		opacity: 0.6,
		fontSize: 12,
		fontFamily: GlobalStyles.fonts.body,
	},
	song: {
		container: {
			flexDirection: "row", 
			backgroundColor: '#f2ebe5',
			padding: 8,
		},
		thumb: {
			width: 100,
			height: 100,
			marginRight: 12,
		},
		detailsPane: {
			flexGrow: 1,
			flexShrink: 1, // prevents this box exceeding the width of the container in case of a long song name
		},
		details: {
			flexGrow: 1,
		},
		name: {
			flexDirection: 'row',
			marginBottom: 2,
		},
		nameText: {
			flexWrap: 'wrap',
			flex: 1,
			fontSize: 14,
			lineHeight: 18,
		},
		artist: {
			flexShrink: 1,
			fontSize: 12,
			opacity: 0.7,
		},
		actions: {
			flexDirection: 'row',
		},
		action: {
			flexDirection: "row",
			alignItems: 'center',
			fontSize: 13,
		},
		icon: {
			marginRight: 4,
		},
	},
	statusLine: {
		marginTop: 8,
	},
	actions: {
		flexDirection: 'row',
		marginTop: 8,
	},
	action: {
		color: GlobalColors['neutral-800'],
		fontWeight: 'normal',
		fontFamily: GlobalStyles.fonts.primary,
		fontSize: 14,
	},
	actionLiked: {
		color: 'purple',
		fontFamily: GlobalStyles.fonts.primaryBold,
		fontSize: 14,
	},
	actionInProgress: {
		color: GlobalColors['neutral-400']
	},
	statusText: {
		fontSize: 12,
		fontFamily: GlobalStyles.fonts.primary,
		color: GlobalColors['neutral-800'],
	},
	commentsSection: {
		marginTop: 8,
	},
	menu: {
		icon: {

		}
	}
}

/*---------------------------------------------*/

export {
	Post,
	PostSkeleton
}
