import React,{ useContext,useCallback,useEffect,useMemo,useRef,useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
// import Icon from 'react-native-ico-material-design';
import Icon from './utils/icon';

import UserDataContext from './admin-kit-local/tools/context';
import AppContextMgr from './admin-kit-local/tools/contextmgr';

import { Platform, SafeAreaView, ScrollView, StatusBar, Text, View } from 'react-native';
import * as Linking from 'expo-linking';
import * as Notifications from 'expo-notifications';
import { useFonts as usePrimaryFonts, Lora_500Medium, Lora_700Bold } from '@expo-google-fonts/lora';
import { useFonts as useBodyFonts, Roboto_400Regular } from '@expo-google-fonts/roboto';

import FeedView from './pages/feed';
import FriendsPage from './pages/friends/index';
import UserPage from './pages/users/index';
import AccountPage from './pages/account/index';
import PostView from './pages/posts/index';
import LikesView from './pages/posts/likes';
import BlockUserPage from './pages/users/block';
import ReportPage from './pages/report';
import NotificationsView from './pages/notifications/index';
import ConnectPage from './admin-kit-local/templates/user/connect';
import LoginPage from './admin-kit-local/templates/user/login';
import ForgotPasswordPage from './admin-kit-local/templates/user/forgotpassword';
import ResetPasswordPage from './admin-kit-local/templates/user/resetpassword';
import WebviewPage from './pages/webview';

import Player from './player/player';
import { GlobalStyles,GlobalColors } from './styles';

import { handleNotification } from './notificationsContent';
import UnreadNotificationsCount from './unreadNotificationsCount';

import LoadingSpinner from './utils/spinner';

/*---------------------------------------------*/

const Stack = createNativeStackNavigator();
const Tab = createMaterialTopTabNavigator();

const DEFAULT_LOGIN_SCREEN = 'Login';

/*---------------------------------------------*/

function getContentView(Component,props) {
	return (
		<ScrollView keyboardShouldPersistTaps={'handled'} contentContainerStyle={{width: '100%', minHeight: '100%', padding: 16, paddingBottom: 0}}>
			<Component {...props} />
		</ScrollView>
	)
}

/*---------------------------------------------*/

function ConnectedPage(props) {
	const context = useContext(UserDataContext);
	const navigation = useNavigation();
	const result = JSON.parse(props.route.params.result);

	useEffect(() => {
		if (!result.errorMsg) {
			context.log("[ConnectedPage] calling onSpotifyConnected");
			context.onSpotifyConnected(result).then(() => {
				context.log("[ConnectedPage] navigating to Content");
				navigation.reset({
					index: 0,
					routes: [{ name: 'Content' }],
				});
			});
		}
	},[]);

	if (result.errorMsg) {
		return (
			<View>
				<Text style={{textAlign: 'center'}}>{`Couldn't connect to Spotify.`}</Text>
				<Text style={{textAlign: 'center'}}>{`Reason - ${result.errorMsg}`}</Text>
			</View>
		);
	}
	
	return (
		<View style={{flex: 1, backgroundColor: '#fff', alignItems: 'center', justifyContent: 'center',}}>
			<LoadingSpinner color='#351C75' duration={600} size={70} width={6} />
		</View>
	)
}

/*---------------------------------------------*/

const linking = {
	prefixes: ['ollbeat-app://','https://web.ollbeat.com/',Linking.createURL('/')],
	config: {
		screens: {
			Connect: 'user/connect',
			Login: 'user/login',
			WebviewPage: 'webpage',
			ForgotPasswordPage: 'user/forgotpassword',
			ResetPasswordPage: 'user/resetpassword',
			Content: {
				screens: {
					Post: 'posts/:postId',
					User: 'users/:userId',
					Likes: 'likes/:entityId',
					BlockUser: 'block/:userId',
					Report: 'block/:entityId',
				}
			},
			ContentTabs: 'my',
			Feed: 'home',
			Friends: 'friends',
			Notifications: 'notifications',
			Account: 'account',
			// Connected: Linking.createURL('connected'),
			Connected: 'user/connected',
		},
	},
};

/*---------------------------------------------*/

const TABS_ICONS = {
	Feed: {
		type: 'home',
		size: 24,
		style: {
			paddingTop: 1,
		}
	},
	Friends: {
		type: 'friends',
		size: 22,
		style: {
			paddingTop: 1,
		}
	},
	Account: {
		type: 'account',
		size: 24,
		style: {
			paddingTop: 0,
		},
	},
	Notifications: {
		type: 'notifications',
		size: 22,
		style: {
			paddingTop: 1,
		},
	},
}

function ContentTabs(props) {
	return (
		<Tab.Navigator initialRouteName="Feed" screenOptions={({ route }) => (
			{
				tabBarLabel: () => {return null},
				tabBarStyle: { height: 48, paddingTop: 4 },
				tabBarIcon: ({ focused, color, size }) => {
					const icon = TABS_ICONS[route.name];
					const badge = (
						<View style={{position: 'absolute', top: -3, left: 8}}>
							<UnreadNotificationsCount style={GlobalStyles.badge} /> 
						</View>
					)
					return (
						<View style={icon.style}>
							<Icon type={icon.type} size={icon.size} color={focused ? GlobalColors['primary-600'] : 'gray'} />
							{ icon.type === 'notifications' ? badge: null }
						</View>
					)
				},
				tabBarActiveTintColor: GlobalColors['primary-600'],
				tabBarInactiveTintColor: 'gray',
			})}
		>
			<Tab.Screen name="Feed" options={{title:null}} component={FeedView} />
			{/* <Tab.Screen name="Feed">{function FeedNavigated(props) { return getContentView(FeedView,props)}}</Tab.Screen> */}
			<Tab.Screen name="Friends" component={FriendsPage} />
			<Tab.Screen name="Notifications" component={NotificationsView} />
			<Tab.Screen name="Account">{function AccountNavigated(props) { return getContentView(AccountPage,props)}}</Tab.Screen>
		</Tab.Navigator>
	)
}

function ContentNavigation(props) {
	return (
		<View style={{width: '100%', height: '100%'}}>
			<Stack.Navigator initialRouteName="ContentTabs" screenOptions={{headerShown: true, headerBackTitleVisible: false}}>
				<Stack.Screen name="ContentTabs" component={ContentTabs} options={{headerTitle: props => null, headerShown: false}} />
				<Stack.Screen name="User" component={UserPage} options={{headerTitle: props => <Text />}} />
				<Stack.Screen name="Post" component={PostView} options={{headerTitle: props => <Text />}} />
				<Stack.Screen name="Likes" component={LikesView} options={{headerTitle: props => <Text>People who like this</Text>}} />
				<Stack.Screen name="BlockUser" component={BlockUserPage} options={{headerTitle: props => <Text>Block user</Text>}} />
				<Stack.Screen name="Report" component={ReportPage} options={{headerTitle: props => <Text>Report</Text>}} />
			</Stack.Navigator>
			<Player />
		</View>
	)
}

function Router(props) {
	const context = useContext(UserDataContext);
	const navigation = useNavigation();
	const notificationListener = useRef();
	const notificationResponseListener = useRef();

	context.navigator = navigation;
	
	useEffect(() => {
		notificationResponseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
			const notificationObject = response.notification;
			const notificationData = notificationObject.request.content.data;
			const notification = {
				type: notificationData.type,
				id: notificationData.id,
				data: notificationData,
			}

			handleNotification(context,navigation,notification);

			context.track("Notification opened",{
				type: notification.type,
				data: notification.data
			});

			context.broadcast('NOTIFICATION_RECEIVED');
		});

		notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
			context.log("[App] notification received",{notification});
			context.track("Notification received",{
				type: notification.type,
				data: notification.data
			});

			context.broadcast('NOTIFICATION_RECEIVED');
		});

		return () => {
			Notifications.removeNotificationSubscription(notificationResponseListener.current);
		};
	},[]);

	const onLoginChange = useMemo(() => {
		return () => {
			if (!context.loggedIn()) {
				navigation.reset({
					index: 0,
					routes: [{name: DEFAULT_LOGIN_SCREEN}],
				});
			}
		}
	});

	useEffect(() => {
		context.observe(onLoginChange,['LOGIN_CHANGE']);

		return () => context.unobserve(onLoginChange);
	},[context]);

	// useEffect(() => {
	// 	Linking.getInitialURL().then((url) => {
	// 		if (url) {
	// 			handleOpenURL(url)
	// 		}
	// 	}).catch(err => {});
		
	// 	Linking.addEventListener('url', handleOpenURL);
	// 	return () => Linking.removeEventListener('url', handleOpenURL);
	// },[]);

	context.log("[Router]",{loggedIn: context.loggedIn()});
	return (
		<Stack.Navigator 
			initialRouteName={context.loggedIn() ? 'Content' : DEFAULT_LOGIN_SCREEN} 
			screenOptions={{
				headerShown: false,
				contentStyle:{
					backgroundColor: GlobalStyles.app.background
				}
			}} 
			screenListeners={{state: (e) => {}}}
		>
			<Stack.Screen name="Content" component={ContentNavigation} options={{ title: '' }} />
			<Stack.Screen name="Connect">{function ConnectNavigated(props) { return getContentView(ConnectPage,props)}}</Stack.Screen>
			<Stack.Screen name="Login">{function LoginNavigated(props) { return getContentView(LoginPage,props)}}</Stack.Screen>
			<Stack.Screen name="ResetPasswordPage">{function ResetPasswordNavigated(props) { return getContentView(ResetPasswordPage,props)}}</Stack.Screen>
			<Stack.Screen name="ForgotPasswordPage">{function ForgotPasswordNavigated(props) { return getContentView(ForgotPasswordPage,props)}}</Stack.Screen>
			<Stack.Screen name="Connected">{function ConnectedNavigated(props) { return <ConnectedPage {...props} /> }}</Stack.Screen>
			<Stack.Screen name="WebView">{function WebViewNavigated(props) { return getContentView(WebviewPage,props)}}</Stack.Screen>
		</Stack.Navigator>
	)

	/*------------------------------------------*/

	function handleOpenURL(url) {
		if (Platform.OS !== 'web') {
			const parsedUrl = Linking.parse(url.url);

			context.log("[Router.handleOpenURL]",{parsedUrl});
			if (parsedUrl.path === 'connected') {
				navigation.navigate('Connected',parsedUrl.queryParams);
			}
		}
	}
}

/*---------------------------------------------*/

function WebApp(props) {
	const context = useMemo(() => new AppContextMgr(),[]);
	const [_update,forceUpdate] = useState(0);
	const [_primaryFontsLoaded] = usePrimaryFonts({Lora_500Medium, Lora_700Bold});
	const [_bodyFontsLoaded] = useBodyFonts({Roboto_400Regular});

	useEffect(() => {
		context.init('ollbeat',() => {
			context.log("[WebApp] inffuse inited");
			forceUpdate(_update+1);
		});
	},[context]);
	
	context.log("[WebApp] ready = ",{ready: context.ready(), loggedIn: context.loggedIn()});
	if (!context.ready()) {
		// return <Text key='loading'>Loading Ollbeat...</Text>;
		return <LoadingSpinner color='#351C75' duration={600} size={70} width={6} />
	}

	const paddingTop = Platform.select({android: StatusBar.currentHeight, default: 0});
	// const paddingTop = 0;
	return (
		<UserDataContext.Provider value={context}>
			<SafeAreaView style={{flex: 1, width: '100%', height: '100%', paddingTop: paddingTop,}}>
				<StatusBar translucent={true} backgroundColor="transparent" barStyle='dark-content' />
				<NavigationContainer linking={linking}>
					<Router />
				</NavigationContainer>
			</SafeAreaView>
		</UserDataContext.Provider>
	)

	/*------------------------------------------*/
}

export default WebApp;
