import React from 'react';
import { RefreshControl,ScrollView } from 'react-native';

import UserDataContext from '../../admin-kit-local/tools/context';
import withLogin from '../../admin-kit-local/tools/withLogin';
import { Post, PostSkeleton } from '../post';

import { useContext, useEffect, useState } from 'react';

/*---------------------------------------------*/

function PostView(props) {
	const context = useContext(UserDataContext);
	const postId = props.route.params.postId;
	const commentId = props.route.params.commentId;
	const [refreshing, setRefreshing] = useState(false);
	
	const [post,setPost] = useState(null);
	
	useEffect(() => {
		refresh();
	},[postId]);

	const content = post 
		? <Post post={post} onContentChanged={refresh} openComments={true} highlightCommentId={commentId} />
		: <PostSkeleton index={1} />;

	return (
		<ScrollView 
			keyboardShouldPersistTaps={'handled'} 
			contentContainerStyle={{width: '100%', minHeight: '100%', paddingTop: 16}}
			refreshControl={<RefreshControl refreshing={refreshing} onRefresh={() => refresh(true)} />}
		>
			{content}
		</ScrollView>
	)

	/*------------------------------------------*/

	function refresh(refreshIndication) {
		refreshIndication && setRefreshing(true);

		context.inffuse.services.mysql.run('getPost',{post_id: postId})
			.then((response) => {
				if (response.result.length) {
					const post = response.result[0];
					if (post.likes) {
						post['likes'] = JSON.parse(`[${post.likes}]`);
					}

					setPost(post);
					refreshIndication && setRefreshing(false);
				}
			});
	}
}

/*---------------------------------------------*/

export default withLogin(PostView);
