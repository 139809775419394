// import '/web/index.scss';

import React from 'react';
import { Alert, Modal, Pressable, Share, Text, View } from 'react-native';
import Icon from '../utils/icon';
import { OverflowMenu } from '@inlightlabs/design-system';

/*---------------------------------------------*/

// two modals are used to aesthetic reasons - the overlay is animated with fade in, and the content with a slide
function ContextMenu(props) {
	return (
		<OverflowMenu target={props.target} style={{verticalAlign: 'middle'}} menuAlign='right'>
			{props.children}
		</OverflowMenu>
	)
}

ContextMenu.Item = function(props) {
	return (
		<OverflowMenu.Item onClick={props.onPress}>
			<Icon style={STYLE.item.icon} type={props.icon} size={16} />
			<label style={STYLE.item.label}>{props.label}</label>
		</OverflowMenu.Item>
	)
}

/*---------------------------------------------*/

const STYLE = {
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.3)',
		height: '100%',
	},
	container: {
		height: '100%',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	dialog: {
		backgroundColor: 'white',
		padding: 24,
	},
	item: {
		container: {
			paddingTop: 12,
			paddingBottom: 12,
			flexDirection: 'row',
			alignItems: 'center',
		},
		icon: {
			marginRight: 12,
			verticalAlign: 'middle',
		},
		label: {
			fontSize: 14,
			verticalAlign: 'middle',
		}
	}
}

/*---------------------------------------------*/

export default ContextMenu;