
function UI(inffuse_instance)
{
	var Inffuse = inffuse_instance;
	var thisObj = this;
	
	this.init = function()
	{
		$('[data-section-title]').click(function(){
				thisObj.toggleSection($(this).parent());
			});
	}
	
	this.toggleSection = function(section)
	{
		var wasOpen = section.hasClass('open');
				
		$('[data-section]').removeClass('open');
		$('[data-section]').addClass('closed');
		
		if (wasOpen)
		{
			section.removeClass('open');
			section.addClass('closed');
			
		} else
		{
			section.addClass('open');
			section.removeClass('closed');
		}

		// Why? Inffuse.project.updateHeight();
	}
	
	this.openColorSelect = function(colorpicker,current,callback)
	{
		if (colorpicker.find('.colorpopup').length) // a colorpicker is already open
			return;

		function updateHex(rgb)
		{
			var hex = colorpicker.find('input.hex');
			hex.val(Inffuse.ui.rgbToHex(rgb));
		}
		
		function update(event)
		{
			var el = $(event.target);
			if (el.hasClass('colorcell'))
			{
				colorpicker.find('.current').removeClass('current');
				el.addClass('current');

				updateHex(el.attr('data-color'));
				
				submit();
			}
			
			event.preventDefault();
			return false;
		}
		
		function close(event)
		{
			colorpicker.find('.colorpopup').remove();
			$('body').unbind('click',close);
			
			if (event)
				event.preventDefault();
		}
		
		function submit(event)
		{
			//Inffuse.analytics.track('Color Changed');

			var color = colorpicker.find('.hex').val();
			color = Inffuse.ui.hexToRgb(color);
			
			// todo - leave the popup open and show error
			if (color)
			{
				callback(color);
			}
			
			close();
		}
		
		var colors_google = [
				//["rgb(0,0,0)", "rgb(68,68,68)", "rgb(102,102,102)", "rgb(153,153,153)", "rgb(204,204,204)", "rgb(238,238,238)", "rgb(243,243,243)", "rgb(255,255,255)", ""], 
				["","rgb(0,0,0)", "rgb(77,77,77)", "rgb(115,115,115)", "rgb(171,171,171)", "rgb(224,224,224)", "rgb(242,242,242)", "rgb(255,255,255)"],
				["rgb(255,0,0)", "rgb(255,153,0)", "rgb(255,255,0)", "rgb(0,255,0)", "rgb(0,255,255)", "rgb(0,0,255)", "rgb(153,0,255)", "rgb(255,0,255)"], 
				["rgb(244,204,204)", "rgb(252,229,205)", "rgb(255,242,204)", "rgb(217,234,211)", "rgb(208,224,227)", "rgb(207,226,243)", "rgb(217,210,233)", "rgb(234,209,220)"], 
				["rgb(234,153,153)", "rgb(249,203,156)", "rgb(255,229,153)", "rgb(182,215,168)", "rgb(162,196,201)", "rgb(159,197,232)", "rgb(180,167,214)", "rgb(213,166,189)"], 
				["rgb(224,102,102)", "rgb(246,178,107)", "rgb(255,217,102)", "rgb(147,196,125)", "rgb(118,165,175)", "rgb(111,168,220)", "rgb(142,124,195)", "rgb(194,123,160)"], 
				["rgb(204,0,0)", "rgb(230,145,56)", "rgb(241,194,50)", "rgb(106,168,79)", "rgb(69,129,142)", "rgb(61,133,198)", "rgb(103,78,167)", "rgb(166,77,121)"], 
				["rgb(153,0,0)", "rgb(180,95,6)", "rgb(191,144,0)", "rgb(56,118,29)", "rgb(19,79,92)", "rgb(11,83,148)", "rgb(53,28,117)", "rgb(116,27,71)"], 
				["rgb(102,0,0)", "rgb(120,63,4)", "rgb(127,96,0)", "rgb(39,78,19)", "rgb(12,52,61)", "rgb(7,55,99)", "rgb(32,18,77)", "rgb(76,17,48)"]
			];
		
		var colors = [
				["rgb(0,0,0)", "rgb(153,51,0)", "rgb(51,51,0)", "rgb(0,0,128)", "rgb(51,51,153)", "rgb(51,51,51)"],
				["rgb(128,0,0)", "rgb(255,102,0)", "rgb(128,128,0)", "rgb(0,128,0)", "rgb(0,128,128)", "rgb(0,0,255)"],
				["rgb(102,102,153)", "rgb(128,128,128)", "rgb(255,0,0)", "rgb(255,153,0)", "rgb(153,204,0)", "rgb(51,153,102)"],
				["rgb(51,204,204)", "rgb(51,102,255)", "rgb(128,0,128)", "rgb(153,153,153)", "rgb(255,0,255)", "rgb(255,204,0)"], 
				["rgb(255,255,0)", "rgb(0,255,0)", "rgb(0,255,255)", "rgb(0,204,255)", "rgb(153,51,102)", "rgb(192,192,192)"],
				["rgb(255,153,204)", "rgb(255,204,153)", "rgb(255,255,153)", "rgb(204,255,255)", "rgb(153,204,255)", "rgb(255,255,255)"]
			];

		var popup = $('<div class="colorpopup"></div>');
		for (var row in colors)
		{
			var rowElement = $('<div class="colorsrow"></div>');
			for (var i in colors[row])
			{
				var color = colors[row][i];
				
				var bg = (color == "") ? "url(/static/wix/img/none.png)" : color;
				var cell = $('<div class="colorcell" data-color="'+color+'" style="background: '+bg+'"></div>');

				if (color == current)
					cell.addClass('current');
					
				rowElement.append(cell);
				
				cell.click(update);
			}
			
			popup.append(rowElement);
		}
		
		var hex = $('<div class="colorhex"><span class="labelhex">hex:</span> <input class="hex" type="text" /> <div class="clear"></div></div>');
		hex.find('input').change(update);
		popup.append(hex);

		var button = $('<button class="ok">OK</button>');
		button.click(submit).append('<div class="clear"></div>');
		popup.append(button);
		
		colorpicker.append(popup);
		if (popup.offset().top+popup.height() > $('body').height())
			popup.addClass('top');
			
		setTimeout(function(){
				updateHex(current);
				
				$('body').bind('click',close);
				$(popup).click(function(){
						return false;
					});
			},0);
	}
	
	this.rgbToHex = function(rgb)
	{
		if (typeof rgb == 'undefined')
			return '';

		function tohex(d)
		{
			var hex = (1*d).toString(16);
			return hex.length == 1 ? "0" + hex : hex;
		}
		
		rgb = rgb.substr(4,rgb.length-5);
		return '#' + rgb.split(',').map(tohex).join('');
	}
	
	this.hexToRgb = function(hex)
	{
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
		if (!result) // try also the short form
			result = /^#?([a-f\d])([a-f\d])([a-f\d])$/i.exec(hex);
		
		if (!result)
			return null;
			
		result.shift();
		
		var values = result.map(function(h){
				if (h.length == 1)
					h = h+h;
					
				return parseInt(h,16)
			});

		return 'rgb('+values.join(',')+')';
	}
	
	this.addCssRule = function(selector,rule)
	{
		// http://stackoverflow.com/questions/311052/setting-css-pseudo-class-rules-from-javascript
		
		rule = rule + " !important";

		if (document.styleSheets[1].insertRule)
			document.styleSheets[1].insertRule(selector + ' {' + rule +' }', 0);
		else if (document.styleSheets[1].addRule)
			document.styleSheets[1].addRule(selector, rule, 0);
	}

	this.opacity = function(color,opacity)
	{
		if (!color)
			return color;

		if (color[0] == '#') // hex based color
		{
			color = color.substr(1);
			shorthand = (color.length == 3);
			
			if (shorthand)
				colors = [color[0]+color[0], color[1]+color[1], color[2]+color[2]];
			else
				colors = [color.substr(0,2), color.substr(2,2), color.substr(4,2)];

			colors = colors.map(function(s){ return parseInt(s,16); });
			colors.push(opacity);
			color = 'rgba(' + colors.join(',') + ')';
		
		} else if (color.substr(0,3) == 'rgb') {
			color = color.replace('rgb(','')
			color = color.replace('rgba(','')
			color = color.replace(')','')
			color = color.replace(' ','')
			colors = color.split(',').slice(0,3);

			colors.push(opacity);
			color = 'rgba(' + colors.join(',') + ')';

		} else if (color.substr(0,3) == 'hsl') {
			color = color.replace('hsl(','')
			color = color.replace('hsla(','')
			color = color.replace(')','')
			color = color.replace(' ','')
			colors = color.split(',').slice(0,3);

			colors.push(opacity);
			color = 'hsla(' + colors.join(',') + ')';
		}

		return color;
	}

	this.openModal = function(params)
	{
		Inffuse.ui.alert("Inffuse error :: openModal is not supported on " + Inffuse.platform + " platform");
	}

	this.closeModal = function()
	{
		Inffuse.ui.alert("Inffuse error :: closeModal is not supported on " + Inffuse.platform + " platform");
	}

	this.alert = function(str)
	{
		alert(str);
	}
};

export default UI;