import React, { useContext,useEffect,useRef,useState } from 'react';
import { AppState, Platform, Pressable, Text, TouchableOpacity, View } from 'react-native';
import Icon from '../utils/icon';
import { useNavigation } from '@react-navigation/native';

import UserDataContext from '../admin-kit-local/tools/context';
import Slider from './slider';
import Image from '../utils/image';

// import AudioPlayer from './youtube';
import AudioPlayer from './audioPlayer';
import { GlobalColors,GlobalStyles } from '../styles';
import { generateId } from '../admin-kit-local/tools/utils';

export default function Player(props) {
	const context = useContext(UserDataContext);
	const navigation = useNavigation();
	const playerRef = useRef();
	const [post,setPost] = useState(null);
	
	const [isPlaying,setIsPlaying] = useState(null);
	const [isFinished,setIsFinished] = useState(false);
	const [isLoading,setIsLoading] = useState(false);
	
	const [duration,setDuration] = useState(0);
	const [currentPosition,setCurrentPosition] = useState(0);
	const [seekingPosition,setSeekingPosition] = useState(null);
	
	const appState = useRef(AppState.currentState);
	

	useEffect(() => {
		context.setPlayerController({play,resume,pause,isPlaying: getIsPlaying});
		context.broadcast('PLAYER_STATE_CHANGE');  // shold be called after setPlayerController
	},[isPlaying]);

	useEffect(() => {
		const interval = setInterval(() => {
			playerRef.current?.getCurrentTime().then(value => {
				setCurrentPosition(value)
			});
			playerRef.current?.getDuration().then(setDuration);
		},500);

		return () => clearInterval(interval);
	},[isPlaying]);
	
	useEffect(() => {
		props.post && play(props.post);
	},[props.post]);

	useEffect(() => {
		const subscription = AppState.addEventListener("change", nextAppState => {
			// if (appState.current.match(/inactive|background/) && nextAppState === "active") {
			if (nextAppState !== "active") {
				pause();
			}

			appState.current = nextAppState;
		});

		return () => {
			subscription && subscription.remove();
		};
	}, []);

	const sliderProps = {
		min: 0,
		max: duration || 1,
		value: duration ? (seekingPosition ?? currentPosition) || 0 : 0,
		onChange: onSeeking,
		onSlideEnd: onSeek,
		style: {
			flexGrow: 1, 
			marginLeft: Platform.select({ android: 0, default: 12 }),
			marginRight: Platform.select({ android: 0, default: 12 })
		},
	
		progressColor: 'white',
		trackColor: 'gray',
		handleColor: GlobalColors['cyan-400'],
	}

	const innerContent = post 
		? (
			<View style={STYLE.player.controls}>
				<Image src={post.song_thumb} style={{width: 48, height: 48}} />
				<View style={{flex: 1, padding: 24, paddingTop: 0, paddingBottom: 0,}}>
					<Pressable onPress={onSongClicked}>
						<Text numberOfLines={1} style={STYLE.title}>
							{post.song_name} · {post.song_artist}
						</Text>
					</Pressable>

					<View style={{flexDirection: "row", alignItems: 'center', marginBottom: 2}}>
						<Text style={STYLE.player.time}>{formatTime(seekingPosition ?? currentPosition)}</Text>
						<Slider {...sliderProps} />
						<Text style={STYLE.player.time}>{formatTime(duration)}</Text>
					</View>
				</View>
				<TouchableOpacity onPress={getIsPlaying() ? pause : resume} style={{flexDirection: "row"}}>
					<View style={{position: 'absolute', left: 1, top: 1, zIndex: -1}}>
						<Icon type={getIsPlaying() ? 'pause' : 'play'} color='rgba(0,0,0,0.5)' size={32} />
					</View>
					<Icon type={getIsPlaying() ? 'pause' : 'play'} color='white' size={32} />
				</TouchableOpacity>
			</View>
		)
		: (
			<Text style={{color: 'white'}}>No song selected</Text>
		);

	return (
		<View style={STYLE.player.container}>
			<View style={STYLE.boxShadow} />
			<AudioPlayer post={post} onStateChange={onStateChange} ref={playerRef} key='audioplayer' />

			{innerContent}
		</View>
	)
	
	/*----------------------------------------------------*/

	function onSongClicked() {
		post && navigation.navigate('Post',{postId: post.id});
	}

	/*----------------------------------------------------*/

	function onStateChange(state) {
		console.log("[onStateChange]",{state});
		
		switch (state) {
			case 'video cued': 
				setIsPlaying(true);
				break;

			case 'playing':
				setIsPlaying(true);
				setIsLoading(false);
				break;
			
			case 'paused':
				setIsPlaying(false);
				break;
		
			case 'ended':
				setIsPlaying(false);
				setIsFinished(true);
				break;	
		}
	}
	
	/*----------------------------------------------------*/

	function play(postToPlay) {
		context.log("[Player.play]",{postToPlay});

		if (post && post.id === postToPlay.id) {
			resume();
			return;
		}

		setPost(postToPlay);

		setIsFinished(false);
		setIsLoading(true);

		context.inffuse.services.mysql.run('logPlay',{id: generateId('play'), post_id: postToPlay.id});
	}

	/*----------------------------------------------------*/
	
	function resume() {
		if (isFinished) {
			playerRef.current?.seekTo(0);
			setIsFinished(false);
		}

		playerRef.current?.play();
	}
	
	function pause() {
		playerRef.current?.pause();
	}
	
	function getIsPlaying() {
		return isLoading || isPlaying;
	}

	/*----------------------------------------------------*/
	
	function onSeeking(value) {
		console.log('[onSeeking]',{value});
		setSeekingPosition(seekingPosition => {
			// workaround for native slider bug - invokes onChange during initial render
			if (seekingPosition === null && value === 0) {
				return null;
			}

			return value;
		});
	}
	
	function onSeek(value) {
		// the native slider calls onSeek before onSeeking, so setTimeout is here to work around this
		setTimeout(() => {
			console.log('[onSeek]',{value});

			// callback is required because onSeek is called from a slider document.addEventListener, 
			// which preserves an outdated scope (https://stackoverflow.com/a/53846698)
			setSeekingPosition(seekingPosition => {
				const position = seekingPosition || value;
				setCurrentPosition(position); // usability issue - prevent slider jump to previous position
				playerRef.current?.seekTo(position);
				return null;
			});
		});
	}
	
	/*----------------------------------------------------*/

	function formatTime(time) {
		var min = Math.floor((time || 0) / 60);
		if (min < 10) {
			min = '0' + min;
		}
		
		var sec = Math.floor((time || 0)) % 60;
		if (sec < 10) {
			sec = '0' + sec;
		}
			
		//timePretty =  min + 'min ' + sec + 'sec';
		return `${min}:${sec}`;
	}
}

/*-------------------------------------------------------*/

const textShadow = {
	textShadowOffset: {
		width: 0.5,
		height: 0.5,
	},
	textShadowColor: 'rgba(0,0,0,1)',
	textShadowRadius: 2,
}

const STYLE = {
	title: {
		...textShadow,
		color: 'white',
		fontWeight: 'bold',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		marginBottom: 8,
	},
	none: {
		position: 'relative',
		display: 'none',
	},
	boxShadow: {
		borderTopWidth: 1.5,
		borderTopColor: 'rgba(0,0,0,0.05)',
		borderBottomWidth: 1.5,
		borderBottomColor: 'rgba(0,0,0,0.15)',
		backgroundColor: 'transparent',
		height: 0,
		width: '100%',
		position: 'absolute',
		left: 0,
		top: -3,
	},
	player: {
		container: {
			minHeight: 36,
			backgroundColor: GlobalColors['neutral-800'],
			alignItems: 'center',
			justifyContent: 'center',
		},
		controls: {
			justifyContent: 'center', 
			alignItems: 'center',
			width: '100%',
			padding: 8, 
			paddingLeft: 16,
			paddingRight: 16,
			flexDirection: 'row',
			backgroundColor: 'rgba(0,0,0,0)',
		},
		cover: {
			position: 'absolute',
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
		},
		bg: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			backgroundColor: '#333',
		},
		time: {
			...textShadow,
			color: 'white',
		}
	}
}
/*-------------------------------------------------------*/
