// https://medium.com/easyfundraising-org-uk/how-to-create-a-simple-loading-animation-in-react-native-typescript-without-libraries-and-with-7257551cd243

import React, { useEffect, useRef } from 'react'
import { StyleSheet, View, ColorValue, Animated, Easing } from 'react-native'

const startRotationAnimation = (duration, rotationDegree) => {
	Animated.loop(Animated.timing(
		rotationDegree,
		{
			toValue: 360,
			duration: duration,
			easing: Easing.linear,
			useNativeDriver: false
		}
	)).start()
}

function LoadingSpinner({color, duration=1000, size=24, width=4, testID}) {
	const rotationDegree = useRef(new Animated.Value(0)).current;

	const styles = {
		container: {
			width: size,
			height: size,
			justifyContent: 'center',
			alignItems: 'center'
		},
		background: {
			width: '100%',
			height: '100%',
			borderRadius: size / 2,
			borderWidth: width,
			opacity: 0.25
		},
		progress: {
			width: '100%',
			height: '100%',
			borderRadius: size / 2,
			borderLeftColor: 'transparent',
			borderRightColor: 'transparent',
			borderBottomColor: 'transparent',
			borderWidth: width,
			position: 'absolute'
		}
	}

	useEffect(() => {
		startRotationAnimation(duration, rotationDegree)
	}, [duration, rotationDegree])

	return (
		<View style={styles.container} accessibilityRole='progressbar'>

			<View style={[styles.background, { borderColor: color }]} />

			<Animated.View
				testID={testID}
				style={[styles.progress, { borderTopColor: color }, {
					transform: [{
						rotateZ: rotationDegree.interpolate({
							inputRange: [0, 360],
							outputRange: ['0deg', '360deg']
						})
					}]
				}]}
			/>
		</View>
	)
}

export default LoadingSpinner;