const NotificationsContent = {
	NOTIF_COMMENT: {
		title: (data) => `${data.userName} commented on a song you shared.`,
		body: (data) => `${data.userName} commented on "${data.songName}" by ${data.songArtist}.`,
	},
	NOTIF_COMMENT_THREAD: {
		title: (data) => `${data.userName} also commented on "${data.songName}".`,
		body: (data) => `${data.userName} also commented a song you commented on - "${data.songName}" by ${data.songArtist}.`,
	},
	NOTIF_LIKED_POST_COMMENT: {
		title: (data) => `${data.userName} commented on "${data.songName}".`,
		body: (data) => `${data.userName} commented on a song you liked - "${data.songName}" by ${data.songArtist}.`,
	},
	NOTIF_FOLLOWER: {
		title: (data) => `${data.userName} started following you.`,
	},
	NOTIF_POST_LIKE: {
		title: (data) => `${data.userName} likes a song you shared.`,
		body: (data) => `${data.userName} likes "${data.songName}" by ${data.songArtist}.`,
	},
	NOTIF_COMMENT_LIKE: {
		title: (data) => `${data.userName} likes your comment.`,
		body: (data) => `${data.userName} likes your comment "${data.commentText}" on "${data.songName}" by ${data.songArtist}.`,
	},
	NOTIF_NEW_POSTS: {
		title: (data) => `New songs in your feed!`,
		body: (data) => [
			data.authors.length === 1 ? data.authors[0] : `${data.authors.length} people`,
			'shared new songs from artists like',
			data.artists.length === 1 ? data.artists[0] : `${data.artists.slice(0, -1).join(', ')} and ${data.artists.slice(-1)}`
		].join(' '),
	},
	NOTIF_INVITE_ACCEPTED: {
		title: (data) => `${data.userName} has accepted your invitation!`,
		body: (data) => `${data.userName} has accepted your invitation and is now following you. Follow them back!`,
	},
}

function handleNotification(context,navigation,notification) {
	switch (notification.type) {
		case 'NOTIF_FOLLOWER': {
			navigation.navigate('User',{userId: notification.data.userId});
			break;
		}
		case 'NOTIF_COMMENT': 
		case 'NOTIF_COMMENT_THREAD':
		case 'NOTIF_LIKED_POST_COMMENT':
		case 'NOTIF_COMMENT_LIKE': {
			navigation.navigate('Post',{postId: notification.data.postId, commentId: notification.data.commentId});
			break;
		}
		case 'NOTIF_POST_LIKE': {
			navigation.navigate('Post',{postId: notification.data.postId});
			break;
		}
		case 'NOTIF_NEW_POSTS': {
			navigation.navigate('Feed',{scrollToTop: true});
			break;
		}
	}

	if (!notification.id) {
		return;
	}

	const currentTime = new Date().toISOString().slice(0,19).replace('T',' ');
	return context.inffuse.services.mysql.run('updateNotification',{id: notification.id, read: currentTime})
}

export {NotificationsContent,handleNotification};