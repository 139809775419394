import React, { useEffect } from 'react';
import { useState,useRef } from 'react';
import { TouchableOpacity,Text, View } from 'react-native';
import { GlobalColors } from '../styles';

/*---------------------------------------------*/

export default function Button(props) {
	const [loading,setLoading] = useState(false);
	const disabled = props.disabled;
	const isMounted = useRef(true);
	const variantStyle = VARIANTS[props.variant || 'common'];

	const style = {
		...STYLE.container, 
		...variantStyle.container, 
		...props.style,
		...(loading ? STYLE.loading : {}),
		...(disabled ? STYLE.disabled : {}),
	}

	useEffect(() => {
		return () => { isMounted.current = false };
	},[]);

	return (
		<TouchableOpacity onPress={onPress} style={style} submit={props.submit}>
			<Text style={{...STYLE.text, ...variantStyle.text}}>{props.children}</Text>
		</TouchableOpacity>
	)

	/*------------------------------------------*/

	function onPress() {
		if (props.onPress) {
			setLoading(true);
	
			const promise = props.onPress()
			if (promise) {
				promise.then(() => {
					if (isMounted.current) {
						setLoading(false)
					};
				});

			} else {
				setLoading(false);
			}
		}
	}
}

/*---------------------------------------------*/

const VARIANTS = {
	primary: {
		container: {
			backgroundColor: GlobalColors['primary-600'],
		},
		text: {
			color: GlobalColors['neutral-50'],
		}
	},
	common: {
		container: {
			backgroundColor: GlobalColors['neutral-100'],
		},
		text: {
			color: GlobalColors['neutral-800'],
		}
	},
	outline: {
		container: {
			backgroundColor: 'transparent',
			borderWidth: 1,
			borderColor: GlobalColors['primary-800'],
		},
		text: {
			color: GlobalColors['primary-800'],
		}
	},
	danger: {
		container: {
			backgroundColor: GlobalColors['error-300'],
		},
		text: {
			color: GlobalColors['neutral-50'],
		}
	},
}

const STYLE = {
	container: {
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 8,
		paddingBottom: 8,
		borderRadius: 2,
	},
	loading: {
		opacity: 0.3,
	},
	disabled: {
		backgroundColor: GlobalColors['neutral-400'],
	},
	text: {
	}
}