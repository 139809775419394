
function InffuseProjectsClass(inffuse_instance)
{
	var Inffuse = inffuse_instance;

	/*------------------------------------------*/

	this._init = function()
	{
	}
	
	/*------------------------------------------*/

	this.list = function()
	{
		var params = {
			user: Inffuse.user.id(),
		};

		return Inffuse.requestAPI('projects',params,'GET');
	}

	this.create = function(name)
	{
		var params = {
			user: Inffuse.user.id(),
			site: (Inffuse.site && Inffuse.site.id()) ? Inffuse.site.id() : null,
			name: name
		};

		return Inffuse.requestAPI('projects',params,'POST');
	}

	this.load = function(project_id,expand_site)
	{
		var expand_list = [];
		if (expand_site)
			expand_list.push('site');

		var params = {
			user: Inffuse.user.id(),
			fields: 'data',
			expand: expand_list.join(',')
		};

		var deferred = new jQuery.Deferred();
		Inffuse.requestAPI('projects/'+project_id,params,'GET')
			.then(
				function(project_details){
					var project = new Inffuse.ProjectClass(Inffuse,project_details.project,project_details.data);
					deferred.resolve(project);
				},
				function(err){
					deferred.reject(err);
				});

		return deferred.promise();
	}
};

export default InffuseProjectsClass;