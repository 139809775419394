import React from 'react';

import JsTimeAgo from 'javascript-time-ago';
import enTimeAgoLocale from 'javascript-time-ago/locale/en.json'

JsTimeAgo.addDefaultLocale(enTimeAgoLocale);
const timeAgo = new JsTimeAgo('en-US')

export default function TimeAgo(props) {
	const now = 1 * new Date();

	return timeAgo.format(Math.min(now,props.time));
}